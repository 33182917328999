import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/@core/services/notification.service';
import { API_ADD_AGENCY, API_APPROVE_AGENCY } from 'src/@core/services/util/api.endpoints';
import { BehaviorSubject } from 'rxjs';
import { LoginResponse } from 'src/@core/interfaces/login-response.model';
import { EnterpriseAdminService } from '../../../enterprise-admin.service';
import { AppService } from 'src/@core/services/app.service';

@Component({
  selector: 'hour4u-admin-create-team-member',
  templateUrl: './create-team-member.component.html',
  styleUrls: ['./create-team-member.component.scss']
})
export class CreateTeamMemberComponent implements OnInit {
  createTeamMemberForm: FormGroup;
  submitted = false;
  private currentUserSubject = new BehaviorSubject<LoginResponse>(new LoginResponse());
  profile = this.appService.getCurrentAccount();
  loginUserDetails: any = null;
  currentUserRole: any = null;
  addingForWhichRole: any = null;

  constructor(
    public dialogRef: MatDialogRef<CreateTeamMemberComponent>,
    private _fb: FormBuilder,
    private http: HttpClient,
    private notify: NotificationService,
    public enterpriseAdminService: EnterpriseAdminService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCurrentRole();
    this.getRolesByEntity();
  }

  getCurrentAccount(): any {
    return this.currentUserSubject.getValue();
  }

  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      this.loginUserDetails = currentUser;
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }

  // get Roles By Entity name
  getRolesByEntity() {
    if (this.profile) {
      this.enterpriseAdminService.getRolesByEntityName(this.profile.entity.entityType).subscribe(res => {
        res.forEach(element => {
          if (this.currentUserRole == 'AGENCY_ADMIN') {
            if (element.roleName == 'AGENCY_STAFF') {
              this.addingForWhichRole = element;
            }
          }
          if (this.currentUserRole == 'ENTERPRISE_ADMIN') {
            if (element.roleName == 'ENTERPRISE_STAFF') {
              this.addingForWhichRole = element;
            }
          }
        });
      })
    }
  }

  createForm() {
    this.createTeamMemberForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave(): void {
    this.submitted = true;
    console.log(this.createTeamMemberForm, this.addingForWhichRole);
    if (this.createTeamMemberForm.invalid) {
      this.createTeamMemberForm.markAllAsTouched();
      this.submitted = false;
      return;
    } else {
      this.addTeamMember();
    }
  }

  // add Team Member
  addTeamMember(): void {
    if (this.addingForWhichRole) {
      let obj = {
        "mobile": this.createTeamMemberForm.value.mobile,
        "email": this.createTeamMemberForm.value.email,
        "roleId": this.addingForWhichRole.roleId,
        "name": this.createTeamMemberForm.value.name
      }
      this.enterpriseAdminService.createTeamMember(this.profile.entity.id, obj).toPromise()
        .then((res: any) => {
          this.autoApproveTeamMember(res);
        }).catch(err => {
          console.log(err)
          this.submitted = false;
        })
    } else {
      this.notify.error('Proper role not found!');
    }
  }

  // auto Approve Team Member
  autoApproveTeamMember(res) {
    this.enterpriseAdminService.updateTeamMemberStatus(res.id, 'ACCEPTED').toPromise()
      .then((res: any) => {
        this.submitted = false;
        this.notify.showMessage('Team Member Added Successfully!', 3000);
        this.dialogRef.close(res);
      }).catch(err => {
        this.submitted = false;
        console.log(err)
      })
  }

}
