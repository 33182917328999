import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSelectChange, MatTableDataSource, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { AppService } from 'src/@core/services/app.service';
import { JobService } from 'src/@core/services/job/job.service';
import { roles } from 'src/app/shared/helpers/roles';
import { STATUS } from 'src/app/shared/helpers/status';
import { environment } from 'src/environments/environment';
import { ViewActivationImageComponent } from '../../dashboard/view-activation-image/view-activation-image.component';
import { AddActivationImageComponent } from '../../dashboard/add-activation-image/add-activation-image.component';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';

@Component({
  selector: 'hour4u-admin-work-order-table',
  templateUrl: './work-order-table.component.html',
  styleUrls: ['./work-order-table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
})
export class WorkOrderTableComponent implements OnInit {
  @Input() showFilter: boolean = true;
  @Input() showOverview: boolean = true;
  @Input() showTable: boolean = true;

  roles = roles;
  workOrderData: any;
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  profile = this.appService.getCurrentAccount();
  workOrderDataSource: MatTableDataSource<any>;
  dateFormControl = new FormControl();
  workOrderFilterData: FormGroup;
  // selectedCityOptions: string[] = [];
  // selectedAgencyOptions: string[] = [];
  // selectedOptions: string[] = [];
  selectedOptions: string = '';
  selectedCityOptions: string = '';
  selectedAgencyOptions: string = '';
  s3Url = environment.image_url;
  selectedStatus: string = 'PENDING';
  customOptions: any;
  displayedBanners: { [bannerId: string]: boolean };
  status = STATUS;



  columns = [
    { columnDef: 'name', visible: true, label: 'Name' },
    { columnDef: 'status', visible: true, label: 'Status' },
    { columnDef: 'city', visible: true, label: 'City' },
    { columnDef: 'agency', visible: true, label: 'Agency' },
    { columnDef: 'activationImage', visible: true, label: 'Activation Image' },
    { columnDef: 'activationApproval', visible: true, label: 'Activation Approval' },
    { columnDef: 'fulfilment', visible: true, label: 'Fulfilment' },
    { columnDef: 'totalTasks', visible: true, label: 'Total Tasks' },
    { columnDef: 'approved', visible: true, label: 'Approved' },
  ];
  displayedColumns = [...this.columns.map(x => x.columnDef), 'actions'];

  multiselectOptions = [
    // { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Completed', label: 'Completed' },
  ];


  cityMultiselectOptions = [
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Gurugram', label: 'Gurugram' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Bangalore', label: 'Bangalore' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Pune', label: 'Pune' },

  ];

  agencyMultiselectOptions = [
    { value: 'Agency1', label: 'Agency 1' },
    { value: 'Agency2', label: 'Agency 2' },
    { value: 'Agency3', label: 'Agency 3' },
  ];

  constructor(
    public appService: AppService,
    private dialog: MatDialog,
    public jobService: JobService,
    public router: Router,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private jobPipe: JobPipe,

  ) { }

  ngOnInit() {
    this.fetchDashboardData();

    // // Filter out 'agency' column
    // if (this.profile.entity.entityType === 'AGENCY') {
    //   this.columns = this.columns.filter(column => column.columnDef !== 'agency');
    //   this.displayedColumns = this.columns.map(x => x.columnDef);
    // }

    // Initialize the form group and controls for filters
    this.workOrderFilterData = new FormGroup({
      dateFrom: new FormControl(),
    });

    // Subscribe to date changes for filter
    this.workOrderFilterData.get('dateFrom').valueChanges.subscribe((date: Date) => {
      console.log('Selected Date:', date);
      //  perform additional actions when the date changes
    });

    this.workOrderFilterData = this.fb.group({
      dateFrom: [new Date(), Validators.required], // Set default value to today's date
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.workOrderDataSource = new MatTableDataSource(this.workOrderData);
  }

  // fetchDashboardData() {
  //   let params = new HttpParams()
  //     .set('page', this.pageIndex.toString())
  //     .set('size', this.pageSize.toString());

  //   // Check if selectedOptions array has elements
  //   if (this.selectedOptions.length > 0) {
  //     params = params.set('status', this.selectedOptions.join(','));
  //   }

  //   // Check if selectedCityOptions array has elements
  //   if (this.selectedCityOptions.length > 0) {
  //     params = params.set('city', this.selectedCityOptions.join(','));
  //   }

  //   this.jobService.searchAndSortDashboardData(params)
  //     .subscribe(
  //       (response) => {
  //         this.workOrderData = response.content;
  //         this.resultsLength = response.totalElements;
  //         this.workOrderDataSource = new MatTableDataSource(this.workOrderData);
  //       },
  //       (error) => {
  //         console.error('Failed to fetch dashboard data:', error);
  //       }
  //     );
  // }

  fetchDashboardData() {
    let params = new HttpParams()
      .set('page', this.pageIndex.toString())
      .set('size', this.pageSize.toString());

    // Set status parameter if a single option is selected
    if (this.selectedOptions) {
      params = params.set('status', this.selectedOptions);
    }

    // Set city parameter if a single option is selected
    if (this.selectedCityOptions) {
      params = params.set('city', this.selectedCityOptions);
    }

    this.jobService.searchAndSortDashboardData(params)
      .subscribe(
        (response) => {
          this.workOrderData = response.content;
          this.resultsLength = response.totalElements;
          this.workOrderDataSource = new MatTableDataSource(this.workOrderData);
        },
        (error) => {
          console.error('Failed to fetch dashboard data:', error);
        }
      );
  }

  clearCitySelection(eventData: Event): void {
    this.selectedCityOptions = '';
    this.fetchDashboardData();
    eventData.stopPropagation();
  }

  clearAgencySelection(): void {
    this.selectedAgencyOptions = '';
    this.fetchDashboardData();
  }
  clearStatusSelection(eventData: Event): void {
    this.selectedOptions = '';
    this.fetchDashboardData();
    eventData.stopPropagation();

  }


  onFilterChange(): void {
    this.fetchDashboardData();
  }

  getTotalJobApplicants(items: any[], gender: string) {
    let totalApplicants = 0;

    if (items) {
      items.forEach(item => {
        totalApplicants += item[`${gender}JobApplicants`] || 0;
      });
    }

    return totalApplicants;
  }

  getTotalTasks(items: any[]): number {
    let totalTasks = 0;

    if (items) {
      items.forEach(item => {
        if (item.totalTasks) {
          totalTasks += item.totalTasks;
        }
      });
    }

    return totalTasks;
  }


  getTotalFulfilment(items: any[]): number {
    let totalQuantity = 0;

    if (items) {
      items.forEach(item => {
        if (item.billingItems && item.billingItems.length > 0) {
          // Only calculate the quantity of the item at index 0 i.e selected JobType
          totalQuantity += item.billingItems[0].quantity || 0;
        }
      });
    }

    return totalQuantity;
  }



  getEachFulfilment(billingItems: any[]): number {
    if (billingItems && billingItems.length > 0) {
      // Only calculate the quantity of the item at index 0 i.e selected JobType
      return billingItems[0].quantity || 0;
    }

    return 0;
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchDashboardData();
  }

  onWorkOrderClick(element: any): void {
    const workOrderId = element.id;
    if (element.status == 'Pending') {
      this.router.navigateByUrl('/enterprise-admin/view-work-order/' + workOrderId);
    } else {
      this.router.navigateByUrl('/employment/job-applications/all/' + workOrderId);
    }
  }

  filter = {
    status: '',
    city: ''
  };


  // Filter date picker
  setNextDate(): void {
    const currentDate = this.workOrderFilterData.get('dateFrom').value || new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    this.workOrderFilterData.get('dateFrom').setValue(nextDate);
  }
  setPrevDate(): void {
    const currentDate = this.workOrderFilterData.get('dateFrom').value || new Date();
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    this.workOrderFilterData.get('dateFrom').setValue(previousDate);
  }

  generateSelectedLabel(): string {
    if (this.selectedOptions.length === 0) {
      return 'Select Status';
    } else if (this.selectedOptions.length === 1) {
      return this.selectedOptions[0];
    } else {
      return `${this.selectedOptions[0]} + ${this.selectedOptions.length - 1}`;
    }
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  onStatusSelectionChange(event: MatSelectChange): void {
    this.fetchDashboardData();
  }

  handleUploadClick(event: Event, element: any) {
    event.stopPropagation();
    // Open the AddActivationImageComponent in a dialog
    const dialogRef = this.dialog.open(AddActivationImageComponent, {
      width: '500px',
      data: {
        workOrder: element,
        dashboardComponent: this
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
    });
  }

  viewActivationImage(event: Event, element: any) {
    event.stopPropagation();

    // Check if imageUrl is available
    if (element.activationProof && element.activationProof.imageUrl) {
      const imageUrl = this.s3Url + element.activationProof.imageUrl;

      // Open the image in a modal dialog
      const dialogRef = this.dialog.open(ViewActivationImageComponent, {
        data: {
          imageUrl: imageUrl,
          workOrder: element,
          dashboardComponent: this
        },
      });

      // Prevent the default action (download) of the original click event
      event.preventDefault();
    } else {
      console.error('Image URL not available.');
    }
  }
}
