import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginComponent } from './public/login/login.component';
import { AuthGuard } from "../@core/guards/auth.guard";
import { roles } from './shared/helpers/roles';
import { ProfileComponent } from './profile/profile.component';
import { AppComponent } from './app.component';
import { HelpVideosComponent } from './modules/help-videos/help-videos.component';
import { AddEditEmploymentComponent } from './modules/employment/employment/add-edit-employment/add-edit-employment.component';
import { AddEditShiftComponent } from './modules/employment/employment/add-edit-shift/add-edit-shift.component';
import { FailScreenComponent } from './modules/modals/fail-screen/fail-screen.component';
import { SuccessScreenComponent } from './modules/modals/success-screen/success-screen.component';
import { SocietiesComponent } from './modules/societies/societies.component';
import { AvailablePermissionsComponent } from './modules/available-permissions/available-permissions.component';
import { AddEditJobComponent } from './modules/job/job/add-edit-job/add-edit-job.component';
import { CreateShiftComponent } from './modules/employment/employment/create-shift/create-shift.component';
import { OrderAndInvoicesComponent } from './modules/order-and-invoices/order-and-invoices/order-and-invoices.component';
import { ViewOrderComponent } from './modules/order-and-invoices/order-and-invoices/view-order/view-order.component';
import { AddEditProjectComponent } from './modules/job/job/add-edit-project/add-edit-project.component';
import { WorkOrderTableComponent } from './modules/enterprise-admin/work-order-table/work-order-table.component';
const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'job',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule)
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule),
    data: { roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF] }
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    data: { roles: [roles.SUPER_ADMIN, roles.ON_BOARDING_STAFF] }
  },
  {
    path: 'employer',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/employer/employer.module').then(m => m.EmployerModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'employment',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/employment/employment.module').then(m => m.EmploymentModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'enterprise-admin',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/enterprise-admin/enterprise-admin.module').then(m => m.EnterpriseAdminModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'job-seeker',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/job-seeker/job-seeker.module').then(m => m.JobSeekerModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'order-and-invoices',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: OrderAndInvoicesComponent
      },
      {
        path: 'view-order/:id',
        component: ViewOrderComponent
      }
    ],
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'help-videos',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HelpVideosComponent
      }
    ]
  },
  {
    path: 'venues',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: SocietiesComponent
      }
    ],
    data: { roles: [roles.AGENCY_ADMIN, roles.AGENCY_STAFF] },

  },
  {
    path: 'available-permissions',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AvailablePermissionsComponent
      }
    ],
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'attendance-tracker',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/attendance-tracker/attendance-tracker.module').then(m => m.AttendanceTrackerModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'payment-tracker',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/payment-tracker/payment-tracker.module').then(m => m.PaymentTrackerModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'supervisor-admin',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/supervisor-admin/supervisor-admin.module').then(m => m.SupervisorAdminModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'checkout/:id', component: AppComponent
  },
  {
    path: 'invoice-pay/:id',
    // canActivate: [AuthGuard],
    component: AppComponent
  },
  {
    path: 'fail-screen', component: FailScreenComponent
  },
  {
    path: 'success-screen', component: SuccessScreenComponent
  },
  {
    path: 'add-shift',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AddEditEmploymentComponent
      }
    ],
  },
  {
    path: 'add-edit-shift',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AddEditShiftComponent
      }
    ],
  },
  {
    path: 'create-shift',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: CreateShiftComponent
      }
    ],
  },
  {
    path: 'add-edit-job-template',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AddEditJobComponent
      }
    ],
  },
  {
    path: 'add-edit-project',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AddEditProjectComponent
      }
    ],
  },
  {
    path: 'add-edit-job-template/:id',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: AddEditJobComponent
      }
    ],
  },
  {
    path: 'job-admin',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./modules/job-admin/job-admin.module').then(m => m.JobAdminModule),
    data: { roles: [roles.ENTERPRISE_ADMIN, roles.AGENCY_ADMIN, roles.ENTERPRISE_STAFF, roles.AGENCY_STAFF] }
  },
  {
    path: 'work-order-table',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: WorkOrderTableComponent
      }
    ]
  },
  // {
  //   path: 'users/staff',
  //   canActivate: [AuthGuard],
  //   component: MainLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: TeamMemberComponent
  //     }
  //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
