import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationExtras, Router } from '@angular/router';
import { JobService } from 'src/@core/services/job/job.service';
import { AssignJobAdminComponent } from 'src/app/modules/employment/assign-job-admin/assign-job-admin.component';

@Component({
  selector: 'hour4u-admin-job-burger-menu',
  templateUrl: './job-burger-menu.component.html',
  styleUrls: ['./job-burger-menu.component.scss']
})
export class JobBurgerMenuComponent implements OnInit {
  @Input() row: any;
  @Input() status: any;
  @Input() roles: any;

  @Output() dataRefresh = new EventEmitter();

  constructor(
    private router: Router,
    private jobService: JobService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  clone(object: any): void {
    object.id = null;
    object.status = null;
    object.cloned = true;
    const data = {
      title: 'Clone Template',
      data: object
    };
    const navigationExtras: NavigationExtras = {
      state: { data: JSON.stringify(data) },
    };
    this.router.navigate(['add-edit-project'], navigationExtras);
  }

  update(object: any): void {
    console.log(object)
    this.router.navigate(['/add-edit-job-template/' + object.id]);
  }

  // Delete template
  deleteTemplate(row) {
    this.jobService.deleteJobTemplate(row.id).subscribe(res => {
      this.dataRefresh.emit();
    })
  }

  // create Work Order
  createWorkOrder(job: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'jobId': job.id, 'jobTypeId': job.jobTypeId, 'employerId': job.employerId }
    }
    this.router.navigate(['/enterprise-admin/create-work-order'], navigationExtras);
  }

  // ASSIGN ADMIN DIALOG
  assignAgency(data): void {
    // const employmentAdmin = new EmploymentAdministration();
    // employmentAdmin.employmentId = id;
    const dialogRef = this.dialog.open(AssignJobAdminComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }
}
