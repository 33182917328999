import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef } from '@angular/material';
import { Search } from 'src/@core/interfaces/search';
import { AccountService } from 'src/@core/services/account.service';
import { AppService } from 'src/@core/services/app.service';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { JobService } from 'src/@core/services/job/job.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { API_FETCH_AGENCY } from 'src/@core/services/util/api.endpoints';
import { roles } from 'src/app/shared/helpers/roles';

@Component({
  selector: 'hour4u-admin-assign-job-admin',
  templateUrl: './assign-job-admin.component.html',
  styleUrls: ['./assign-job-admin.component.scss']
})
export class AssignJobAdminComponent implements OnInit {

  selectedJobDetails: any = null;
  selection = [];
  searchField: string = null;
  searchParams: Search[] = [];
  agencyList: any[] = [];
  roles = roles;
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 100;
  role: string = 'JOB_ADMIN';
  pageSizeOptions: number[] = [10, 20, 50, 100, 500];
  assignedJobAdmins: any[] = [];
  selectedAll: boolean = false;
  profile = this.appService.getCurrentAccount();

  constructor(
    public dialogRef: MatDialogRef<AssignJobAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private notify: NotificationService,
    public employmentService: EmploymentService,
    private appService: AppService,
    private http: HttpClient,
    public jobService: JobService
  ) {
    this.selectedJobDetails = this.data;
    console.log(this.selectedJobDetails)
  }

  ngOnInit() {
    this.getEmployementAssignedJobAdmin();
  }

  getEmployementAssignedJobAdmin() {
    this.employmentService.getAssignedAdmins(this.selectedJobDetails.id).subscribe((res: any) => {
      console.log(res)
      this.assignedJobAdmins = res.jobAdminAssignmentList;
      this.getAllJobAdmin();
    })
  }

  getAllJobAdmin() {
    this.searchParams = [];
    const entityId = this.profile.entity.id;

    if (entityId) {
      if (this.searchField) {
        this.searchParams.push(new Search('query', this.searchField));

      }
      // this.searchParams.push(new Search('roles', this.role));
      this.jobService.searchAndSortAgencies(entityId, this.pageIndex, this.pageSize, 'createdOn', null, this.searchParams
      ).subscribe(data => {
        this.agencyList = data.content;
        if (this.selectedAll) {
          let selection = Object.assign([], this.selection);
          this.selection = [];
          selection.forEach(ele => {
            this.agencyList.forEach(el => {
              if (el.id == ele.id) {
                this.selection.push(el);
              }
            })
          })
        } else {
          if (this.selection && this.selection.length) {
            this.selection.forEach(ele => {
              this.agencyList.forEach(el => {
                el.status = ele.status;
                if (el.id == ele.id) {
                  el.checked = true;
                }
              })
            })
          }
        }

        if (this.assignedJobAdmins && this.assignedJobAdmins.length) {
          this.assignedJobAdmins.forEach(ele => {
            this.agencyList.forEach(el => {
              el.status = ele.status;
              if (ele.jobAdmin && el.id == ele.jobAdmin.id) {
                if (ele.status == 'PENDING' || ele.status == 'ACCEPTED') {
                  el.assigned = true;
                }
              }
            })
          })
        }

        // this.agencyList = this.agencyList.sort((a, b) => {
        //   return a.entityMeta.name.localeCompare(b.entityMeta.name);
        // });
        console.log(this.agencyList)
        this.resultsLength = data.totalElements;
      }, error => {
        this.notify.showMessage('Something went wrong!', 2000);
      });



      // Make a GET request to fetch agencies based on the entity ID
      // this.http.get(`${API_FETCH_AGENCY}${entityId}`).toPromise()
      //   .then((response: any) => {
      //     // Handle the response from the API
      //     console.log('Agencies fetched successfully:', response);
      //     this.agencyList = response.content;
      //     this.resultsLength = response.totalElements;
      //   })
      //   .catch(error => {
      //     console.error('Error fetching agencies:', error);
      //     // Handle error response from the API
      //   });
    } else {
      console.warn('Entity ID is not available. Unable to fetch agencies.');
    }
  }

  toggle(item, event: MatCheckboxChange) {
    // this.selection = [];
    if (event.checked) {
      this.selection.push(item);
    } else {
      const index = this.selection.findIndex(x => x.id === item.id);
      if (index >= 0) {
        this.selection.splice(index, 1);
      }
    }
  }

  exists(item) {
    return this.selection.indexOf(item) > -1;
  };

  isIndeterminate() {
    return (this.selection.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selection.length === this.agencyList.length;
  };

  toggleAll(event: MatCheckboxChange) {
    this.selectedAll = event.checked;
    this.selection = [];
    if (!this.selectedAll) {
      this.agencyList.forEach(el => {
        el.checked = false;
      })
    }
    if (event.checked) {
      this.agencyList.forEach(row => {
        if (!row.assigned)
          this.selection.push(row)
      });
    } else {
      this.selection.length = 0;
    }
  }

  // Disable send request btn
  disable() {
    if (this.selection && this.selection.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  // Get Initial letter from JobSeeker name
  getUserNameInitial(full_name) {
    let initialOfName = null;
    if (full_name) {
      if (full_name.split(' ').length == 1) {
        initialOfName = full_name.split(' ')[0].split('')[0];
      }
      if (full_name.split(' ').length > 1) {
        initialOfName = full_name.split(' ')[0].split('')[0] + (full_name.split(' ')[1].split('')[0] ? full_name.split(' ')[1].split('')[0] : '')
      }
      return initialOfName ? initialOfName : full_name.split('')[0];
    }
  }

  // Send request
  sendRequest() {
    console.log(this.selection);
    let obj = null;
    console.log(this.selectedJobDetails);
    let entityIds = [];
    this.selection.forEach(ele => {
      entityIds.push(ele.id);
    })
    if (this.selection.length) {
      obj = {
        "jobId": this.selectedJobDetails.id,
        "entityIds": entityIds,
      }
      this.employmentService.assignJobSupervisorAdmin(obj).subscribe(res => {
        // if (res) {
        this.notify.showMessage("Selected agencies assigned successfully!", 2000)
        this.dialogRef.close(true);
        // } else {
        //   this.notify.showMessage('Something went wrong!', 2000);
        // }
      }, (err: any) => {
        this.notify.showMessage('Something went wrong!', 2000);
      })
    } else {
      this.notify.showMessage('You have not selected any Agency!', 2000);
    }
  }

  // Change pagination event
  getPaginatorData(event) {
    console.log(event)
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllJobAdmin();
  }

}
