import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/@core/services/notification.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_PROPERTY, API_PROPERTY_DOCUMENT } from 'src/@core/services/util/api.endpoints';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { roles } from 'src/app/shared/helpers/roles';
import { ChangeDetectorRef } from '@angular/core';
import { JobService } from 'src/@core/services/job/job.service';
import { AppService } from 'src/@core/services/app.service';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { MatDialog } from '@angular/material';
import { AssignJobAdminComponent } from 'src/app/modules/employment/assign-job-admin/assign-job-admin.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EmployerUploadService } from 'src/@core/services/employer/employer-upload.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';





@Component({
  selector: 'hour4u-admin-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss']
})
export class AddEditProjectComponent implements OnInit {

  form: FormGroup;
  jobTypes: any[];
  employers: any[];
  roles = roles;
  fromWhere: string;
  dialogData: any = {};
  dataFromPopup: boolean;
  submitted = false;
  activationTypes: any;
  selectedFileNames: string[] = [];
  isSocietyActivationSelected: boolean = false;
  selectedValue: any;
  data: any;
  profile = this.appService.getCurrentAccount();
  isSuper: boolean = false;
  employer: Employer;
  @Output() dataRefresh = new EventEmitter();
  totalBill: number = 0;
  selectedData: any = null;
  isUpdate: boolean = false;




  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private notify: NotificationService,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private service: JobService,
    private appService: AppService,
    private employerService: EmployerService,
    private jobTypeService: JobTypeService,
    private dialog: MatDialog,
    private employerUploadService: EmployerUploadService

  ) {
    this.initializeForm();
    this.activationTypes = [
      { value: 'manpower', display: 'Manpower' },
      { value: 'Society Activation', display: 'Society Activation' },
      { value: 'Hotspot', display: 'Hotspot' },
      { value: 'Corporate Activation', display: 'Corporate Activation' }
    ];

    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.selectedData = JSON.parse(navigation.extras.state.data).data;
      this.dialogData.data = this.selectedData;
      this.dialogData.title = JSON.parse(navigation.extras.state.data).title;
      this.form.patchValue(this.dialogData.data);
      this.setupFormValues();
      this.isSocietyActivationSelected = this.dialogData.data.type;
    }
  }

  ngOnInit() {

    // if employer is logged in
    console.log(this.profile)
    if (this.profile.entity.id) {
      this.form.controls['employerId'].setValue(this.profile.entity.id);
      this.form.controls['employerId'].updateValueAndValidity();
    }

    this.loadJobTypes();



    // Subscribe to value changes of quantity and rate controls
    const rateCardsArray = this.form.get('rateCards') as FormArray;
    rateCardsArray.controls.forEach((rateCard: FormGroup) => {
      rateCard.get('quantity').valueChanges.subscribe(() => {
        this.updateTotalBill();
      });

      rateCard.get('rate').valueChanges.subscribe(() => {
        this.updateTotalBill();
      });
    });

    // Subscribe to value changes of the entire form
    this.form.valueChanges.subscribe(() => {
      this.updateTotalBill();
    });

  }

  setupFormValues() {
    // if (this.isSuper)
    this.loadJobTypes();
    // this.loadEmployers();

    if (this.dialogData.description) {
      this.form.controls['description'].setValue(this.dialogData.description);
    }

    // if employer is logged in
    if (this.profile.entity.id) {
      this.form.controls['employerId'].setValue(this.profile.entity.id);
      this.form.controls['employerId'].updateValueAndValidity();
    }

    if (this.dialogData && this.dialogData.data && (this.dialogData.data.id || this.dialogData.data.cloned)) {
      this.isUpdate = true;
      this.form.controls['employerId'].setValue(this.dialogData.data.employerId ? this.dialogData.data.employerId : 'all');
      this.form.controls['employerId'].updateValueAndValidity();
    }
    console.log(this.dialogData)
    if (this.dialogData) {
      if (this.dialogData.from) {
        this.fromWhere = this.dialogData.from;
      }
      if (this.dialogData.jobTypeId) {
        if (this.fromWhere == 'Shifts') {
          this.form.controls['jobTypeId'].setValue(this.dialogData.jobTypeId);
          this.form.controls['jobTypeId'].updateValueAndValidity();
          if (this.isSuper) {
            console.log("callllllllllll")
            this.form.controls['employerId'].setValue(this.dialogData.employerId ? this.dialogData.employerId : 'all');
            this.form.controls['employerId'].updateValueAndValidity();
          }
        }
      }
      const deliverables = this.form.get('deliverables') as FormArray;
      deliverables.clear();
      if (this.dialogData.data.deliverables) {
        this.dialogData.data.deliverables.forEach((element, index) => {
          deliverables.push(this.createDeliverable());
          deliverables.controls[index].patchValue(element)
        });
      }
      this.totalBill = this.calculateTotalBill();
    }
  }

  updateTotalBill() {
    this.totalBill = this.calculateTotalBill();
    console.log('Updated Total Bill:', this.totalBill);
  }


  initializeForm() {
    this.form = this._fb.group({
      id: [''],
      jobTypeId: [''],
      employerId: [''],
      employerName: [''],
      title: [''],
      dressCodeMale: [''],
      dressCodeFemale: [''],
      description: [''],
      tasks: [''],
      requirements: [''],
      prerequisites: [''],
      city: [''],
      type: [''],
      attachments: this._fb.array([]),
      elements: this._fb.array([this.createElement()]),
      deliverables: this._fb.array([this.createDeliverable()]),
      rateCards: this._fb.array([])
    });
  }



  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const attachmentsArray = this.form.get('attachments') as FormArray;

      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];

        // Upload the file to S3 using the existing function
        this.employerUploadService.uploadProfileImage(file).subscribe(
          (s3File) => {
            console.log('File uploaded successfully:', s3File);

            attachmentsArray.push(this._fb.control(s3File.key));
            this.selectedFileNames.push(file.name);
          },
          (error) => {
            console.error('Error uploading file:', error);
          }
        );
      }
    }
  }



  // This method fetches job types and populates the dropdown
  loadJobTypes() {
    this.jobTypeService.searchAndSort(0, 10000, null, null).subscribe(data => {
      this.jobTypes = data.content;
    });
  }

  // This method is triggered when the user selects a job type from the dropdown
  onJobTypeSelectionChange(event: any) {
    const selectedJobTypeId = event.value;

    // Additional logic to fetch details if needed
    // ...

    // Set the selected jobTypeId in the form
    this.form.controls['jobTypeId'].setValue(selectedJobTypeId);
    this.form.controls['jobTypeId'].updateValueAndValidity();
  }





  // Method to add more 'rateCards'
  addMoreRateCards() {
    const rateCardsArray = this.form.get('rateCards') as FormArray;
    rateCardsArray.push(this.createRateCard());
  }

  enableEdit(rateCard: FormGroup) {
    rateCard.get('saved').setValue(false);
    rateCard.enable(); // Enable the entire rate card FormGroup
  }

  // Method to delete a specific 'rateCard' by index
  deleteRateCard(index: number) {
    const rateCardsArray = this.form.get('rateCards') as FormArray;
    rateCardsArray.removeAt(index);
  }

  createElement(): FormGroup {
    return this._fb.group({
      fieldLabel: [''],
      fieldType: [''],
      fieldName: [''],
      isMandatory: ['']
    });
  }

  createRateCard(defaultType: string = ''): FormGroup {
    return this._fb.group({
      type: [defaultType],
      quantity: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      rate: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      totalAmount: [''],
      saved: [false]
    });
  }


  calculateTotalBill(): number {
    this.totalBill = 0;
    console.log("Calllllllllll")
    const rateCards = this.form.get('rateCards') as FormArray;
    rateCards.controls.forEach((rateCard: FormGroup) => {
      const quantity = rateCard.get('quantity').value;
      const rate = rateCard.get('rate').value;
      this.totalBill += quantity * rate;
    });

    return this.totalBill;
  }


  setFieldLabel(index: number) {
    const deliverables = this.form.get('deliverables') as FormArray;
    const deliverable = deliverables.at(index) as FormGroup;

    if (deliverable) {
      const fieldName = deliverable.get('fieldLabel').value;

      if (fieldName) {
        const lowerCaseName = fieldName.replaceAll(' ', '_').toLowerCase();
        deliverable.get('fieldName').setValue(lowerCaseName);
      }
    }
    console.log('Form data:', this.form.value);
  }


  deleteDeliverable(index: number) {
    const deliverables = this.form.get('deliverables') as FormArray;
    deliverables.removeAt(index);
    // Other logic for deleting the deliverable if needed
  }

  addMoreDeliverables() {
    const deliverables = this.form.get('deliverables') as FormArray;
    const newDeliverable = this.createDeliverable();
    newDeliverable.get('isMandatory').setValue(true); // Set 'isMandatory' to true for the new row
    deliverables.push(newDeliverable);
  }


  createDeliverable(): FormGroup {
    return this._fb.group({
      fieldLabel: [''],
      fieldType: [''],
      fieldName: [''],
      isMandatory: [true],
      //saved: [false]
    });
  }




  onSave(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.submitted = false;
      return;
    } else {
      const formData = this.form.value;

      // 'employerName' is a required field
      formData.employerName = 'Your Employer Name'; // Replace with the actual value

      if (typeof (formData.tasks) === 'string') {
        formData.tasks = [formData.tasks];
      } else {
        // Ensure that tasks is always an array
        formData.tasks = formData.tasks ? formData.tasks : [];
      }

      //  your service method is called createJobOrProject
      this.service.create(formData).subscribe(
        (data) => {
          this.submitted = false;
          // Handle success, e.g., display a success message
          this.notify.showMessage("Project created successfully!", 2000);
          this.router.navigate(['/job'], { relativeTo: this.route });
          this.openSuccessModal(data);
        },
        (error: HttpErrorResponse) => {
          this.submitted = false;
          // Handle error, e.g., display an error message
          this.notify.showMessage("Error while creating project!", 2000);
        }
      );
    }
  }

  jobTypeChanged(event) {
    // Ensure that the event object and its target property exist
    if (event && event.source && event.source.value) {
      //  your event contains the selected job type ID
      const selectedJobTypeId = event.source.value;

      // Find the selected job type in the jobTypes array
      const selectedJobType = this.jobTypes.find(jobType => jobType.id === selectedJobTypeId);

      // Update the first element in the rateCards array with the selected job type name
      const rateCards = this.getRateCardsControls();
      if (rateCards.length > 0 && selectedJobType) {
        const firstRateCard = rateCards[0] as FormGroup;
        firstRateCard.get('type').setValue(selectedJobType.name);
      }

      // Check if "Society Activation" is selected
      // this.isSocietyActivationSelected = selectedJobType.name == "society-activation";
      console.log('Selected Job Type:', selectedJobType ? selectedJobType.name : 'Not Found');
      console.log('Is Society Activation Selected:', this.isSocietyActivationSelected);
    }
  }

  activationTypeChanged(event) {
    console.log("Activation type changed")
    if (event && event.source && event.source.value) {
      const selectedValue = event.source.value;

      // Check if "Society Activation or Hotspot or Corporate Activation" is selected
      this.isSocietyActivationSelected =
        selectedValue === "Society Activation" ||
        selectedValue === "Hotspot" ||
        selectedValue === "Corporate Activation";

      if (this.isSocietyActivationSelected) {
        const rateCardsArray = this._fb.array([
          this.createRateCard('Promoters'),
          this.createRateCard('Transportation'),
          this.createRateCard('Goods for activity')
        ]);

        // Set the rateCards array in the form
        this.form.setControl('rateCards', rateCardsArray);
      } else {
        // Remove rateCards if Manpower is selected or any other case
        this.form.removeControl('rateCards');
      }
    }
  }

  getRateCardsControls(): AbstractControl[] {
    return (this.form.get('rateCards') as FormArray).controls;
  }

  // ASSIGN ADMIN DIALOG
  assignAgency(data): void {
    // const employmentAdmin = new EmploymentAdministration();
    // employmentAdmin.employmentId = id;
    const dialogRef = this.dialog.open(AssignJobAdminComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }


  openSuccessModal(data?: any) {
    const dialogRef = this.dialog.open(SuccessModalComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }
}
