import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { API_BOOK, API_PROPERTY, API_PROPERTY_EXPORT } from 'src/@core/services/util/api.endpoints';
import { AppService } from 'src/@core/services/app.service';
import { roles } from 'src/app/shared/helpers/roles';
import { Search } from 'src/@core/interfaces/search';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SocietyFilterDialogComponent } from './view-society/society-filter-dialog/society-filter-dialog.component';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/@core/services/notification.service';
import { BookSocietyDialogComponent } from './book-society-dialog/book-society-dialog.component';

declare var Razorpay: any;



@Component({
  selector: 'hour4u-admin-societies',
  templateUrl: './societies.component.html',
  styleUrls: ['./societies.component.scss']
})
export class SocietiesComponent implements OnInit {
  societies: any[] = [];
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [20, 30, 50];
  roles = roles;
  public userEmail: string = '';
  currentUserRole: any = null;
  searchParams: Search[] = [];
  term: string = '';
  // Initialize the filters with empty values
  filters: { city: string, numberOfFlats: number | null } = {
    city: '',
    numberOfFlats: null
  };
  areFiltersActive: boolean = false;
  profile = this.appService.getCurrentAccount();
  copiedSocieties: { [key: string]: boolean } = {};
  copyTitleSociety: string = 'Share';





  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BookSocietyDialogComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private appService: AppService,
    private dialog: MatDialog,
    private notify: NotificationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageIndex = +params['page'] || 0;
      this.term = params['name'] || ''; // Get the search term from query params

      // Parse and apply filter parameters
      this.filters = {
        city: params['city'] || '',
        numberOfFlats: params['numberOfFlats'] !== undefined ? +params['numberOfFlats'] : null
      };

      this.fetchSocieties();
    });

    this.getCurrentRole();
  }



  fetchSocieties() {
    // Create a HttpParams object to pass the page number and sorting parameter as query parameters
    let params = new HttpParams()
      .set('page', this.pageIndex.toString())
      .set('sort', 'createdOn,desc'); // Sorting parameter

    if (this.term) {
      params = params.set('name', this.term); // Include 'name' query parameter if 'term' is not empty
    }

    // Apply city filter if it exists
    if (this.filters.city) {
      params = params.set('location.city', this.filters.city);
    }

    // Apply numberOfFlats filter if it exists
    if (this.filters.numberOfFlats) {
      params = params.set('numberOfFlats', this.filters.numberOfFlats.toString());
    }

    // Make a GET request to fetch societies with pagination and sorting
    this.http.get<any>(API_PROPERTY, { params }).subscribe(
      (response) => {
        this.societies = response.content;
        this.resultsLength = response.totalElements;
        // console.log(this.appService.getCurrentAccount().email, '&', this.societies);
        this.userEmail = this.appService.getCurrentAccount().email;
        //console.log(this.userEmail)
        this.calculateTimeDifference();

        // Update the URL with the current search and filter parameters
        this.updateUrl();
      },
      (error) => {
        console.error('Failed to fetch societies:', error);
      }
    );
  }

  // Update the URL with the current search and filter parameters
  private updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: this.pageIndex,
        name: this.term,
        city: this.filters.city,
        numberOfFlats: this.filters.numberOfFlats
      },
      queryParamsHandling: 'merge'
    });
  }

  search(data: string) {
    this.term = data;
    this.pageIndex = 0;
    this.searchParams = [];

    this.fetchSocieties();
  }


  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }


  viewSociety(societyId: string) {
    // Navigate to the "view-society" route with the society ID in the route parameter
    //console.log(`View society with ID: ${societyId}`);
    this.router.navigate(['/venues/view-venue', societyId]);
  }

  calculateTimeDifference() {
    this.societies.forEach((society) => {
      if (society && society.availableSlots && society.availableSlots.length > 0) {
        const { timeFrom, timeTo } = society.availableSlots[0];
        if ((timeFrom && timeFrom.length) && (timeTo && timeTo.length)) {
          const hourDifference = timeTo[0] - timeFrom[0];
          const minuteDifference = timeTo[1] - timeFrom[1];

          society.timeDifference = `${hourDifference} h ${minuteDifference} m`;
        }
      } else {
        // Set a default value or handle the case where there are no available slots
        society.timeDifference = 'N/A';
      }
    });
  }

  onPaginateChange(event: any) {
    // Update the page index when the user changes the page
    this.pageIndex = event.pageIndex;

    // Update the URL with the new page number using query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: this.pageIndex },
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
  }
  addSociety() {
    this.router.navigate(['../venues/add-edit-venues'], { relativeTo: this.route });
  }


  exportData() {
    this.http.get(API_PROPERTY_EXPORT, { responseType: 'blob', observe: 'response' })
      .subscribe((response: HttpResponse<Blob>) => {
        // Handle the API response, which is a file to download
        const blob = new Blob([response.body!], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'society-data.xlsx'; // specify the desired filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
  editSociety(societyId: string) {
    // Navigate to the "add-edit-societies" route with the society ID in the route parameter
    // console.log(`Edit society with ID: ${societyId}`);
    this.router.navigate(['/venues/add-edit-venues', societyId]);
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(SocietyFilterDialogComponent, {
      width: '50%',
      height: '100%',
      position: { right: '0px' },
    });

    dialogRef.afterClosed().subscribe((filters: { city: string | null, numberOfFlats: number | null }) => {
      if (filters.city !== null || filters.numberOfFlats !== null) {
        // Apply the selected filters to your API request
        this.filters = filters;
      }

      // Set areFiltersActive based on whether filters are active or not
      this.areFiltersActive = filters.city !== null || filters.numberOfFlats !== null;

      // Fetch societies after handling filters and areFiltersActive
      this.fetchSocieties();
    });
  }

  copySocietyLinkToClipboard(societyId: string) {
    let copyUrl = null;

    if (environment.api_url === 'https://devapi.hour4u.com') {
      copyUrl = `https://dev.workorbits.com/venues/view-venue/${societyId}`;
    }
    if (environment.api_url === 'https://api.hour4u.com') {
      copyUrl = `https://workorbits.com/venues/view-venue/${societyId}`;
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl || '';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // Update the local state to mark the society's link as copied
    this.copiedSocieties[societyId] = true;

    // Reset the button text after a specific duration (e.g., 3 seconds)
    setTimeout(() => {
      this.copiedSocieties[societyId] = false;
    }, 5000);
  }


}