import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/@core/services/notification.service';
import { BehaviorSubject } from 'rxjs';
import { LoginResponse } from 'src/@core/interfaces/login-response.model';
import { EnterpriseAdminService } from '../../../enterprise-admin.service';
import { AppService } from 'src/@core/services/app.service';

@Component({
  selector: 'hour4u-admin-create-champ-user',
  templateUrl: './create-champ-user.component.html',
  styleUrls: ['./create-champ-user.component.scss']
})
export class CreateChampUserComponent implements OnInit {
  createChampForm: FormGroup;
  submitted = false;
  private currentUserSubject = new BehaviorSubject<LoginResponse>(new LoginResponse());
  profile = this.appService.getCurrentAccount();
  loginUserDetails: any = null;
  currentUserRole: any = null;
  addingForWhichRole: any = null;

  constructor(
    public dialogRef: MatDialogRef<CreateChampUserComponent>,
    private _fb: FormBuilder,
    private notify: NotificationService,
    public enterpriseAdminService: EnterpriseAdminService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCurrentRole();
    // this.getRolesByEntity();
  }

  getCurrentAccount(): any {
    return this.currentUserSubject.getValue();
  }

  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      this.loginUserDetails = currentUser;
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }

  // get Roles By Entity name
  // getRolesByEntity() {
  //   if (this.profile) {
  //     this.enterpriseAdminService.getRolesByEntityName('CHAMP').subscribe(res => {
  //       res.forEach(element => {
  //         if (element.roleName == 'CHAMP_ADMIN') {
  //           this.addingForWhichRole = element;
  //         }
  //       });
  //     })
  //   }
  // }

  createForm() {
    this.createChampForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      //email: ['', [Validators.required, Validators.email]],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave(): void {
    this.submitted = true;
    console.log(this.createChampForm, this.addingForWhichRole);
    if (this.createChampForm.invalid) {
      this.createChampForm.markAllAsTouched();
      this.submitted = false;
      return;
    } else {
      this.addTeamMember();
    }
  }

  // add Team Member
  addTeamMember(): void {
    // if (this.addingForWhichRole) {
    let obj = {
      "mobile": this.createChampForm.value.mobile,
      "email": this.createChampForm.value.email,
      "entityType": 'CHAMP',
      "name": this.createChampForm.value.name
    }
    this.enterpriseAdminService.createChamp(this.profile.entity.id, obj).toPromise()
      .then((res: any) => {
        this.autoApproveChamp(res);
      }).catch(err => {
        this.submitted = false;
        console.log(err)
      })
    // } else {
    //   this.notify.error('Proper role not found!');
    // }
  }

  // auto Approve Team Member
  autoApproveChamp(res) {
    this.enterpriseAdminService.updateChampStatus(res.id, 'ACCEPTED').toPromise()
      .then((res: any) => {
        this.submitted = false;
        this.notify.showMessage('Team Member Added Successfully!', 3000);
        this.dialogRef.close(res);
      }).catch(err => {
        this.submitted = false;
        console.log(err)
      })
  }


}
