import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';
import { AppService } from 'src/@core/services/app.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[showIfRole]'
})
export class ShowIfRoleDirective implements OnInit {

  // tslint:disable-next-line:variable-name
  private _roles: string[];
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private appService: AppService
  ) { }

  @Input() set showIfRole(roles: string[]) {
    this._roles = roles;
  }
  ngOnInit(): void {
    this.appService.currentUser.subscribe(currentUser => {
      if (!currentUser) { return; }
      if (_.intersectionWith(currentUser.roles, this._roles, _.includes).length > 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
