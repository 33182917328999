import { Component, OnInit, ViewChild } from '@angular/core';
import { GiveRatingDialogComponent } from './give-rating-dialog/give-rating-dialog.component';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { Employment, Dates } from 'src/@core/interfaces/employment/employment.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Search } from 'src/@core/interfaces/search';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { JobService } from 'src/@core/services/job/job.service';
import { JobSeeker } from 'src/@core/interfaces/job-seeker/job-seeker.model';
import { JobApplicationService } from 'src/@core/services/job/job-application.service';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { EmploymentHistoryService } from 'src/@core/services/employment/employment-history.service';
import { GiveBulkRatingDialogComponent } from './give-bulk-rating-dialog/give-bulk-rating-dialog.component';
import { roles } from 'src/app/shared/helpers/roles';
import { environment } from 'src/environments/environment';
import { CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { STATUS } from 'src/app/shared/helpers/status';
import { EnterpriseAdminService } from '../../enterprise-admin/enterprise-admin.service';


@Component({
  selector: 'hour4u-admin-active-employments',
  templateUrl: './active-employments.component.html',
  styleUrls: ['./active-employments.component.scss']
})
export class ActiveEmploymentsComponent implements OnInit {
  loadComponent = true;
  employment: any = null;
  searchParams: Array<Search> = [];
  jobSeekers = Array<JobSeeker>();
  jobApplications: JobApplication[];
  jobApplicationPage: JobApplication[];
  activeDate: any;
  roles = roles;
  paramDate;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  resultsLength = 0;
  pageIndex = 0;
  copiedEmployer: boolean = false;
  copyTitleEmployer: string = 'Copy link to share';
  showNoAccessState: boolean = false;
  status = STATUS;
  workImages: any[] = [];
  totalTasks: number = 0;

  constructor(private route: ActivatedRoute,
    private employmentService: EmploymentService,
    private notify: NotificationService,
    private employerService: EmployerService,
    private jobService: JobService,
    private jobTypeService: JobTypeService,
    private jobApplicationService: JobApplicationService,
    private jobSeekerService: JobSeekerService,
    public employmentHistoryService: EmploymentHistoryService,
    private dialog: MatDialog,
    private router: Router,
    public enterpriseAdminService: EnterpriseAdminService
  ) {

  }

  ngOnInit() {

    //   this.route.queryParams.subscribe(params => {
    //     const employment = params["employment"];
    //  });

    this.paramDate = this.route.snapshot.paramMap.get('date');


    document.getElementById('detail-header').style.display = 'none';

    this.route.params.subscribe(async data => {
      await this.loadEmploymentData(data.id);
      this.searchParams.push(new Search('employmentId', data.id));
    });

  }


  loadMap() {
    this.loadComponent = false;
  }

  loadList() {
    this.loadComponent = true;
  }

  async loadEmploymentData(id: string) {
    await this.enterpriseAdminService.getWorkOrderById(id).toPromise()
      .then(data => {
        this.employment = data;
        this.employment.id = data.workOrder.id;
        console.log(this.employment)
        // fetch active date
        // finding active date to view active employment
        this.activeDate = this.employment.workOrder.dates.find(x => x.isActive)

        // if user is view past employment
        // date will come along with navigation params
        // and active date will be replaced with requested date
        console.log(this.paramDate)
        if (this.paramDate) {
          this.activeDate = this.employment.workOrder.dates.find(x => x.date == this.route.snapshot.paramMap.get('date'))
          console.log(this.activeDate)
        }
      }).catch(error => {
        this.notify.showMessage('Could not load employment data...', 2000);
      }).finally(async () => {
        console.log(this.employment)
        // load employer
        // await this.employerService.getById(this.employment.project.employerId).toPromise()
        //   .then(data => {
        //     this.employment.employer = data;
        this.showNoAccessState = false;
        // if (this.employment && JSON.parse(localStorage.getItem(CURRENT_EMPLOYER))) {
        //   if (this.employment.employerId != JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)).id) {
        //     document.getElementById('detail-header').style.display = 'none';
        //     this.showNoAccessState = true;
        //   } else {
        //     document.getElementById('detail-header').style.display = 'block';
        //     this.loadJob(this.employment.id);
        //     this.loadJobApplications();
        //   }
        // } else {
        document.getElementById('detail-header').style.display = 'block';
        this.loadJob(this.employment.id);
        this.loadJobApplications();
        // }
        // });
      });
  }

  async loadJob(employmentId) {
    // load job
    console.log(this.employment)
    await this.jobService.getById(this.employment.workOrder.jobId).toPromise()
      .then(job => {
        this.employment.job = job;
      }).finally(async () => {
        await this.jobTypeService.getById(this.employment.job.jobTypeId).toPromise()
          .then(jobType => {
            this.employment.job.jobType = jobType;
          });
      })
  }

  async loadJobApplications() {
    this.notify.showMessage('Loading applicants .....', 2000);
    console.log(this.employment)
    const serachParams = [new Search('employmentId', this.employment.id)];
    //if (this.employment.status == 'Completed') {
    serachParams.push(new Search('status', 'COMPLETED'));
    serachParams.push(new Search('status', 'APPROVED'));
    serachParams.push(new Search('employmentId', this.employment.workOrder.id));
    /*} else {
      serachParams.push(new Search('status', 'APPROVED'));
    }*/

    await this.jobApplicationService.searchAndSort(0, 5000, null, null, serachParams).toPromise()
      .then(data => {
        if (data.content.length > 0) {
          this.jobApplications = data.content;
          this.resultsLength = this.jobApplications.length;
          this.jobApplications.forEach(async ja => {
            // laod js
            await this.jobSeekerService.getById(ja.jobSeekerId).toPromise()
              .then(async (js) => {
                ja.tempJobSeeker = js;
                // load history
                await this.employmentHistoryService.searchAndSort(0, 1000, null, null, [new Search('employmentId', this.employment.workOrder.id), new Search('jobSeekerId', ja.jobSeekerId)]).toPromise()
                  .then(data => {
                    ja.history = data.content[0];
                    this.jobApplicationPage = this.jobApplications.slice(0, this.pageSize);
                    console.log(this.jobApplicationPage)
                    this.workImages = [];
                    this.jobApplicationPage.forEach(ele => {
                      // console.log(ele)
                      // this.workImages = ele.history.imageProofs.filter(ip => ip.proofEnum == 'MIDDLE');
                      // this.totalTasks = this.totalTasks + this.workImages.length;
                      if (ele.history && ele.history.imageProofs && ele.history.imageProofs.length) {
                        console.log(ele, ele.history.imageProofs)
                        let workImages = ele.history.imageProofs.filter(ip => ip.proofEnum == 'MIDDLE');
                        console.log(workImages)
                        this.workImages = workImages;
                        this.totalTasks = this.totalTasks + this.workImages.length;
                        // if (workImages.length) {
                        //   workImages.forEach((el: any) => {
                        //     el.tempJobSeeker = ele;
                        //     this.workImages.push(el)
                        //   })
                        // }
                      }
                    })
                  });
              });
          });

        }
      })

  }

  getPageData(e: PageEvent) {
    this.jobApplicationPage = this.jobApplications.slice(e.pageIndex * e.pageSize, (e.pageIndex + 1) * e.pageSize);
  }


  openRatingDialog() {
    const dialogRef = this.dialog.open(GiveBulkRatingDialogComponent, {

      width: '700px',
      disableClose: true,
      data: { jobApplication: this.jobApplications, employment: this.employment }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.dataChange.next(1);
      }
    });
  }

  copyLinkToClipboardForEmployer() {
    let copyUrl = null;
    this.copiedEmployer = true;
    this.copyTitleEmployer = 'Copied to your clipboard!';

    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://dev.workorbits.com' + this.router.url
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://workorbits.com' + this.router.url
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedEmployer = false;
      this.copyTitleEmployer = 'Copy link to share';
    }, 7000);
  }

  dataRefresh() {
    this.router.navigateByUrl('/employment/all/all');
  }
}
