import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_CANCEL_SHIFT, API_CHECKOUT_SHIFT, API_FETCH_AGENCY, API_FETCH_TEAM_MEMBER, API_INVOICE_BY_ID, API_JOB, API_FETCH_DASHBOARD } from '../util/api.endpoints';
import { Injectable } from '@angular/core';
import { Job } from 'src/@core/interfaces/job/job.model';
import { Observable } from 'rxjs';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import * as _ from 'lodash';

/**
 * Service response for fetch, create, update and delete the Job.
 */
@Injectable({
  providedIn: 'root'
})
export class JobService extends BaseService<Job> {
  constructor(private http: HttpClient) {
    super(http, API_JOB);
  }

  getCancelShiftDetails(id) {
    const url = API_CANCEL_SHIFT + '/info/' + id;
    console.log(url)
    return this.http.get<any>(url);
  }

  cancelShift(request) {
    const url = API_CANCEL_SHIFT;
    console.log(url)
    return this.http.post<any>(url, request);
  }

  getEmployementDetails(id) {
    const url = API_CHECKOUT_SHIFT + '/fetch?employmentId=' + id;
    return this.http.get<any>(url);
  }

  deleteJobTemplate(id) {
    const url = API_JOB + '/' + id;
    return this.http.delete<any>(url);
  }

  getInvoiceDetails(id) {
    const url = API_INVOICE_BY_ID + '/' + id;
    return this.http.get<any>(url);
  }

  searchAndSortAgencies(entityId: any, page: number, size: number, sort: string, order: string, searchList?: any[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = null;
    if (entityId) {
      url = API_FETCH_AGENCY + '/' + entityId + '?page=' + page;
    } else {
      url = API_FETCH_AGENCY + '?page=' + page;
    }
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }

  searchAndSortTeamMembers(entityId: any, page: number, size: number, sort: string, order: string, searchList?: any[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = null;
    if (entityId) {
      url = API_FETCH_TEAM_MEMBER + '/' + entityId + '?page=' + page;
    } else {
      url = API_FETCH_TEAM_MEMBER + '?page=' + page;
    }
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }

  searchAndSortDashboardData(params: HttpParams): Observable<PageableResponse<any>> {
    let url = `${API_FETCH_DASHBOARD}?sort=createdOn,desc`;

    return this.http.get<PageableResponse<any>>(url, { params: params });
  }




  searchAndSortChamps(entityId: any, entityType: any, page: number, size: number, sort: string, order: string, searchList?: any[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = null;
    if (entityId) {
      url = API_FETCH_AGENCY + '/' + entityId + '?entityType=' + entityType;
    } else {
      url = API_FETCH_AGENCY + '?entityType=' + entityType;
    }

    url += '&page=' + page;
    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }
}
