import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurdBaseModule } from 'src/app/shared/base/curd-base.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { ColorFadeModule } from 'src/@core/pipes/color/color-fade.module';
import { MaterialShared } from 'src/app/shared/material-shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { JobComponent } from './job/job.component';
import { AddEditJobComponent } from './job/add-edit-job/add-edit-job.component';
import { ViewJobComponent } from './job/view-job/view-job.component';
import { AddEditProjectComponent } from './job/add-edit-project/add-edit-project.component';
import { SuccessModalComponent } from './job/success-modal/success-modal.component';
import { JobBurgerMenuComponent } from './job/job-burger-menu/job-burger-menu.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: ''
  // },
  {
    path: '', component: JobComponent
  },
  {
    path: 'view/:id', component: ViewJobComponent
  },
];


@NgModule({
  declarations: [JobComponent, AddEditJobComponent, ViewJobComponent, AddEditProjectComponent, SuccessModalComponent, JobBurgerMenuComponent],
  imports: [
    FlexLayoutModule,
    IconModule,
    ColorFadeModule,
    MaterialShared,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CurdBaseModule,
    RouterModule.forChild(routes)
  ],
  entryComponents: [AddEditJobComponent, SuccessModalComponent, JobBurgerMenuComponent]
})



export class JobModule { }
