import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Search } from '../../../../@core/interfaces/search';
// tslint:disable-next-line:max-line-length
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatDialog,
  MatFormFieldDefaultOptions,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { AddEditEmploymentComponent } from './add-edit-employment/add-edit-employment.component';
import { Employment } from 'src/@core/interfaces/employment/employment.model';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { ProgressBarService } from 'src/@core/services/progress-bar.service';
import { ColumnVisibilityDialogComponent } from 'src/app/shared/components/column-visibility-dialog/column-visibility-dialog.component';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { STATUS } from 'src/app/shared/helpers/status';
import { AppService } from 'src/@core/services/app.service';
import { roles } from 'src/app/shared/helpers/roles';
import { ApproveEmploymentComponent } from './approve-employment/approve-employment.component';
import { ActivateEmploymentComponent } from './activate-employment/activate-employment.component';
import { EmploymentFilter } from './employment-filter.model';
import { EmploymentFilterDialogComponent } from './employment-filter-dialog/employment-filter-dialog.component';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { AssignAdminDialogComponent } from '../assign-admin-dialog/assign-admin-dialog.component';
import { EmploymentAdministration } from "../../../../@core/interfaces/employment/employment-administration.model";
import { DeActivateEmploymentComponent } from './de-activate-employment/de-activate-employment.component';
import { NotificationService } from 'src/@core/services/notification.service';
import { WorkLogService } from 'src/@core/services/employment/worklog.service';
import { LocalStorageService } from 'src/@core/services/local-storage.service';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';
import { CancelEmployementComponent } from './cancel-employement/cancel-employement.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'hour4u-admin-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class EmploymentComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  term = '';
  isSearch = true;
  dataSource: MatTableDataSource<Employment>;
  dataChange: BehaviorSubject<any>;
  prevUrl: String;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  status = STATUS;
  roles = roles;
  resultsLength = 0;
  profile = this.appService.getCurrentAccount();
  title = 'Job';
  columns = [
    { columnDef: 'position', visible: true, label: 'Position' },
    { columnDef: 'jobSeeker', visible: true, label: 'Job Seeker' },
    { columnDef: 'jobId', visible: true, label: 'Job' },
    { columnDef: 'employmentTitle', visible: true, label: 'Employment Title' },
    { columnDef: 'date', visible: true, label: 'Date' },
    { columnDef: 'time', visible: true, label: 'Time' },
    { columnDef: 'location', visible: true, label: 'Location' },
    { columnDef: 'status', visible: true, label: 'Status' },
    { columnDef: 'action', visible: true, label: 'Action' },
  ];
  displayedColumns = this.columns.map(x => x.columnDef);

  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  searchParams: Search[] = [];
  searchFilters: Search[] = [];
  filters: EmploymentFilter;

  employer: Employer = this.appService.getEmployer();
  isEmployerLogin = this.appService.getIsEmployerLogin();
  sort_field: string = '';
  links: any[] = [
    {
      label: 'All Jobs',
      route: '/employment/all/all'
    },
    {
      label: 'On-going',
      route: '/employment/all/Active'
    },
    {
      label: 'Upcoming',
      route: '/employment/all/Active/false'
    },
    {
      label: 'Completed',
      route: '/employment/all/Completed'
    }
  ];

  urlStatus;
  copyTitleEmployer: string = 'Copy Link to Share';
  copiedEmployer: boolean = false;
  selectedRoute: string = null;
  someText = "Default";

  constructor(private dialog: MatDialog,
    private service: EmploymentService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private appService: AppService,
    private progress: ProgressBarService,
    private notify: NotificationService,
    private workLogService: WorkLogService,
    private jobPipe: JobPipe,
    private _localStorageService: LocalStorageService,
    private datePipe: DatePipe) {

    if (this.router.getCurrentNavigation().previousNavigation) {
      this.prevUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    }
  }

  ngOnInit() {
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.filters = new EmploymentFilter();
    console.log("xsxsx", this.dataSource.filteredData.length);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.term = '';
    this.sort_field = null;
    this.dataSource.data = [];
    setTimeout(() => {
      this.route.params.subscribe(data => {
        this.selectedRoute = this.router.url;
        this.urlStatus = data.status;
        if (this.urlStatus) {
          this.searchParams = [];
          if (this.urlStatus && this.urlStatus != 'all')
            this.searchParams.push(new Search('status', data.status));
          if (data && data.id) {
            this.searchParams.push(new Search('inProgress', data.id));
          }

          //this.paginator.pageIndex = 0;
          //             " last status: " + this._localStorageService.getEmptLastStatus() + 
          //             " curr status: " + this.urlStatus);
          if (this.prevUrl && (this.prevUrl.includes('employment/view')
            || this.prevUrl.includes('employment/job-applications')
            || this.prevUrl.includes('employment/active')
            || this.prevUrl.includes('employment/job-seeker-payment')
            || this.prevUrl.includes('employment/work-log')
            || this.prevUrl.includes('employment/payment')) &&
            this._localStorageService.getEmptListPage() && this._localStorageService.getEmptLastStatus() == this.urlStatus) {
            this.paginator.pageIndex = this._localStorageService.getEmptListPage();
          }
          else {
            this.paginator.pageIndex = 0;
            this._localStorageService.setEmptListPage(0, this.urlStatus);
          }

          if (this.prevUrl && (this.prevUrl.includes('employment/view')
            || this.prevUrl.includes('employment/job-applications')
            || this.prevUrl.includes('employment/active')
            || this.prevUrl.includes('employment/job-seeker-payment')
            || this.prevUrl.includes('employment/work-log')
            || this.prevUrl.includes('employment/payment'))) {
            if (this._localStorageService.getJSearchFilters().length > 0) {
              this.searchFilters = this._localStorageService.getJSearchFilters();
              this.filters = this.convertFilter(this.searchFilters);
            }

            if (this._localStorageService.getJSearchParams().length > 0) {
              this.searchParams = this._localStorageService.getJSearchParams();
              this.setSearchPrams();
            }

            if (this._localStorageService.getJSortParams()) {
              this.sort_field = this._localStorageService.getJSortParams();
            }
          }

          if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
            this.searchParams.push(new Search('employerId', this.profile.user_details.employerId));
          }
          this.dataChange.next(1);
        }
      });
    }, 0);

    this.searchParams.push(new Search('isDelete', false));
    // if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
    //   this.isEmployer = true;
    //   this.userEmployerId = this.profile.user_details.employerId;
    //   this.searchParams.push(new Search('employerId', this.profile.user_details.employerId));
    // }

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    merge(this.sort.sortChange, this.paginator.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.progress.show();
          // tslint:disable-next-line:no-non-null-assertion
          return this.service!.searchAndSortEmployment(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, [...this.searchParams, ...this.searchFilters], 'Employment');
        }),
        map(data => {
          this.progress.hide();
          this.resultsLength = data.totalElements;
          return data.content;
        }),
        catchError(() => {
          this.progress.hide();
          return observableOf([]);
        })
      ).subscribe(data => {
        this._localStorageService.setEmptListPage(this.paginator.pageIndex, this.urlStatus);
        this.dataSource.data = data;
        this._localStorageService.setJSearchFilters(this.searchFilters);
        this._localStorageService.setJSearchParams(this.searchParams);
        this._localStorageService.setJSortParams(this.sort.direction);
        this.progress.hide();
      });
  }



  reloadTable() {
    this.dataChange.next(1);

  }

  search(data?) {
    //  let filterValue = data.trim();
    //  filterValue = filterValue.toLowerCase();
    //  this.dataSource.filter = filterValue;

    this.searchParams = [];
    if (data) {
      this.searchParams.push(new Search('employmentTitle', data));
    }
    this.reloadTable();
  }

  sortData(direction) {
    this.sort.active = 'createdOn';
    this.sort.direction = direction.value;
    this.sort.sortChange.next();
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(EmploymentFilterDialogComponent, {
      disableClose: false,
      width: '50%',
      height: '100%',
      position: { right: '0px' },
      data: this.filters
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.applyFilters();
      }
    });
  }

  applyFilters() {

    this.searchFilters = [];
    this.filters.status.forEach(status => {
      if (status.checked) {
        this.searchFilters.push(new Search('status', status.value));
      }
    });

    this.filters.createdOn.forEach(date => {
      this.searchFilters.push(new Search('createdOn', date.toISOString().slice(0, -1)));
    });

    if (this.filters.employer) {
      this.searchFilters.push(new Search('employerId', this.filters.employer.id));
    }
    if (this.filters.city) {
      this.searchFilters.push(new Search('location.city', this.filters.city));
    }

    this.dataChange.next(1);
  }

  create(): void {

    // redirect to new component

    this.router.navigate(['/create-shift']);


    //Open in PopUp
    // const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
    //   width: '770px',
    //   panelClass: 'add-dialog',
    //   disableClose: true,
    //   data: {}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.dataChange.next(1);
    // });
  }


  openDialogColumnOptions(): void {
    const dialogRef = this.dialog.open(ColumnVisibilityDialogComponent, {
      width: '300px',
      data: this.columns,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.displayedColumns = [];
          this.columns.forEach((value, key) => {
            if (value.visible) {
              this.displayedColumns.push(value.columnDef);
            }
          });
        }
      }
    );
  }

  getToolTip(day) {
    switch (day) {
      case 'SINGLE_DAY':
        return 'Single Day';
      case 'MULTI_DAYS':
        return 'Multiple Days';
      case 'RECURRING_DAYS':
        return 'Recurring Days';
      default:
        return '';
    }
  }

  viewJobApplications(row) {
    this.router.navigateByUrl('/employment/job-applications/all/' + row.id);
  }

  viewProgress(employment: Employment) {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'employment': employment }
    }
    this.router.navigateByUrl(`/employment/active/${employment.id}`, navigationExtras);
  }

  /**
   * Approve Dialog box
   */
  openDialogApprove(employment: Employment): void {
    console.log("employment-------", employment)
    const dialogRef = this.dialog.open(ApproveEmploymentComponent, {
      width: '900px',
      panelClass: 'assign-dialog',
      data: employment,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.dataChange.next(1);
          this.openDialogActivate(employment);
        }
        else {
          this.dataChange.next(1);

        }
      }
    );
  }

  reloadData() {
    this.dataChange.next(1);
  }

  /**
   * Approve Dialog box
   */
  openDialogActivate(employment: Employment): void {
    const dialogRef = this.dialog.open(ActivateEmploymentComponent, {
      width: '400px',
      data: employment,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.dataChange.next(1);

      }
    );
  }

  openDialogDeActivate(employment: Employment): void {
    const dialogRef = this.dialog.open(DeActivateEmploymentComponent, {
      width: '400px',
      data: employment,
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.dataChange.next(1);

      }
    );
  }

  ngOnDestroy(): void {
    if (!this.router.url.includes('employment/view') && !this.router.url.includes('employment/job-applications')
      && !this.router.url.includes('employment/active')
      && !this.router.url.includes('employment/job-seeker-payment')
      && !this.router.url.includes('employment/work-log')
      && !this.router.url.includes('employment/payment')) {
      this._localStorageService.setEmptListPage(0, '');
      this._localStorageService.setJSearchFilters([]);
      this._localStorageService.setJSearchParams([]);
      this._localStorageService.setJSortParams([]);
    }
  }

  copyLinkToClipboardForEmployer() {
    let copyUrl = null;
    this.copiedEmployer = true;
    this.copyTitleEmployer = 'Copied to your clipboard!';
    console.log(this.router.url)
    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://dev.workorbits.com' + this.router.url
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://workorbits.com' + this.router.url
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedEmployer = false;
      this.copyTitleEmployer = 'Copy Link to Share';
    }, 7000);
  }

  dataRefresh() {
    this.dataChange.next(1);
  }
  // UPDATE EMPLOYEMENT
  update(object: any): void {
    event.stopPropagation();
    // let data = null;
    // data = Object.assign({}, object);
    // const dialogRef = this.dialog.open(AddEditEmploymentComponent, {
    //   width: '770px',
    //   panelClass: 'add-dialog',
    //   disableClose: true,
    //   data: data
    // });
    this.router.navigate(['/create-shift'], { queryParams: { mode: 'edit', employmentId: object.id } });

  }

  convertFilter(sf: any) {
    this.searchFilters.forEach((search) => {
      if (search.column && search.content) {
        if (search.column == 'createdOn') {
          console.log(search.column + search.content);
          let d = new Date();
          d.setFullYear(search.content.slice(0, 4));
          let y: number = search.content.slice(5, 7);
          d.setMonth(y - 1);
          d.setDate(search.content.slice(8, 10));
          d.setHours(0);
          d.setMinutes(0);
          this.filters.createdOn[0] = d;
        }
        if (search.column == 'jobTypeName')
          this.filters.employer = search.content;
        if (search.column == 'address.city')
          this.filters.city = search.content;
        if (search.column == 'status') {
          if (search.content == 'Pending')
            this.filters.status[0].checked = true;
          if (search.content == 'Active')
            this.filters.status[1].checked = true;
          if (search.content == 'Rejected')
            this.filters.status[2].checked = true;
          if (search.content == 'Hold')
            this.filters.status[3].checked = true;
        }
      }
    });
    return this.filters;
  }

  setSearchPrams() {
    this.searchParams.forEach((search) => {
      if (search.column && search.content) {
        if (search.column == "employmentTitle") {
          this.term = search.content
          this.isSearch = true;
        }
      }
    })
  }

  resetFilter() {
    this._localStorageService.setEmptListPage(0, '');
    this._localStorageService.setJSearchFilters([]);
    this._localStorageService.setJSearchParams([]);
    this._localStorageService.setJSortParams([]);
    this.searchParams = [];
    this.sort.direction = null;
    this.paginator.pageIndex = 0;
    this.sort_field = null;
    this.term = '';
    this.dataChange.next(1);
  }

  // view Tasks
  viewTasks(job) {
    console.log(job)
    // return;
    this.router.navigate(['/employment/active/workproof/' +
      job.id, {
      date: this.datePipe.transform(job.dates[0].date, "yyyy,MM,dd")
    }]);
  }
}
