import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'src/@core/services/notification.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { AddChampComponent } from "../add-champ/add-champ.component";
import { Router } from '@angular/router';
import { JobPipe } from 'src/@core/pipes/data/job.pipe';
import { AssignAdminDialogComponent } from '../../employment/assign-admin-dialog/assign-admin-dialog.component';
import { AppService } from 'src/@core/services/app.service';
import { WorkLogService } from 'src/@core/services/employment/worklog.service';

@Component({
  selector: 'hour4u-job-admin-shift-burger-menu',
  templateUrl: './shift-burger-menu.component.html',
  styleUrls: ['./shift-burger-menu.component.scss']
})
export class JobAdminShiftBurgerMenuComponent implements OnInit {
  @Input() row: any;
  @Input() status: any;
  @Input() roles: any;

  @Output() dataRefresh = new EventEmitter();

  copyTitleInvoiceShare: string = 'Share Invoice Payment';
  copiedShareInvoice: boolean = false;
  screenWidth = window.innerWidth;
  role: any = null;

  constructor(
    private notify: NotificationService,
    private dialog: MatDialog,
    public router: Router,
    private jobPipe: JobPipe,
    private appService: AppService,
    private workLogService: WorkLogService
  ) { }

  ngOnInit() {
    this.getCurrentRole();
  }

  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.role = ele;
      })
    })
  }

  // View Employement
  viewEmployement(row) {
    this.router.navigateByUrl('/employment/view/' + row.employmentId);
  }

  // Add Champ
  addChamp(object?: any): void {
    let data = null;
    data = Object.assign({}, object);
    console.log(data)
    data.id = data.employmentId;
    const dialogRef = this.dialog.open(AddChampComponent, {
      width: '700px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }

  // Share Shift
  shareShift(object: any): void {
    console.log(object)
    let copyUrl = null;

    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://devapp.workorbits.com/#/available-job-details-global/' + object.employment.id;
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://app.workorbits.com/#/available-job-details-global/' + object.employment.id;
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
  }

  // view Employement 
  viewEmployment(row) {
    console.log(row)
  }

  // assign SuperVisor
  assignSuperVisor(row) {
    console.log(row)
    row.employment.fromWhere = 'JOB_ADMIN';
    const dialogRef = this.dialog.open(AssignAdminDialogComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: row.employment
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }

  // view JobSeeker's Schedule
  openJobSeekerSchedule(row) {
    console.log(row)
    if (row.employmentId && row.employment && row.employment.dates && row.employment.dates.length != 0) {
      this.router.navigate(['/employment/active/' + row.employmentId, {
        date: row.employment.dates[0].date
      }])
    }
  }

  // view Payment Requests
  async openPaymentRequests(row) {
    console.log(row)
    let jobt: string;
    await this.jobPipe.transform(row.employment.jobId).then(v => jobt = v);
    this.router.navigate(['/employment/job-seeker-payment/' + row.employment.id], {
      queryParams: {
        jobTitle: jobt,
        employmentTitle: row.employment.employmentTitle,
        employerId: row.employment.employerId,
        date: row.employment.dates[0].date
      }
    });
  }

  // open WorkLogs of job admin
  openWorkLogs(row) {
    console.log(row)
    this.router.navigateByUrl('/job-admin/job-admin-worklog/' + row.employment.id + '/' + row.id);
    return
  }

  // open WorkLog Of Supervisor
  openWorkLogOfSupervisor(row) {
    console.log(row)
    this.router.navigateByUrl('/supervisor-admin/supervisor-worklog/' + row.employment.id + '/' + row.id);
    return
  }

  // SHARE PROOF OF WORK
  async shareProofOfWork(row) {
    const blob = await this.workLogService.getProofOfWork(row.employment.id)
      .catch(error => {
        this.notify.showMessage('Unable to download work log.', 2000);
      });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'proof_of_work_' + row.employment.id + '.xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  }
}