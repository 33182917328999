/// <reference types="@types/googlemaps" />
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Address } from 'src/@core/interfaces/address.model';
import { NotificationService } from 'src/@core/services/notification.service';
// import {} from 'googlemaps';
declare var google: any;

// @ts-ignore

@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss']
})
export class SearchAddressComponent implements OnInit {

  @Input('label') label: string;
  @Input('addressObj') addressObj: Address = {};
  zoom: number;
  isSnazzyInfoWindowOpened = false;

  private geoCoder;

  // @ts-ignore
  @Output() onAddressSelected = new EventEmitter<any>();
  @Input('defaultAddress') defaultAddress = '';
  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef = null;
  latitude: any;
  longitude: any;
  timeoutRef: ReturnType<typeof setTimeout>;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private notify: NotificationService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef
  ) { }


  ngOnInit() {
    console.log(this.addressObj)
    if (this.defaultAddress !== '') {
      console.log(this.defaultAddress)
      this.searchElementRef.nativeElement.value = this.defaultAddress;
    }
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      console.log("@@@@@@@@@@@")
      if (this.addressObj.address) {
        this.setZoom();
      } else {
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        // types: ['address', '(cities)', ],
        componentRestrictions: { country: 'in' }
      });
      console.log("111111111")
      autocomplete.addListener('place_changed', () => {
        console.log("Callllllllllll")
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log("#######3")

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.addressObj.latitude = place.geometry.location.lat();
          this.addressObj.longitude = place.geometry.location.lng();
          this.getAddress(this.addressObj.latitude, this.addressObj.longitude);
          this.zoom = 12;
        });
      });
    });
  }

  setZoom() {
    this.zoom = 8;
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    let self = this;
    navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      // if (result.state == 'granted') {
      //   self.getLocation();
      // } else if (result.state == 'prompt') {
      //   self.getLocation();
      // } else if (result.state == 'denied') {
      //   self.getLocation();
      // }
    })
  }

  getLocation() {
    console.log("@@@@@@@@")
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.addressObj.latitude = position.coords.latitude;
        this.addressObj.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.addressObj.latitude, this.addressObj.longitude);
      }, (err) => {
        this.notify.showMessage(err.message, 3000);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.addressObj.latitude = $event.coords.lat;
    this.addressObj.longitude = $event.coords.lng;
    this.getAddress(this.addressObj.latitude, this.addressObj.longitude);
  }

  getAddress(latitude, longitude) {
    this.addressObj.latitude = latitude;
    this.addressObj.longitude = longitude;
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results)
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.addressObj.address = results[0].formatted_address;
          this.showSnazzyInfoWindow();
          this.addressObj.placeId = results[0].place_id;

          for (let i = 0; i < results[0].address_components.length; i++) {
            if (results[0].address_components[i].types[0] == 'locality') {
              this.addressObj.city = results[0].address_components[i].long_name;
            }
            if (results[0].address_components[i].types[0] == 'administrative_area_level_1') {
              this.addressObj.region = results[0].address_components[i].long_name;
            }
            if (results[0].address_components[i].types[0] == 'country') {
              this.addressObj.country = results[0].address_components[i].long_name;
            }
            if (results[0].address_components[i].types[0] == 'postal_code') {
              this.addressObj.zip = results[0].address_components[i].long_name;
            }
          }
          this.transmitData();
        } else {
          this.notify.showMessage('No search results found', 3000, 'OK');
        }
      } else {
        this.notify.showMessage('Google maps location failed due to: ' + status, 3000, 'OK');
      }

    });
    console.log("address object", this.addressObj)

  }


  transmitData() {
    this.onAddressSelected.emit(this.addressObj);
  }

  toggleSnazzyInfoWindow() {
    this.isSnazzyInfoWindowOpened = !this.isSnazzyInfoWindowOpened;
  }

  showSnazzyInfoWindow() {
    this.isSnazzyInfoWindowOpened = true;
  }

  searchByaddress() {
    if (this.searchElementRef.nativeElement.value) {
      if (this.searchElementRef.nativeElement.value.includes('.')) {
        let data = this.searchElementRef.nativeElement.value.split(',');
        console.log(data)
        if (data.length == 2) {
          if (data[1] != "") {
            this.timeoutRef = setTimeout(() => {
              if (data[0].length > 7 && data[1].length > 7) {
                this.getAddress(Number(data[0].trim()), Number(data[1].trim()));
              }
            }, 700)
          }
        }
      }
      // this.parseCoordinates(this.searchElementRef.nativeElement.value);
    }
  }

  parseCoordinates(value) {
    const coordinatesString = value;
    // const coordinatesString = "21°11'26.6\"N 72°50'16.2\"E";

    const latitudeRegex = /(\d+)°(\d+)'([\d.]+)\"N/;
    const longitudeRegex = /(\d+)°(\d+)'([\d.]+)\"E/;

    const latitudeMatch = coordinatesString.match(latitudeRegex);
    const longitudeMatch = coordinatesString.match(longitudeRegex);

    if (latitudeMatch && longitudeMatch) {
      const latDegrees = parseInt(latitudeMatch[1]);
      const latMinutes = parseInt(latitudeMatch[2]);
      const latSeconds = parseFloat(latitudeMatch[3]);

      const lngDegrees = parseInt(longitudeMatch[1]);
      const lngMinutes = parseInt(longitudeMatch[2]);
      const lngSeconds = parseFloat(longitudeMatch[3]);

      this.latitude = this.convertDMStoDD(latDegrees, latMinutes, latSeconds);
      this.longitude = this.convertDMStoDD(lngDegrees, lngMinutes, lngSeconds);

      console.log('Latitude:', this.latitude);
      console.log('Longitude:', this.longitude);
      this.getAddress(this.latitude, this.longitude);
    } else {
      console.error('Invalid coordinates format');
    }
  }

  convertDMStoDD(degrees: number, minutes: number, seconds: number): number {
    return degrees + (minutes / 60) + (seconds / 3600);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutRef);
  }
}
