import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ADD_CHAMP, API_ADD_TEAM_MEMBER, API_APPROVE_TEAM_MEMBER, API_FETCH_AGENCY, API_FETCH_DASHBOARD, API_GET_ROLES_BY_ENTITY_NAME, API_PROPERTY, API_PROPERTY_DOCUMENT, API_WORK_ORDER, API_WORK_ORDER_APPROVE, API_WORK_ORDER_REJECT } from '../../../@core/services/util/api.endpoints';
import { PageableResponse } from 'src/@core/interfaces/pageable-response';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseAdminService {

  constructor(
    private http: HttpClient
  ) { }

  // get work order api
  searchAndSortWorkOrder(page: number, size: number, sort: string, order: string, searchList?: any[], cacheKey?: any): Observable<PageableResponse<any>> {
    let params = new HttpParams();
    let url = API_FETCH_DASHBOARD + '?page=' + page;

    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    if (searchList && searchList.length > 0) {
      _.forEach(searchList, (search) => {
        if (search.column && search.content) {
          params = params.append(search.column, search.content);
        }
      });
    }
    return this.http.get<PageableResponse<any>>(url, { params: params });
  }

  // create work order api
  createWorkOrder(payload: any): Observable<any> {
    console.log(payload)
    return this.http.post<any>(API_WORK_ORDER, payload);
  }

  // create work order api
  updateWorkOrder(payload: any): Observable<any> {
    console.log(payload)
    return this.http.put<any>(API_WORK_ORDER + '/' + payload.id, payload);
  }

  // create venue order api
  createVenue(formData: any): Observable<any> {
    console.log(formData)
    return this.http.post<any>(API_PROPERTY, formData);
  }

  // fetch Venue
  fetchVenue(page: number, size: number, sort: string, order: string): Observable<any[]> {
    let url = API_PROPERTY + '?page=' + page;

    if (size != null) {
      url += '&size=' + size;
    }

    if (order !== 'desc') {
      order = 'asc';
    }
    if (sort != null) {
      url += ('&sort=' + sort + ',' + order);
    } else {
      url += ('&sort=createdOn,desc');
    }

    return this.http.get<any[]>(url);
  }

  // upload venue picture
  uploadPicture(file: File, index): Observable<any[]> {
    const formData: FormData = new FormData();
    formData.append('profile', file, file.name);
    return this.http.post<any>(API_PROPERTY_DOCUMENT, formData, {
      reportProgress: true
    });
  }

  // get work order by id
  getWorkOrderById(id: any): Observable<any> {
    const url = API_WORK_ORDER + '/' + id;
    return this.http.get<PageableResponse<any>>(url);
  }

  // get work order by id
  getVenueById(id: any): Observable<any> {
    const url = API_PROPERTY + '/' + id;
    return this.http.get<PageableResponse<any>>(url);
  }

  // approve work order
  approveWorkOrder(id: any, obj): Observable<any> {
    const url = API_WORK_ORDER_APPROVE + '/' + id;
    return this.http.put<any>(url, obj);
  }

  // reject work order
  rejectWorkOrder(id: any, obj): Observable<any> {
    const url = API_WORK_ORDER_REJECT + '/' + id;
    return this.http.put<any>(url, obj);
  }

  // get roles by  entity name
  getRolesByEntityName(entity_name: any): Observable<any> {
    const url = API_GET_ROLES_BY_ENTITY_NAME + '/' + entity_name;
    return this.http.get<PageableResponse<any>>(url);
  }

  // create team member api
  createTeamMember(id: any, payload: any): Observable<any> {
    const url = API_ADD_TEAM_MEMBER + '/' + id;
    return this.http.post<any>(url, payload);
  }

  // Update status of team member
  updateTeamMemberStatus(id: any, status: any): Observable<any> {
    const url = API_APPROVE_TEAM_MEMBER + '/' + id + '/' + status;
    return this.http.get<any>(url);
  }

  // create team member api
  createChamp(id: any, payload: any): Observable<any> {
    const url = API_ADD_CHAMP + '/' + id;
    return this.http.post<any>(url, payload);
  }

  // Update status of champ
  updateChampStatus(id: any, status: any): Observable<any> {
    const url = API_FETCH_AGENCY + '/' + id + '/' + status;
    return this.http.get<any>(url);
  }
}
