import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { API_ACTIVATION_PROOF } from 'src/@core/services/util/api.endpoints';
import { roles } from 'src/app/shared/helpers/roles';

@Component({
  selector: 'hour4u-admin-view-activation-image',
  templateUrl: './view-activation-image.component.html',
  styleUrls: ['./view-activation-image.component.scss']
})
export class ViewActivationImageComponent implements OnInit {
  roles = roles;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewActivationImageComponent>,
    private http: HttpClient
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  onApprove() {
    this.updateActivationStatus('ACCEPTED');
  }

  onReject() {
    this.updateActivationStatus('REJECTED');
  }

  private updateActivationStatus(status: string) {
    const payload = {
      status: status
    };

    this.http.put(`${API_ACTIVATION_PROOF}${this.data.workOrder.id}`, payload).subscribe(
      (apiResponse) => {
        console.log(`Update Status (${status}) Success:`, apiResponse);

        // After handling approval/rejection, close the dialog
        this.dialogRef.close();
        this.data.dashboardComponent.fetchDashboardData();
      },
      (apiError) => {
        console.error(`Error updating status (${status}):`, apiError);
      });
  }
}
