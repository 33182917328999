import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { API_ACTIVATION_PROOF } from 'src/@core/services/util/api.endpoints';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EmployerUploadService } from 'src/@core/services/employer/employer-upload.service';

@Component({
  selector: 'hour4u-admin-add-activation-image',
  templateUrl: './add-activation-image.component.html',
  styleUrls: ['./add-activation-image.component.scss']
})
export class AddActivationImageComponent implements OnInit {

  addActivationImageForm: FormGroup;
  selectedImage: File | null = null;
  thumbnail: HTMLImageElement | null = null;
  s3Key: string | null = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employerUploadService: EmployerUploadService,
    private dialogRef: MatDialogRef<AddActivationImageComponent>
  ) { }

  ngOnInit() {
    this.addActivationImageForm = this.fb.group({
      remarks: ['']
    });
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      this.compressAndUpload(file);
    }
  }

  compressAndUpload(file: File) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxWidth = 1800;
    const maxHeight = 1800;
    const image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        const ratio = Math.min(maxWidth / width, maxHeight / height);
        width *= ratio;
        height *= ratio;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          // Create a File from the Blob
          const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });

          // Upload the compressed file to S3
          this.employerUploadService.uploadProfileImage(compressedFile).subscribe(
            (s3File) => {
              console.log('File uploaded successfully:', s3File);

              // Set the selected image directly as a File
              this.selectedImage = compressedFile;
              this.displayThumbnail(compressedFile);

              // Save the S3 key
              this.s3Key = s3File.key;
            },
            (error) => {
              console.error('Error uploading file:', error);
            }
          );
        },
        'image/jpeg',
        0.6
      );
    };

    image.src = URL.createObjectURL(file);
  }

  displayThumbnail(file: File) {
    // Remove previous thumbnail if any
    if (this.thumbnail) {
      this.thumbnail.remove();
    }

    // Display new thumbnail
    const thumbnailContainer = document.querySelector('.image-thumbnails');
    if (thumbnailContainer) {
      this.thumbnail = document.createElement('img');
      this.thumbnail.src = URL.createObjectURL(file);
      this.thumbnail.alt = 'Thumbnail';
      this.thumbnail.style.width = '150px';
      this.thumbnail.style.height = '150px';
      this.thumbnail.style.marginRight = '8px';
      this.thumbnail.style.objectFit = 'cover';
      this.thumbnail.style.borderRadius = '12px';

      thumbnailContainer.appendChild(this.thumbnail);
    }
  }

  onSubmit() {
    const remarks = this.addActivationImageForm.value.remarks;

    if (!this.selectedImage || !this.s3Key) {
      console.error('No image selected or S3 key not available. Please choose an image.');
      return;
    }

    // Construct payload for API_ACTIVATION_PROOF
    const payload = {
      remarks: remarks,
      imageUrl: this.s3Key // Use the S3 key
    };

    // Call API_ACTIVATION_PROOF with the final payload
    this.http.put(`${API_ACTIVATION_PROOF}${this.data.workOrder.id}`, payload).subscribe(
      (apiResponse) => {
        console.log('API_ACTIVATION_PROOF Success:', apiResponse);
        this.dialogRef.close();

        this.data.dashboardComponent.fetchDashboardData();
      },
      (apiError) => {
        console.error('Error calling API_ACTIVATION_PROOF:', apiError);
        // Handle error
      });
  }
}
