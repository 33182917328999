import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/@core/services/notification.service';
import { API_ADD_AGENCY, API_APPROVE_AGENCY } from 'src/@core/services/util/api.endpoints';
import { AppService } from 'src/@core/services/app.service';

@Component({
  selector: 'hour4u-admin-add-edit-employer',
  templateUrl: './add-edit-employer.component.html',
  styleUrls: ['./add-edit-employer.component.scss']
})
export class AddEditEmployerComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  entityId = this.appService.getEntityId();


  constructor(
    public dialogRef: MatDialogRef<AddEditEmployerComponent>,
    private _fb: FormBuilder,
    private http: HttpClient,
    private notify: NotificationService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.createForm();
  }


  createForm() {
    this.form = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.submitted = false;
      return;
    } else {
      this.addAgency();
    }
  }



  addAgency(): void {
    const agencyData = this.form.value;

    agencyData.mobile = agencyData.mobile;
    agencyData.entityType = 'AGENCY';

    this.http.post(API_ADD_AGENCY + this.entityId, agencyData).toPromise()
      .then((response: any) => {
        this.submitted = false;
        this.notify.showMessage('Agency created successfully!', 2000);
        // Extract the ID from the response
        const entityId = response.id;

        // Make another API call with the extracted ID
        this.http.get(`${API_APPROVE_AGENCY}${entityId}/ACCEPTED`).toPromise()
          .then((secondApiResponse: any) => {
            this.dialogRef.close(true);
            // Handle the response from the second API call
            console.log('Second API Response:', secondApiResponse);
          })
          .catch(error => {
            console.error('Error in second API call:', error);
            // Handle error response from the second API call
          });

      })
      .catch(error => {
        console.error('Error creating agency:', error);
        // Handle error response from the first API call
      });
  }

}
