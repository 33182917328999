import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef } from '@angular/material';
import { AppService } from 'src/@core/services/app.service';
import { API_CHAMP, API_JOB_SEEKER, ASSIGN_CHAMP } from 'src/@core/services/util/api.endpoints';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationService } from 'src/@core/services/notification.service';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hour4u-admin-add-champ',
  templateUrl: './add-champ.component.html',
  styleUrls: ['./add-champ.component.scss']
})
export class AddChampComponent implements OnInit {
  selectedJobDetails: any = null;
  selection = [];
  champsList: any[] = [];
  searchByChampName: string = '';
  searchByChampMobileNumber: string = '';
  searchByChampCity: string = '';
  filterArray: any[] = [
    {
      label: 'Rating (highest to lowest)',
      direction: 'desc',
      field: 'averageRating'
    },
    {
      label: 'Rating (lowest to highest)',
      direction: 'asc',
      field: 'averageRating'
    },
    {
      label: 'Hours (highest to lowest)',
      direction: 'desc',
      field: 'hoursWorked'
    },
    {
      label: 'Hours (lowest to highest)',
      direction: 'asc',
      field: 'hoursWorked'
    }
  ];
  selectedFilter: any = null;
  currentUserRole: any = null;
  pageSizeOptions: number[] = [20, 50, 100, 500];
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 20;
  profile = this.app.getCurrentAccount().user_details;
  params: any = {};
  step: number = 1;
  basePrice: number = 0;
  shiftPay: number = 0;
  sort: string = 'createdOn';
  sortDirection: string = 'desc';
  baseImageUrl = environment.image_url;
  disableBtn: boolean = false;
  selectedAll: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddChampComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appService: AppService,
    private http: HttpClient,
    private app: AppService,
    private notify: NotificationService
  ) {
    this.selectedJobDetails = this.data;
    this.shiftPay = this.selectedJobDetails.totalAmount;
    if (this.selectedJobDetails && !this.selectedJobDetails.jobSeekerPaymentInfo) {
      this.selectedJobDetails.jobSeekerPaymentInfo = this.selectedJobDetails.employment.jobSeekerPaymentInfo;
      this.selectedJobDetails.jobSeekerPaymentInfo_female = this.selectedJobDetails.employment.jobSeekerPaymentInfo_female;
      this.selectedJobDetails.dates = this.selectedJobDetails.employment.dates;
    }
    this.selectedJobDetails.jobSeekerPaymentInfo.fixedTotal = this.getFixedTotal(this.selectedJobDetails.jobSeekerPaymentInfo)
    this.selectedJobDetails.jobSeekerPaymentInfo_female.fixedTotal = this.getFixedTotal(this.selectedJobDetails.jobSeekerPaymentInfo_female)
  }

  ngOnInit() {
    this.getCurrentRole();
  }

  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
        if (this.profile && this.profile.id) {
          // if (this.currentUserRole == 'JOB_SUPERVISOR') {
          //   this.getChamps();
          // }
          // if (this.currentUserRole == 'JOB_ADMIN') {
          this.getChampsList();
          // }
        }
      })
    })
  }

  // get All champs for supervisor admin
  getChamps() {
    this.params.refereeId = this.profile.id;
    this.params.page = this.pageIndex;
    this.params.size = this.pageSize;

    const params = this.params;
    this.http.get(API_CHAMP, { params }).subscribe(
      (response: any) => {
        this.champsList = response.content;
        if (this.selection && this.selection.length) {
          this.selection.forEach(ele => {
            this.champsList.push(ele);
          })
        }
        // this.champsList = this.champsList.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        this.resultsLength = response.totalElements + this.selection.length;
      },
      (error) => {
        console.error('Error fetching champs:', error);
      }
    );
  }

  // Get champs list
  getChampsList() {
    if (this.currentUserRole == 'JOB_SUPERVISOR') {
      this.params.refereeId = this.profile.id;
    }
    this.params.page = this.pageIndex;
    this.params.size = this.pageSize;
    this.params.sort = this.sort + ',' + this.sortDirection;

    const params = this.params;
    this.http.get(API_JOB_SEEKER, { params }).subscribe(
      (response: any) => {
        console.log(response);
        this.champsList = [];
        this.champsList = response.content;
        // if (this.selectedAll) {
        //   let selection = Object.assign([], this.selection);
        //   // this.selection = [];
        //   selection.forEach(ele => {
        //     this.champsList.forEach(el => {
        //       if (el.id == ele.id) {
        //         // el.checked = true;
        //         this.selection.push(el);
        //       }
        //     })
        //   })
        // }
        //  else {
        if (this.selection && this.selection.length) {
          this.selection.forEach(ele => {
            this.champsList.forEach(el => {
              if (el.id == ele.id) {
                el.checked = true;
              }
            })
          })
        }
        // }
        // this.champsList = this.removeDuplicates(this.champsList);
        // this.champsList = this.champsList.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // });
        this.resultsLength = response.totalElements + this.selection.length;
      },
      (error) => {
        console.error('Error fetching champs:', error);
      }
    );
  }

  // remove all duplicate name from array
  removeDuplicates(array) {
    let items = array.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        test.name.trim() && findTest.name.trim() === test.name.trim() && findTest.email.trim() === test.email.trim() && findTest.mobile.trim() === test.mobile.trim()
      )
    );
    return items;
  }

  toggle(item, event: MatCheckboxChange) {
    if (event.checked) {
      this.selection.push(item);
    } else {
      const index = this.selection.indexOf(item);
      if (index >= 0) {
        this.selection.splice(index, 1);
      }
    }
  }

  exists(item) {
    return this.selection.indexOf(item) > -1;
  };

  isIndeterminate() {
    return (this.selection.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selection.length === this.champsList.length;
  };

  toggleAll(event: MatCheckboxChange) {
    this.selectedAll = event.checked;
    // this.selection = [];
    if (!this.selectedAll) {
      this.champsList.forEach(el => {
        el.checked = false;
      })
    }
    if (event.checked) {
      this.champsList.forEach(row => {
        this.selection.push(row)
      });
    } else {
      this.selection.length = 0;
    }
  }

  // Disable send request btn
  disable() {
    if (this.selection && this.selection.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  // apply filter
  applyFilter(filter) {
    this.sort = filter.field;
    this.sortDirection = filter.direction;
    // if (this.currentUserRole == 'JOB_SUPERVISOR') {
    //   this.getChamps();
    // }
    // if (this.currentUserRole == 'JOB_ADMIN') {
    this.getChampsList();
    // }
  }

  // search field
  search() {
    if (this.searchByChampName) {
      this.params.name = this.searchByChampName;
    } else {
      this.params.name = "";
    }

    if (this.searchByChampMobileNumber) {
      this.params.mobile = this.searchByChampMobileNumber;
    } else {
      this.params.mobile = "";
    }

    if (this.searchByChampCity) {
      this.params['address.city'] = this.searchByChampCity;
    } else {
      this.params['address.city'] = "";
    }

    this.pageIndex = 0;
    this.pageSize = 20;
    // if (this.currentUserRole == 'JOB_SUPERVISOR') {
    //   this.getChamps();
    // }
    // if (this.currentUserRole == 'JOB_ADMIN') {
    this.getChampsList();
    // }
  }

  // Go to payment page
  // goToPaymentPage() {
  //   this.step = 2;
  // }

  // Go back
  // goBack() {
  //   this.step = 1;
  // }

  // Send request
  sendRequest() {
    let jobFulfilment = [];
    this.disableBtn = true;
    this.selection.forEach(ele => {
      let obj = {
        jobSeekerId: ele.id,
        jobSeeker: ele,
        jobSeekerPaymentInfo: ele.gender == 'Male' ? this.selectedJobDetails.jobSeekerPaymentInfo : this.selectedJobDetails.jobSeekerPaymentInfo_female
      }
      jobFulfilment.push(obj);
    })
    const param = {
      "employmentId": this.selectedJobDetails.id,
      "fulfillerType": this.currentUserRole,
      "fulfilerId": this.profile.id,
      "jobFulfilment": jobFulfilment
    }
    if (jobFulfilment && jobFulfilment.length) {
      this.http.post(ASSIGN_CHAMP, param).subscribe(
        (response: any) => {
          if (response) {
            this.notify.showMessage('Request sent successfully!', 3000)
            this.disableBtn = false;
            this.dialogRef.close(true);
          }
        }, (err => {
          this.disableBtn = false;
        }))
    } else {
      this.disableBtn = false;
      this.notify.showMessage('Please select champ!', 3000)
    }
  }

  // Change pagination event
  getPaginatorData(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    // if (this.currentUserRole == 'JOB_SUPERVISOR') {
    //   this.getChamps();
    // }
    // if (this.currentUserRole == 'JOB_ADMIN') {
    this.getChampsList();
    // }
  }

  // get min total
  getMinTotal(info) {
    let minTotal = 0;
    try {
      this.data.dates.forEach(date => {
        const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
        minTotal += Math.round(info.basePrice + (info.minRate * hours));
      });
    } catch {
    }
    return minTotal;
  }

  // get max total
  getMaxTotal(info) {
    let maxTotal = 0;
    try {
      this.data.dates.forEach(date => {
        const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
        maxTotal += Math.round(info.basePrice + (info.maxRate * hours));
      });
    } catch {
    }
    return maxTotal;
  }

  // get Total Shift Payment
  getFixedTotal(info) {
    let fixedTotal = 0;
    try {
      if (info.fixedRate) {
        this.selectedJobDetails.dates.forEach(date => {
          const hours = this.hoursOfJob(date.date, date.timeFrom, date.timeTo);
          fixedTotal += Math.round(info.basePrice + (info.fixedRate * hours));
        });
      }
    } catch {
    }
    return fixedTotal;
  }

  // get total hours of shift based on time
  hoursOfJob(dateObject: any, timeFrom: any, timeTo: any): number {
    let scheduledStartDate: DateTime = DateTime.local(dateObject[0], dateObject[1], dateObject[2], timeFrom[0], timeFrom[1]);
    let scheduledEndDate: DateTime = DateTime.local(dateObject[0], dateObject[1], dateObject[2], timeTo[0], timeTo[1]);
    scheduledStartDate.setLocale('in-IN');
    scheduledEndDate.setLocale('in-IN');

    let dateEndObject: any[] = [];
    dateEndObject = Object.assign(dateEndObject, dateObject);
    if (scheduledStartDate.toString() > scheduledEndDate.toString()) {
      dateEndObject[2] = this.addDaysInDate(dateObject, 1)
    }

    let jobStartDate = new Date(dateObject[0], dateObject[1] - 1, dateObject[2]);
    jobStartDate.setHours(timeFrom[0]);
    jobStartDate.setMinutes(timeFrom[1]);


    let jobEndDate = new Date(dateEndObject[0], dateEndObject[1] - 1, dateEndObject[2]);
    jobEndDate.setHours(timeTo[0]);
    jobEndDate.setMinutes(timeTo[1]);
    const jobEndTime = (jobEndDate.getTime() - jobStartDate.getTime()) / 1000;
    return Math.abs(jobEndTime / 3600);
  }

  // Add days into given date
  addDaysInDate(dateObject: any, days: number) {
    var futureDate = new Date(dateObject[0], dateObject[1] - 1, dateObject[2]);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate.getDate();
  }
}
