import { Address } from '../address.model';
import { Employer } from '../employer/employer.model';
import { Job } from '../job/job.model';
import { EmploymentHistory } from './employment-history.model';
import { EmploymentAdministrationService } from 'src/@core/services/employment/employment-administration.service';
import { EmploymentAdministration } from './employment-administration.model';

export class Employment {
    briefing: string[];
    recurringDays: string[];
    createdBy: string;
    createdOn: string;
    dates: Dates[];
    deletedBy: boolean;
    employerId: string;
    employerPaymentInfos: EmployerPaymentInfo[];
    employmentType: string;
    employmentTitle: string;
    id: string;
    instructions: string;
    isDelete: boolean;
    jobId: string;
    jobSeekerPaymentInfos: JobSeekerPaymentInfos[];
    location: Address;
    noOfFemaleRequired: number;
    noOfMaleRequired: number;
    termsForLatePayment: string;
    updatedBy: string;
    updatedOn: string;
    status: string;
    inProgress: boolean;
    averageRating: number;
    attendanceLogInSelfieRequired: boolean;
    attendanceLogOutSelfieRequired: boolean;
    attendanceLocationTrackerRequired: boolean;
    attendanceAtVenueRequired: boolean;
    flatRate: boolean;
    jobTypeId?: string;

    // other
    datesReq: Dates[];
    jobSeekerPaymentInfo: JobSeekerPaymentInfos;
    jobSeekerPaymentInfo_female?: JobSeekerPaymentInfos;
    employer: Employer;
    job: Job;
    history: EmploymentHistory[];
    sliderImages: Array<any>;
    administration: EmploymentAdministration;
    checkedInCount?: any;
    maleJobApplicants?: any;
    femaleJobApplicants?: any;
    jobFulfilerCommission?: any;
    proofOfWorkPicturesRequired?: boolean;
    proofOfWorkFields?: any;
    proofOfWork?: any;
    servicesFormArray?: any;
}

export class Dates {
    isActive: boolean;
    isComplete: boolean;
    date: any;
    time: any;
    timeFrom: any;
    timeTo: any;
}

export class Times {
    hour: any;
    minute: any;
    nano: any;
    second: any;

}
export class EmployerPaymentInfo {
    level: string;
    gender: string;
    maxRate: number;
    flatRate: number;
}

export class JobSeekerPaymentInfos {
    level: string;
    gender: string;
    maxRate: number;
    minRate: number;
    basePrice?: number;
    minTotal?: any;
    maxTotal?: any;
    fixedRate?: number;
    fixedTotal?: any;
    biddingAllowed?: any;
}

