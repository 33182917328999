export const PREFIX = '/api';

// auth service
export const API_AUTH = PREFIX + '/auth-service';
export const API_ACCOUNT = API_AUTH + '/v1/account';
export const API_EMPLOYER = API_AUTH + '/v1/employer';
export const API_EMPLOYER_UPLOAD = API_AUTH + '/v1/fileupload/employer';
export const API_EMPLOYER_CONTRACT = API_AUTH + '/v1/fileupload/EMPLOYER_CONTRACT';
export const API_EMPLOYER_SIGNATURE = API_AUTH + '/v1/fileupload/employer/signature';
export const API_EMPLOYER_SIGN_CONTRACT = API_AUTH + '/v1/employer/sign-contract';
export const API_ENTERPRISE_ADMIN = '/enterprise-job-service/v1';
export const API_CREATE_ENTITY = API_AUTH + '/v1/enterprise/entity';
export const API_ADD_AGENCY = API_AUTH + '/v1/enterprise/add-entity/';
export const API_ADD_CHAMP = API_AUTH + '/v1/enterprise/add-entity';
export const API_ADD_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user-mapping';
export const API_APPROVE_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user_mapping';
export const API_APPROVE_AGENCY = API_AUTH + '/v1/enterprise/entity_mapping/'
export const API_FETCH_AGENCY = API_AUTH + '/v1/enterprise/entity_mapping';
export const API_FETCH_TEAM_MEMBER = API_AUTH + '/v1/enterprise/user_mapping/';
export const SEND_OTP = API_AUTH + '/v1/enterprise/otp';

// Job SERVICE
const JOB_SERVICE = '/enterprise-job-service';
export const API_JOB = PREFIX + JOB_SERVICE + '/v1/job';
export const API_JOB_APPLICATION = PREFIX + JOB_SERVICE + '/v1/jobapplication';
export const API_JOB_APPLICATION_V2 = PREFIX + JOB_SERVICE + '/v2/jobapplication';
export const API_EMPLOYEE_ATTENDANCE = PREFIX + JOB_SERVICE + '/v1/employmentAttendance';
export const API_JOB_CATEGORY = PREFIX + JOB_SERVICE + '/v1/jobCategory';
export const API_JOB_TYPE = PREFIX + JOB_SERVICE + '/v1/jobType';
export const API_MY_JOB = PREFIX + JOB_SERVICE + '/v1/mobile/myjobs';
export const API_STATS = PREFIX + JOB_SERVICE + '/v1/stats';
export const API_WORKLOG = PREFIX + JOB_SERVICE + '/v1/worklog';
export const API_CANCEL_SHIFT = PREFIX + JOB_SERVICE + '/v1/employment/cancel';
export const API_CHECKOUT_SHIFT = PREFIX + JOB_SERVICE + '/v1/employment/checkout-link';
export const API_FETCH_DASHBOARD = PREFIX + JOB_SERVICE + '/v1/enterprise/workOrder/getAllWorkOrder';
export const API_ACTIVATION_PROOF = PREFIX + JOB_SERVICE + '/v1/workOrder/activation-proof/'

// Employment
export const API_EMPLOYMENT = PREFIX + JOB_SERVICE + '/v1/employment';
export const API_EMPLOYMENT_LIST = PREFIX + JOB_SERVICE + '/v1/employment/getAllEmployment';
export const API_EMPLOYMENT_HISTORY = PREFIX + JOB_SERVICE + '/v1/employmentHistory';
export const API_EMPLOYMENT_ADMINISTRATION = PREFIX + JOB_SERVICE + '/v1/employment/admin';
export const API_EMPLOYMENT_ASSIGN_JOB_ADMIN = PREFIX + JOB_SERVICE + '/v1/job/assignment/fulfiler';
export const API_ASSIGN_AGENCY_IN_PROJECT = PREFIX + JOB_SERVICE + '/v1/job/link/jobentity';
export const API_EMPLOYMENT_GET_JOB_ADMIN = PREFIX + JOB_SERVICE + '/v1/job/assignment/fulfilment';
export const API_GET_ASSIGNED_ADMINS = PREFIX + JOB_SERVICE + '/v1/job/assignment/details';

// GAI API
const GAI_SERVICE = '/gaim-service';
export const API_GAI = PREFIX + GAI_SERVICE + '/v1/customai/chat';

// Job Seeker SERVICE
const JOB_SEEKER_SERVICE = '/job-seeker-service';
const ORDER_SERVICE = '/payment-service';
export const API_JOB_SEEKER = PREFIX + JOB_SEEKER_SERVICE + '/v1/jobseeker';
export const API_SEEKER_PREFERENCE = PREFIX + JOB_SEEKER_SERVICE + '/v1/jobpreference';
export const CREATE_EMPLOYEMENT_ORDER = PREFIX + ORDER_SERVICE + '/api/v1/order';
// Payment
export const API_PAYMENT = PREFIX + JOB_SEEKER_SERVICE + '/v1/payment';

// Work Log
export const API_FETCH_WORKLOG = PREFIX + JOB_SERVICE + '/v1/worklog'

// Profile Status Detail
export const API_PROFILE_STATUS_DETAIL = PREFIX + JOB_SEEKER_SERVICE + '/v1/profileStatusDetail';

// Rating
const RATING_SERVICE = '/rating-service';
export const API_EMPLOYER_RATING = PREFIX + RATING_SERVICE + '/v1/employer';
export const API_JOB_SEEKER_RATING = PREFIX + RATING_SERVICE + '/v1/jobseeker';

/** Notification SERVICE */
const NOTIFICATION_SERVICE = '/notification-service';
export const API_NOTIFICATION = PREFIX + NOTIFICATION_SERVICE + '/v1/notification';

//Invoice
export const API_INVOICE = PREFIX + JOB_SERVICE + '/v1/invoice';
export const API_INVOICE_SUMMARY = PREFIX + JOB_SERVICE + '/v1/invoice/summary';
export const API_INVOICE_BY_ID = PREFIX + JOB_SERVICE + '/v1/invoice/unsecured';
export const API_BULK_INVOICES_PAY = PREFIX + ORDER_SERVICE + '/api/v1/order/bulk/invoice';
export const API_INVOICE_SETTLE = PREFIX + JOB_SERVICE + '/v1/invoice/settle';

//Supervisor Admin
export const REFERRAL_SERVICE = '/referral-service';
export const API_CHAMP = PREFIX + REFERRAL_SERVICE + '/v1/champ';
export const API_REFERRAL = PREFIX + REFERRAL_SERVICE + '/v1/referral'
export const API_COMMISSION = PREFIX + REFERRAL_SERVICE + '/v1/commission';
export const API_GET_JOBSUPERVISORS = API_AUTH + '/v2/account';
export const API_GET_JOBSUPERVISOR_BY_ID = PREFIX + JOB_SEEKER_SERVICE + '/v1/jobseeker/fetch-job-seeker-summary-by-ref-ids';
export const API_GET_JOBSUPERVISOR_EARNING_COMMISSION = PREFIX + REFERRAL_SERVICE + '/v1/commission/summary';


// JOB ADMIN 
export const ASSIGN_CHAMP = PREFIX + JOB_SERVICE + '/v1/job/assignment/fulfilment';


//Property
export const API_PROPERTY = PREFIX + JOB_SERVICE + '/v1/enterprise/property';
export const API_BOOKING = API_PROPERTY + '/booking';
export const API_PROPERTY_DOCUMENT = API_AUTH + '/v1/fileupload/PROPERTY_DOCUMENTS';
export const API_PROPERTY_EXPORT = API_PROPERTY + '/download';
export const API_BOOK = API_PROPERTY + '/booking';


// WORK ORDER
export const API_WORK_ORDER = PREFIX + API_ENTERPRISE_ADMIN + '/enterprise/workOrder';
export const API_WORK_ORDER_APPROVE = PREFIX + API_ENTERPRISE_ADMIN + '/workOrder/approve';
export const API_WORK_ORDER_REJECT = PREFIX + API_ENTERPRISE_ADMIN + '/workOrder/reject';


export const API_GET_ROLES_BY_ENTITY_NAME = API_AUTH + '/v1/enterprise/role';

