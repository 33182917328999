import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSlider } from '@angular/material';
import { JobService } from 'src/@core/services/job/job.service';
import { Search } from 'src/@core/interfaces/search';
import { Job } from 'src/@core/interfaces/job/job.model';
import { NotificationService } from 'src/@core/services/notification.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { EmployerPaymentInfo, Employment, JobSeekerPaymentInfos } from 'src/@core/interfaces/employment/employment.model';
import { AppService, CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { AddEditJobComponent } from 'src/app/modules/job/job/add-edit-job/add-edit-job.component';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { roles } from 'src/app/shared/helpers/roles';
import { environment } from 'src/environments/environment';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { Router } from '@angular/router';
import { GaiService } from "src/@core/services/gai/gai.service";
import { API_JOB } from 'src/@core/services/util/api.endpoints';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { expandCollapseAnimation } from 'src/@core/animations/expand-collapse.animation';
import { slideTransitionAnimation } from 'src/@core/animations/slide-transition.animation';
import { AnimationEvent } from '@angular/animations';


declare var Razorpay: any;
@Component({
  selector: 'hour4u-admin-create-shift',
  templateUrl: './create-shift.component.html',
  styleUrls: ['./create-shift.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms,
    expandCollapseAnimation,
    slideTransitionAnimation
  ]
})
export class CreateShiftComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChild('termsText', { static: false }) termsText: ElementRef;
  @ViewChild('shiftNameInput', { static: false }) shiftNameInputRef: ElementRef;
  @ViewChild('jobRadioRef', { static: false }) jobRadioInputRef: ElementRef;
  @ViewChild('timeFrom', { static: false }) timeFromRef: ElementRef;




  femaleTotal: number = 0;
  femaleSingleTotal: number = 0;
  maleTotal: number = 0;
  maleSingleTotal: number = 0;
  grandTotal: number = 0;

  loading = false;
  diameter = 30;
  color: string;
  mode = 'indeterminate';

  show: boolean = false;
  hide: boolean = true;
  form: FormGroup;
  button_name: any = 'Cancel'
  chkselect: boolean;
  stepper = 1;
  jobs: Job[];
  jobType: JobType;
  dateArr = [];
  maleLevelLabel = 'Beginner';
  femaleLevelLabel = 'Beginner';
  profile = this.appService.getCurrentAccount();
  isEmployerLogin = this.appService.getIsEmployerLogin();
  hours: number = 1;
  minTimeTo = "06:00";
  minDate = new Date();
  attendanceLogInSelfieRequired: boolean;
  attendanceLogOutSelfieRequired: boolean;
  attendanceLocationTrackerRequired: boolean;
  attendanceAtVenueRequired: boolean;
  keepMaleSection: boolean;
  keepFemaleSection: boolean;
  isFlatRate: boolean = false;
  wasFlatRate: boolean = false;
  currMaleHourlyRate: number = 0;
  currFemaleHourlyRate: number = 0;
  flatRateBanner: string;
  flatRateHourlyBanner: string;
  isSuper: boolean = false;
  errorInstepOne: boolean = false;
  gaiJobDescription: string;
  templateForm: FormGroup;
  templateSelected: boolean = false;


  recurringDays = [
    { day: 'Every day', val: 'ALL', value: 'ALL', checked: false },
    { day: 'Every Sunday', val: 0, value: 'SUNDAY', checked: false, disabled: false },
    { day: 'Every Monday', val: 1, value: 'MONDAY', checked: false, disabled: false },
    { day: 'Every Tuesday', val: 2, value: 'TUESDAY', checked: false, disabled: false },
    { day: 'Every Wednesday', val: 3, value: 'WEDNESDAY', checked: false, disabled: false },
    { day: 'Every Thursday', val: 4, value: 'THURSDAY', checked: false, disabled: false },
    { day: 'Every Friday', val: 5, value: 'FRIDAY', checked: false, disabled: false },
    { day: 'Every Saturday', val: 6, value: 'SATURDAY', checked: false, disabled: false },
  ];

  briefingData = [
    { value: 'Telephonic', checked: false },
    { value: 'Videos', checked: false },
    { value: 'One to One', checked: false },
  ]

  terms = [
    { value: "7 Days", text: '7 Days selected' },
    { value: "15 Days", text: '15 Days selected' },
    { value: "30 Days", text: '30 Days selected' },
    { value: "45 Days", text: '45 Days selected' }
  ]

  jobTypes: any[] = [];
  allJobTypes: any[] = [];
  templateLists: any[] = [];
  empList: any[] = [];
  employerList: any[] = [];
  roles = roles;
  sliderValue: number;

  copied: boolean = false;
  copyTitle: string = 'Share Checkout';
  loginEmployerDetails: any = null;
  sentRequestForPayment: boolean = false;
  showExpand: boolean = false;
  selectedTemplateDetails: any = null;
  selectedValue: string | null = null;
  nextBtnTxt: string = 'Next: Add description';
  isLoadingDescription: boolean = false;
  public data: any = {
    address: {}
  };
  addressObj: any = {};
  proofOfWorkFields: FormArray;

  typesOfFieldsList: any[] = [
    {
      type: 'text',
      display: 'Text field'
    },
    {
      type: 'tel',
      display: 'Phone no.'
    }
  ]

  times = this.generateTimeSlots();
  timeDifferences: { [key: string]: string } = {};
  timeFrom: any;
  numberOfHours: string = '';
  timeDifference: string;
  newTimeDifference: string;


  constructor(
    public dialogRef: MatDialogRef<CreateShiftComponent>,
    private jobService: JobService,
    private notify: NotificationService,
    private gaiService: GaiService,
    private _fb: FormBuilder,
    private jobTypeService: JobTypeService,
    private employmentService: EmploymentService,
    private employerService: EmployerService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private el: ElementRef

  ) { }


  ngOnDestroy(): void {
    this.cdRef.detach();
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }


  ngOnInit() {
    this.minDate = new Date();
    // generating form with required fields
    this.createForm();
    if (!this.data || !this.data.location) {
      this.data.address = {};
    } else {
      this.data.address = this.data.location
    }
    this.loginEmployerDetails = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
    if (!this.loginEmployerDetails) {
      this.loginEmployerDetails =
      {
        advanceAmountPercentage: 20
      }
    }
    this.appService.currentUser.subscribe(curruser => {
      if (curruser.roles)
        curruser.roles.forEach(role => {
          if (role == 'SUPER_ADMIN') {
            this.isSuper = true;
          }
        });
    });


    // window.addEventListener("popstate", (event) => {
    //   console.log("Popstate triggered", event.state);

    //   if (event.state && typeof event.state.step !== 'undefined') {
    //     this.onBackPress();
    //     this.cdRef.detectChanges();
    //   }
    // });

  }

  generateTimeSlots(selectedStartTime?: string, selectedEndTime?: string): {
    timeDifference: string; display: string, value: string
  }[] {
    const times: {
      timeDifference: string; display: string, value: string
    }[] = [];

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour12 = ('0' + (h % 12 || 12)).slice(-2); // Convert 0 to 12 for 12-hour format
        const hour24 = ('0' + h).slice(-2); // 24-hour format
        const minute = ('0' + m).slice(-2);
        const period = h < 12 ? 'AM' : 'PM';

        const displayFormat = `${hour12}:${minute} ${period}`;
        const valueFormat = `${hour24}:${minute}`;

        times.push({
          display: displayFormat, value: valueFormat,
          timeDifference: ''
        });
      }
    }
    times.forEach(time => {
      // Calculate time difference for each time option
      // ... calculate time difference based on your logic
      time.timeDifference = '';// assign the calculated time difference
    });
    return times;
  }

  calculateTimeDifference(start: string, end: string): string {
    if (!start || !end) {
      return '';
    }

    const startTime = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    let difference = (endTime.getTime() - startTime.getTime()) / 1000;

    // Adjust for negative durations
    if (difference < 0) {
      difference += 24 * 60 * 60; // Adding a day in seconds
    }

    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);

    return `(${hours}.${(minutes < 10 ? '0' : '') + minutes} hours)`;
  }

  calculateTimeDifferences() {
    this.times.forEach(time => {
      this.timeDifferences[time.value] = this.calculateTimeDifference(this.timeFrom.value, time.value);
    });
  }

  onBackPress() {
    if (this.stepper == 2) {
      this.nextBtnTxt = 'Next: Add description';
    }
    this.stepper = this.stepper - 1;
    this.scrollToTop();
  }

  async ngAfterViewInit() {
    this.loading = true;


    if (this.isSuper)
      await this.loadEmployers();
    await this.loadJobType();
    await this.loadTemplates();
    this.form.controls['jobTypeId'].markAsTouched();
    this.loading = false;


    this.route.queryParams.subscribe(async params => {
      const mode = params['mode'];
      const employmentId = params['employmentId'];

      if (mode === 'edit' && employmentId) {
        this.data = await this.employmentService.getById(employmentId).toPromise();
        this.data.address = this.data.location;
        this.form.controls['emplrId'].markAllAsTouched();
        this.times.forEach((time) => {
          const [hours, minutes] = this.data.dates[0].timeFrom;
          const startTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          const endTime = time.value;
          time.timeDifference = this.calculateTimeDifference(startTime, endTime);
        });

      } else if (mode === 'clone' && employmentId) {
        this.data = await this.employmentService.getById(employmentId).toPromise();
        this.data.id = null;
        this.data.status = null;
        this.data.cloned = true;
        this.data.address = this.data.location;
        this.form.controls['emplrId'].markAllAsTouched();
        this.times.forEach((time) => {
          const [hours, minutes] = this.data.dates[0].timeFrom;
          const startTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          const endTime = time.value;
          time.timeDifference = this.calculateTimeDifference(startTime, endTime);
        });
      }
      if (this.data && (this.data.id || this.data.cloned)) {
        // edit case
        // patch data into form...
        // converting time and date to fit into form
        if (this.data.dates) {
          this.data.dates.forEach((dat, index) => {
            // getting from time
            if (dat.timeFrom.length > 0) {
              let correctHour = dat.timeFrom[0].toString().length == 1 ? "0" + dat.timeFrom[0] : dat.timeFrom[0];
              let correctMin = dat.timeFrom[1].toString().length == 1 ? "0" + dat.timeFrom[1] : dat.timeFrom[1];
              dat.timeFrom = correctHour + ":" + correctMin;
              //dat.timeFrom = dat.timeFrom[0] + ":" + (dat.timeFrom[1] == '0' ? '00' : dat.timeFrom[1]);
            }
            // getting to time
            if (dat.timeTo.length > 0) {
              let correctHour = dat.timeTo[0].toString().length == 1 ? "0" + dat.timeTo[0] : dat.timeTo[0];
              let correctMin = dat.timeTo[1].toString().length == 1 ? "0" + dat.timeTo[1] : dat.timeTo[1];
              dat.timeTo = correctHour + ":" + correctMin;
              //dat.timeTo = dat.timeTo[0] + ":" + (dat.timeTo[1]== '0' ? '00' : dat.timeTo[1]);
            }
            // getting from date
            if (dat.date.length > 2)
              dat.dateFrom = new Date(dat.date[0] + "-" + dat.date[1] + "-" + dat.date[2])
            // getting to date
            if (this.data.employmentType == 'RECURRING_DAYS' && index == this.data.dates.length - 1 && dat.date.length > 2) {
              this.data.dates = [
                {
                  dateFrom: this.data.dates[0].dateFrom,
                  timeFrom: this.data.dates[0].timeFrom,
                  timeTo: this.data.dates[0].timeTo,
                  dateTo: new Date(dat.date[0] + "-" + dat.date[1] + "-" + dat.date[2]),
                }
              ]
            }

          })

          // generate multi day form
          for (let i = 0; i < this.data.dates.length - 1; i++) {
            this.datesArrayForm.push(this.datesForm);
          }
        }


        await this.form.patchValue(this.data);
        if (this.profile.user_details.employerId) {
          this.form.controls['emplrId'].setValue(this.profile.user_details.employerId);
          this.form.controls['emplrId'].updateValueAndValidity();
          this.form.controls['employerId'].setValue(this.profile.user_details.employerId);
          this.form.controls['employerId'].updateValueAndValidity();
        } else {
          this.form.controls['emplrId'].setValue(this.data.employerId);
          this.form.controls['emplrId'].updateValueAndValidity();
          this.form.controls['employerId'].setValue(this.data.employerId);
          this.form.controls['employerId'].updateValueAndValidity();
        }

        await this.selectedJobType(this.data.jobTypeId);
        await this.getJobType(this.data);

        // set Recurring data fields
        if (this.data.recurringDays) {
          this.data.recurringDays.forEach(x => {
            this.recurringDays.forEach(r => {
              if (x == r.value)
                r.checked = true;
            });
          });
        }

        // set briefing data
        if (this.data.briefing) {
          this.data.briefing.forEach(brief => {
            this.briefingData.forEach(b => {
              if (brief == b.value) {
                b.checked = true;
              }
            })
          });
        }

        if (this.data.employerPaymentInfos) {
          this.data.employerPaymentInfos.forEach(epi => {
            if (epi.gender == 'Male')
              this.form.controls.maleFlatRate.setValue(epi.flatRate);
            else if (epi.gender == 'Female')
              this.form.controls.femaleFlatRate.setValue(epi.flatRate);
          })
        }

        if (this.data.flatRate) {
          this.wasFlatRate = true;
        }

      }
      if (this.data.jobId != null) {
        // await this.setJobData();
      }
    });



  }


  employmentTypeChange(event: any) {
    for (let i = this.datesArrayForm.length; i > 0; i--) {
      this.datesArrayForm.removeAt(i);
    }
    this.cdRef.detectChanges();
  }

  /**
   * fetching data for job  and setting in to fields on edit
   */
  // async setJobData() {
  //   if (this.data.jobId) {
  //     await this.jobService.getById(this.data.jobId).toPromise()
  //       .then(async job => 
  //         // patching job data into form
  //         this.form.controls.jobId.patchValue(job);
  //         this.form.controls.employerId.setValue(job.employerId);
  //         // loading job type data as well
  //         await this.getJobType(job);
  //       });
  //   }
  // }

  /**
   * on Next click
   * it will increase the stepper by one every time.
   */
  onNext() {
    this.errorInstepOne = false;

    if (this.stepper === 2 && !this.templateSelected && this.areSpecialFieldsFilled()) {
      this.createNewTemplate();
      return;
    }

    if (this.stepper === 1) {
      // this.form.markAllAsTouched();
      this.form.controls['jobTypeId'].markAsTouched();
      this.form.controls['jobId'].markAsTouched();
      this.form.controls['emplrId'].markAsTouched();
      this.form.controls['briefing'].markAsTouched();
      this.form.controls['location'].markAsTouched();
      this.form.controls['employerId'].markAsTouched();
      this.form.controls['instructions'].markAsTouched();
      this.form.controls['employmentType'].markAsTouched();
      this.form.controls['employmentTitle'].markAsTouched();
      this.form.controls['noOfMaleRequired'].markAsTouched();
      this.form.controls['noOfFemaleRequired'].markAsTouched();
      this.datesForm.markAllAsTouched();

      if (!this.data.address.placeId || !this.form.controls.noOfMaleRequired.valid || !this.form.controls.employmentTitle.valid) {
        this.errorInstepOne = true;
        return;
      }

      let timeValid = true;
      this.form.controls.dates.value.forEach(x => {
        if (x.timeFrom == '' || x.timeTo == '')
          timeValid = false;
      });

      if (!timeValid) {
        return;
      }

      if ((this.form.controls.noOfFemaleRequired.value && (this.form.controls.noOfFemaleRequired.value == 0 || this.form.controls.noOfFemaleRequired.value == null))
        && (this.employmentFormData.controls.noOfFemaleRequired.value && (this.form.controls.noOfMaleRequired.value == 0 || this.form.controls.noOfMaleRequired.value == null))) {
        this.notify.showMessage('Min 1 male or female required', 2000);
        return;
      }

      this.captureStepper1Data();
      this.nextBtnTxt = 'Next: Add Budget';
    }

    this.stepper++;
    this.scrollToTop();

    if (this.stepper > 1) {
      this.button_name = 'Back';
    } else {
      this.button_name = "Cancel";
    }

    if (this.stepper == 2) {
      this.nextBtnTxt = 'Next: Define deliverables';
      console.log(this.data, "*(((((((((((((((((", this.form)
      if (!this.form.value.id && !this.data.cloned) {
        this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
        this.proofOfWorkFields.push(this.workPicturesForm);
        this.proofOfWorkFields.push(this.workPicturesForm);
        this.proofOfWorkFields.push(this.workPicturesForm);
        let staticArray = [
          {
            "requiredInfoFieldLabel": "Customer name",
            "requiredInfoFieldType": "text"
          },
          {
            "requiredInfoFieldLabel": "Customer mobile no.",
            "requiredInfoFieldType": "tel"
          }, {
            "requiredInfoFieldLabel": "Order ID",
            "requiredInfoFieldType": "text"
          }, {
            "requiredInfoFieldLabel": "Car Number",
            "requiredInfoFieldType": "text"
          }
        ]
        this.proofOfWorkFields.controls.forEach((ele, index) => {
          staticArray.forEach((el, i) => {
            if (i == index) {
              this.proofOfWorkFields.controls[index].patchValue(el);
            }
          })
          console.log(ele)
        })
      } else {
        if (this.data.proofOfWork) {
          this.form.controls['proofOfWorkPicturesRequired'].setValue(this.data.proofOfWork.proofOfWorkPicturesRequired);
          this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
          this.proofOfWorkFields.clear();
          if (this.data.proofOfWork.proofOfWorkFields && this.data.proofOfWork.proofOfWorkFields.length) {
            this.data.proofOfWork.proofOfWorkFields.forEach((element, index) => {
              this.proofOfWorkFields.push(this.workPicturesForm);
            });
            this.proofOfWorkFields.controls.forEach((ele, index) => {
              this.data.proofOfWork.proofOfWorkFields.forEach((el, i) => {
                if (i == index) {
                  this.proofOfWorkFields.controls[index].patchValue(el);
                }
              })
            })
          }
        }
      }
    }
    if (this.stepper == 3) {
      this.nextBtnTxt = 'Next : Set Budget';
    }

    if (this.stepper == 4) {
      this.nextBtnTxt = 'Review shift';

      this.data.employerPaymentInfos.forEach(p => {
        if (this.jobType) {
          if (p.gender == 'Female') {
            if (this.currFemaleHourlyRate == null || this.currFemaleHourlyRate == 0) {
              this.femaleSliderChange(p.maxRate, this.jobType.employerPrices[1].basePrice);
            }
          } else {
            if (this.currMaleHourlyRate == null || this.currMaleHourlyRate == 0) {
              this.maleSliderChange(p.maxRate, this.jobType.employerPrices[0].basePrice);
            }
          }
        }
      });

      if (this.data.flatRate || this.isFlatRate) {
        this.handleRateType('flatRate');
      } else {
        this.handleRateType('hourlyRate');
      }
    }

  }
  areSpecialFieldsFilled(): boolean {

    const templateDescription = this.form.get('templateDescription').value;
    const templateTitle = this.form.get('templateTitle').value;
    const dressCodeFemale = this.form.get('dressCodeFemale').value;
    const dressCodeMale = this.form.get('dressCodeMale').value;

    return templateDescription && templateTitle && dressCodeFemale && dressCodeMale;
  }



  /**
   * Stepper 1 
   *  Set address object into form
   *  Set dates array properly
   */
  captureStepper1Data() {

    this.dateArr = [];

    // setting address obj into form
    this.form.controls.location.setValue(this.data.address);

    // convert date into array if Recurring Days is selected
    if (this.form.controls.employmentType.value == 'RECURRING_DAYS') {
      let datesArray = [];
      this.form.controls.dates.value.forEach(x => {

        // in case of recurring days only one record will be there in this list...
        // get selected date list
        if (this.recurringDays[0].checked == true) {

          // if all is selected
          let arr = this.getDatesInBetween(new Date(x.dateFrom), new Date(x.dateTo), 'ALL');
          datesArray = arr;

        } else {

          // if all is not selected then iterate amoung others
          this.recurringDays.forEach(rec => {
            if (rec.checked == true && rec.val != 'ALL') {
              let arr = this.getDatesInBetween(new Date(x.dateFrom), new Date(x.dateTo), rec.val);
              datesArray = datesArray.concat(arr);
            }
          });

          datesArray = this.sortDates(datesArray);
        }
      });
      // add selected dates array into the form dates
      datesArray.forEach(d => {
        this.dateArr.push({
          date: d,
          timeFrom: this.form.controls.dates.value[0].timeFrom,
          timeTo: this.form.controls.dates.value[0].timeTo,
          tempHours: this.getTimeBetween(this.form.controls.dates.value[0].timeFrom, this.form.controls.dates.value[0].timeTo)
        });

      });
    } else {
      // setting dateFrom to date to send in request
      this.form.controls.dates.value.map(x => x.date = x.dateFrom);

      this.form.controls.dates.value.forEach(x => {
        this.dateArr.push(
          {
            date: new Date(x.date.getTime() - (x.date.getTimezoneOffset() * 60000)).toISOString(),
            timeFrom: x.timeFrom,
            timeTo: x.timeTo,
            tempHours: this.getTimeBetween(x.timeFrom, x.timeTo)
          }
        )
      });
    }
    this.cdRef.detectChanges();
    return;

  }

  getTimeBetween(inTime: any, outTime: any): any {
    if (isNaN(inTime) || isNaN(outTime) || (inTime > outTime)) {
      //create date format          
      //var time_start = new Date();
      //var time_end = new Date();

      var value_start = inTime.split(':');
      var hours_start_temp = parseInt(value_start[0], 10);
      var minutes_start_temp = value_start[1] ? parseInt(value_start[1], 10) : 0;
      var start_hrs: number = hours_start_temp + minutes_start_temp / 60;

      var value_end = outTime.split(':');
      var hours_end_temp = parseInt(value_end[0], 10);
      var minutes_end_temp = value_start[1] ? parseInt(value_end[1], 10) : 0;
      var end_hrs: number = hours_end_temp + minutes_end_temp / 60;

      var timeDiff: any;

      if ((end_hrs - start_hrs) < 0)
        timeDiff = ((end_hrs - start_hrs) + 24).toFixed(6);
      else
        timeDiff = (end_hrs - start_hrs).toFixed(6);

      return timeDiff;
      // time_start.setHours(value_start[0], value_start[1], 0, 0)
      // time_end.setHours(value_end[0], value_end[1], 0, 0)
      // return Math.round((time_end.getTime() - time_start.getTime())/ (1000*60*60)) // millisecond 
    }
  }

  /**
   * Find dates of the day given in range of from and to.
   * @param fromDate 
   * @param toDate 
   * @param day 
   */
  getDatesInBetween(fromDate, toDate, day?) {
    var list = [];
    while (fromDate <= toDate) {
      if (fromDate.getDay() === day) {
        list.push(new Date(fromDate));
      } else if (day == 'ALL') {
        list.push(new Date(fromDate));
      }
      fromDate.setDate(fromDate.getDate() + 1);
    }
    return list;
  }

  sortDates(datesArray: Date[]) {
    let n = datesArray.length;
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (datesArray[j] > datesArray[j + 1]) {
          let x = datesArray[j];
          datesArray[j] = datesArray[j + 1];
          datesArray[j + 1] = x;
        }
      }
    }
    return datesArray;
  }

  /**
   * On Recurring Day selected
   * @param event 
   */
  recurringDaySelected(event) {
    this.recurringDays.forEach(x => {
      if (x.value == event.source.value)
        x.checked = event.checked;
    })
  }

  /**
   * On Briefing selected
   * @param event 
   */
  briefingSelected(event) {
    this.briefingData.forEach(x => {
      if (x.value == event.source.value)
        x.checked = event.checked;
    });
  }


  /**
   * Creating form using form builder for Job
   */
  createForm() {
    this.form = this._fb.group({
      id: [''],
      jobTypeId: ['', Validators.required],
      jobId: ['', Validators.required],
      emplrId: ['', Validators.required],
      briefing: [[], Validators.required],
      location: ['', Validators.required],
      employerId: ['', Validators.required],  // set id from selected job
      instructions: [''],
      employmentType: ['SINGLE_DAY', Validators.required],
      employmentTitle: ['', Validators.required],
      noOfMaleRequired: [1, Validators.pattern('^[0-9]+$')],
      noOfFemaleRequired: [1, Validators.pattern('^[0-9]+$')],
      //termsForLatePayment: ['', Validators.required],
      gstn: [''],
      attendanceLogInSelfieRequired: [true],
      attendanceLogOutSelfieRequired: [false],
      attendanceLocationTrackerRequired: [false],
      attendanceAtVenueRequired: [false],
      proofOfWorkPicturesRequired: [true],
      proofOfWorkFields: this._fb.array([
        // this.workPicturesForm,
      ]),
      maleFlatRate: [null, Validators.pattern('^[0-9]+$')],
      femaleFlatRate: [null, Validators.pattern('^[0-9]+$')],

      // employer payment info
      employerPaymentInfos: this._fb.array([
        this.employerPaymentInfosForm,
      ]),

      // dates
      dates: this._fb.array([
        this.datesForm
      ]),
      dressCodeFemale: ['', Validators.required],
      dressCodeMale: ['', Validators.required],
      templateTitle: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      templateDescription: ['']

    });
    this.employerPaymentInfosFormArray.push(this.employerPaymentInfosForm);
    // console.log(this.employmentFormData.controls)
  }

  /**
   *  Get EmployerPaymentInfoForm form Array 
   */
  get employerPaymentInfosFormArray() {
    return this.form.get('employerPaymentInfos') as FormArray;
  }

  /**
   *  Get Date form Array 
   */
  get datesArrayForm() {
    return this.form.get('dates') as FormArray;
  }

  get proofOfWorkFieldsForm() {
    return this.form.get('proofOfWorkFields') as FormArray;
  }

  /**
   * Form Access
   */
  get employmentFormData() {
    return this.form;
  }


  /**
   *  Dates Form  
   */
  get datesForm() {
    return this._fb.group({
      date: ['', Validators.required],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required],
      time: ['', Validators.required],
      timeFrom: ['', Validators.required],
      timeTo: ['', Validators.required],
    });
  }

  /**
   *  Employer Payment Information Form 
   */
  get employerPaymentInfosForm() {
    return this._fb.group({
      level: ['Beginner'],
      gender: [''],
      maxRate: [''],
      flatRate: [''],
    });
  }

  get workPicturesForm() {
    return this._fb.group({
      requiredInfoFieldLabel: [''],
      requiredInfoFieldType: [''],
      isMandatory: false,
    });
  }

  get workPicturesFormWithValidators() {
    return this._fb.group({
      requiredInfoFieldLabel: ['', Validators.required],
      requiredInfoFieldType: ['', Validators.required],
      isMandatory: [true],
    });
  }

  // Get Days form delete row
  deleteDays(index: number) {
    if (this.datesArrayForm.length > 1) {
      this.datesArrayForm.removeAt(index);
    }
  }
  // Get Days form add new row
  addDays() {
    this.datesArrayForm.push(this.datesForm);
  }

  /**
   * Job display function for list
   * @param job 
   */
  jobDisplayFn(job: Job) {
    return job ? job.title : job;
  }

  /**
   * On Job Select pass the employer id of job into current form
   * @param job 
   */
  async jobSelected(job: Job) {
    // setting select job's employer id for employment
    if (job) {
      // this.form.controls.employerId.setValue(job.employerId);
      await this.getJobType(job);
    }
  }

  /**
   * Get job type for selected job
   */
  async getJobType(job: Job) {
    if (job.jobTypeId)
      await this.jobTypeService.getById(job.jobTypeId).toPromise()
        .then(type => {
          this.jobType = type;
          // console.log("xxx", this.templateForm.value)
          // this.templateForm.controls['jobTypeId'].setValue(this.jobType.id);

        }

        );
    if (this.jobType) {
      this.changeMaleTotal();
      this.changeFemaleTotal();
    }
  }

  /**
  * Job Search and select
  * @param term 
  */
  async onJobTemplateSearch(term) {
    const params: Search[] = [];
    params.push(new Search('title', term));
    if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
      params.push(new Search('employerId', this.profile.user_details.employerId));
    }
    if (this.isSuper && this.form.controls['emplrId'] && this.form.controls['emplrId'].value != '') {
      params.push(new Search('employerId', this.form.controls['emplrId'].value));
    }
    if (this.form.controls['jobTypeId'] && this.form.controls['jobTypeId'].value != '') {
      params.push(new Search('jobTypeId', this.form.controls['jobTypeId'].value));
    }

    await this.jobService.searchAndSort(0, 10000, null, null, params).toPromise()
      .then(data => {
        this.jobs = data.content;
      });
  }

  setAddress(data) {
    this.data.address.latitude = data.latitude;
    this.data.address.longitude = data.longitude;
    this.data.address.zip = data.zip;
    this.data.address.country = data.country;
    this.data.address.region = data.region;
    this.data.address.city = data.city;
    this.data.address.address = data.address;
    this.data.address.placeId = data.placeId;
  }

  fromTimeEvent(event: any) {
    this.minTimeTo = event;
  }
  /**
   * Female price slider change
   * @param event 
   */
  femaleSliderChange(hourlyRate, basePay: number) {
    this.currFemaleHourlyRate = hourlyRate;
    this.femaleTotal = 0;
    this.femaleSingleTotal = 0;
    //this.grandTotal = 0;
    let fr: number = 0;
    if (this.employmentFormData.controls.noOfFemaleRequired.value) {
      fr = this.employmentFormData.controls.noOfFemaleRequired.value;
    }
    this.dateArr.forEach(date => {
      this.femaleTotal += (basePay + (hourlyRate * date.tempHours)) * fr;
      this.femaleSingleTotal += (basePay + (hourlyRate * date.tempHours));
    });

    // if(this.employmentFormData.controls.noOfMaleRequired.value > 0 ) {
    //   this.grandTotal = femaleTotal + this.grandTotal;
    // }else {
    //   this.grandTotal = femaleTotal;
    // }

    // get job type here and find level of the users
    this.femaleLevelLabel = this.findUserLevel(parseInt(hourlyRate), 'Female');
    this.employerPaymentInfosFormArray.at(0).value.level = this.femaleLevelLabel;
    this.employerPaymentInfosFormArray.at(0).value.gender = 'Female';
    this.employerPaymentInfosFormArray.at(0).value.maxRate = hourlyRate;

  }

  femaleCounterInc() {
    if (this.employmentFormData.controls.noOfFemaleRequired.value + 1 > 0)
      this.keepFemaleSection = false;
    let noOfFemaleRequired = 0;
    if (this.employmentFormData.controls.noOfFemaleRequired.value) {
      noOfFemaleRequired = parseInt(this.employmentFormData.controls.noOfFemaleRequired.value);
    }
    this.employmentFormData.controls.noOfFemaleRequired.setValue(noOfFemaleRequired + 1);
    this.femaleTotal = this.femaleSingleTotal * (noOfFemaleRequired + 1);
    this.flatRateBannerValue();
  }


  femaleCounterDec() {
    let fc: number = 0;
    if (this.employmentFormData.controls.noOfFemaleRequired.value) {
      fc = this.employmentFormData.controls.noOfFemaleRequired.value;
    }
    if (fc - 1 == 0)
      this.keepFemaleSection = true;
    if (fc - 1 < 0)
      return;

    this.employmentFormData.controls.noOfFemaleRequired.setValue(fc - 1);
    this.femaleTotal = this.femaleSingleTotal * (fc - 1);
    this.flatRateBannerValue();
  }

  /**
   * Male price slider change
   * @param event 
   */
  maleSliderChange(hourlyRate, basePay: number) {
    this.currMaleHourlyRate = hourlyRate;
    this.maleTotal = 0;
    this.maleSingleTotal = 0;
    let mr: number = 0;
    if (this.employmentFormData.controls.noOfMaleRequired.value) {
      mr = this.employmentFormData.controls.noOfMaleRequired.value;
    }
    this.dateArr.forEach(date => {
      this.maleTotal += (basePay + (hourlyRate * date.tempHours)) * mr;
      this.maleSingleTotal += (basePay + (hourlyRate * date.tempHours));
    });

    // get job type here and find level of the users
    this.maleLevelLabel = this.findUserLevel(parseInt(hourlyRate), 'Male');

    this.employerPaymentInfosFormArray.at(1).value.level = this.maleLevelLabel;
    this.employerPaymentInfosFormArray.at(1).value.gender = 'Male';
    this.employerPaymentInfosFormArray.at(1).value.maxRate = hourlyRate;

  }

  // shows the min and max value at start and end of slider

  formatSliderValue(value: number | null) {
    if (!value) {
      return '';
    }

    const minValue = this.jobType.employerPrices[1].jobTypeHourlyPriceRange[0].hourlyMin;
    const maxValue = this.jobType.employerPrices[1].jobTypeHourlyPriceRange[2].hourlyMax;

    return `${minValue} - ${value} - ${maxValue}`;
  }


  maleCounterInc() {
    let noOfMaleRequired: number = 0;
    if (this.employmentFormData.controls.noOfMaleRequired.value) {
      noOfMaleRequired = parseInt(this.employmentFormData.controls.noOfMaleRequired.value);
    }
    if (noOfMaleRequired + 1 > 0) {
      this.keepMaleSection = false;
    }

    this.employmentFormData.controls.noOfMaleRequired.setValue(noOfMaleRequired + 1);
    this.maleTotal = this.maleSingleTotal * (noOfMaleRequired + 1);
    this.flatRateBannerValue();
  }


  maleCounterDec() {
    let mc: number = 0;
    if (this.employmentFormData.controls.noOfMaleRequired.value) {
      mc = this.employmentFormData.controls.noOfMaleRequired.value;
    }
    if (mc - 1 == 0)
      this.keepMaleSection = true;
    if (mc - 1 < 0)
      return;

    this.employmentFormData.controls.noOfMaleRequired.setValue(mc - 1);
    this.maleTotal = this.maleSingleTotal * (mc - 1);
    this.flatRateBannerValue();
  }

  /**
   * Find User level on price slider change
   *
   * @param hourlyRate 
   * @param gender 
   */
  findUserLevel(hourlyRate: number, gender) {
    let level = 'Beginner';
    this.jobType.employerPrices.forEach(typePrice => {
      if (typePrice.gender == gender) {
        typePrice.jobTypeHourlyPriceRange.forEach(priceRange => {

          if (hourlyRate <= priceRange.hourlyMax && hourlyRate >= priceRange.hourlyMin) {
            level = priceRange.level;
          }

        });
      }
    });
    return level;
  }

  /**
   * On term selected
   * @param event 
   */
  onTermSelection(event) {
    this.terms.forEach(x => {
      if (x.value == event.value) {
        this.termsText.nativeElement.value = x.text;
        this.form.controls.termsForLatePayment.setValue(x.text);
      }
    })
  }

  /**
   * Set term selected on edit
   */
  getTermSelected() {
    if (!this.data || !this.data.termsForLatePayment) return;
    let val = '';
    this.terms.forEach(x => {
      if (x.text == this.data.termsForLatePayment)
        val = x.value;
    });
    return val;
  }

  /**
   * Find Male/Female price
   */
  getPriceSelected(gender: string): number {
    // Existing checks for currMaleHourlyRate and currFemaleHourlyRate
    if (gender == 'Male' && this.currMaleHourlyRate != null)
      return this.currMaleHourlyRate;
    else if (gender == 'Female' && this.currFemaleHourlyRate != null)
      return this.currFemaleHourlyRate;

    // New logic to get the minimum value from the jobType object
    const defaultPrice = this.jobType.employerPrices[0].jobTypeHourlyPriceRange[0].hourlyMin;
    if (defaultPrice) return defaultPrice;

    // Existing logic for employerPaymentInfos
    if (!this.data || !this.data.employerPaymentInfos) return;
    let val = 0;
    this.data.employerPaymentInfos.forEach(p => {
      if (p.gender == gender)
        val = p.maxRate;
    })

    return val;
  }


  handleRateType(rateType) {
    if (rateType == 'hourlyRate') {
      this.isFlatRate = false;
      this.data.flatRate = false;
      this.femaleTotal = 0;
      this.femaleSingleTotal = 0;
      let fr: number = 0;
      if (this.employmentFormData.controls.noOfFemaleRequired.value) {
        fr = this.employmentFormData.controls.noOfFemaleRequired.value;
      }
      this.dateArr.forEach(date => {
        this.femaleTotal += (this.jobType.employerPrices[1].basePrice + (this.currFemaleHourlyRate * date.tempHours)) * fr;
        this.femaleSingleTotal += (this.jobType.employerPrices[1].basePrice + (this.currFemaleHourlyRate * date.tempHours));
      });

      this.maleTotal = 0;
      this.maleSingleTotal = 0;
      let mr: number = 0;
      if (this.employmentFormData.controls.noOfMaleRequired.value) {
        mr = this.employmentFormData.controls.noOfMaleRequired.value;
      }
      this.dateArr.forEach(date => {
        this.maleTotal += (this.jobType.employerPrices[0].basePrice + (this.currMaleHourlyRate * date.tempHours)) * mr;
        this.maleSingleTotal += (this.jobType.employerPrices[0].basePrice + (this.currMaleHourlyRate * date.tempHours));
      });
    }
    else if (rateType == 'flatRate') {
      this.isFlatRate = true;
      this.data.flatRate = true;

      let fr: number = 0;
      if (this.employmentFormData.controls.femaleFlatRate.value) {
        fr = parseInt(this.employmentFormData.controls.femaleFlatRate.value);
      }
      let nofr: number = 0;
      if (this.employmentFormData.controls.noOfFemaleRequired.value) {
        nofr = this.employmentFormData.controls.noOfFemaleRequired.value;
      }
      this.femaleTotal = fr * nofr;
      this.femaleSingleTotal = fr;

      let mr: number = 0;
      if (this.employmentFormData.controls.maleFlatRate.value) {
        mr = parseInt(this.employmentFormData.controls.maleFlatRate.value);
      }

      if (this.employmentFormData.controls.noOfMaleRequired.value) {
        this.maleTotal = mr * this.employmentFormData.controls.noOfMaleRequired.value;
      } else {
        this.maleTotal = mr * 0;
      }
      this.maleSingleTotal = mr;

      this.flatRateBannerValue();
    }
  }

  flatRateBannerValue() {
    let flatRateBannerValue = "This job has been sent as flat-rate job with ";
    if (this.employmentFormData.controls.maleFlatRate.value && this.employmentFormData.controls.noOfMaleRequired.value && this.employmentFormData.controls.noOfMaleRequired.value > 0) {
      flatRateBannerValue += "₹ " + this.employmentFormData.controls.maleFlatRate.value + " for " + this.jobType.name + " Male";
    }
    if (this.employmentFormData.controls.noOfMaleRequired.value && this.employmentFormData.controls.noOfMaleRequired.value > 0 && this.employmentFormData.controls.noOfFemaleRequired.value > 0) {
      flatRateBannerValue += " and ";
    }
    if (this.employmentFormData.controls.femaleFlatRate.value && this.employmentFormData.controls.noOfFemaleRequired.value && this.employmentFormData.controls.noOfFemaleRequired.value > 0) {
      flatRateBannerValue += "₹ " + this.employmentFormData.controls.femaleFlatRate.value + " for " + this.jobType.name + " Female";
    }
    this.flatRateBanner = flatRateBannerValue + ".";
    this.flatRateHourlyBanner = flatRateBannerValue + ". You need to convert it to a pay per hour job.";
  }

  femaleFlatRateChange() {
    let fr: number = 0;
    if (this.employmentFormData.controls.femaleFlatRate.value) {
      fr = parseInt(this.employmentFormData.controls.femaleFlatRate.value);
    }

    if (this.employmentFormData.controls.noOfFemaleRequired.value) {
      this.femaleTotal = fr * this.employmentFormData.controls.noOfFemaleRequired.value;
    } else {
      this.femaleTotal = fr * 0;
    }
    this.femaleSingleTotal = fr;
    this.employerPaymentInfosFormArray.at(0).value.gender = 'Female';
    this.employerPaymentInfosFormArray.at(0).value.flatRate = fr;
    this.flatRateBannerValue();
  }

  maleFlatRateChange() {
    let mr = 0;
    if (this.employmentFormData.controls.maleFlatRate.value) {
      mr = parseInt(this.employmentFormData.controls.maleFlatRate.value);
    }

    if (this.employmentFormData.controls.noOfMaleRequired.value) {
      this.maleTotal = mr * this.employmentFormData.controls.noOfMaleRequired.value;
    } else {
      this.maleTotal = mr * 0;
    }
    this.maleSingleTotal = mr;
    this.employerPaymentInfosFormArray.at(1).value.gender = 'Male';
    this.employerPaymentInfosFormArray.at(1).value.flatRate = mr;
    this.flatRateBannerValue();
  }

  /**
   * Process data towards saving
   * @param isDraft 
   */
  onSave(isDraft: boolean) {
    this.sentRequestForPayment = true;
    if (!isDraft) {
      if ((this.employmentFormData.controls.noOfFemaleRequired.value && this.employmentFormData.controls.noOfFemaleRequired.value > 0 && this.femaleSingleTotal == 0)
        || (this.employmentFormData.controls.noOfMaleRequired.value && this.employmentFormData.controls.noOfMaleRequired.value > 0 && this.maleSingleTotal == 0)) {
        this.notify.showMessage("Please select hourly rate", 2000);
        return;
      }
    }

    let employmentRequest: Employment = this.form.value;
    // shifting date array into request
    if (employmentRequest.employmentType == 'RECURRING_DAYS')
      employmentRequest.dates = this.dateArr;

    // get briefing data
    employmentRequest.briefing = [];
    this.briefingData.forEach(x => {
      if (x.checked)
        employmentRequest.briefing.push(x.value)
    })

    let epiFemale = new EmployerPaymentInfo();
    epiFemale.gender = 'Female';
    epiFemale.level = 'Beginner';
    epiFemale.maxRate = 0;
    epiFemale.flatRate = 0;

    let epiMale = new EmployerPaymentInfo();
    epiMale.gender = 'Male';
    epiMale.level = 'Beginner';
    epiMale.maxRate = 0;
    epiMale.flatRate = 0;

    if (this.isFlatRate) {
      employmentRequest.flatRate = true;
      epiFemale.flatRate = this.employmentFormData.controls.femaleFlatRate.value ? this.employmentFormData.controls.femaleFlatRate.value : 0;
      epiMale.flatRate = this.employmentFormData.controls.maleFlatRate.value ? this.employmentFormData.controls.maleFlatRate.value : 0;
    }
    else {
      employmentRequest.flatRate = false;
      epiFemale.maxRate = this.currFemaleHourlyRate;
      epiFemale.level = this.findUserLevel(this.currFemaleHourlyRate, 'Female');
      epiMale.maxRate = this.currMaleHourlyRate;
      epiMale.level = this.findUserLevel(this.currMaleHourlyRate, 'Male');
    }

    employmentRequest.employerPaymentInfos = [epiFemale, epiMale];

    // console.log('pay info');
    employmentRequest.employerPaymentInfos.forEach(epi => {
      // console.log(epi);
    })

    // setting the address location
    employmentRequest.location = this.data.address;

    // setting job id
    //employmentRequest.jobId = (employmentRequest.jobId as (any)).id;
    employmentRequest.jobId = this.form.controls['jobId'].value;

    // set recurringDays if selected 
    employmentRequest.recurringDays = [];
    if (employmentRequest.employmentType == 'RECURRING_DAYS') {
      this.recurringDays.forEach(x => {
        if (x.checked)
          employmentRequest.recurringDays.push(x.value)
      });
    }

    // set jobSeekerPaymentInfo
    if (!this.data.jobSeekerPaymentInfo && !this.data.jobSeekerPaymentInfo_female) {
      employmentRequest.jobSeekerPaymentInfo = null;
    } else {
      employmentRequest.jobSeekerPaymentInfo = this.data.jobSeekerPaymentInfo;
      employmentRequest.jobSeekerPaymentInfo_female = this.data.jobSeekerPaymentInfo_female;
    }

    // fix dates
    employmentRequest.dates.forEach(x => {
      x.date = new Date(x.date.getTime() - (x.date.getTimezoneOffset() * 60000));
    });


    // set status
    employmentRequest.status = isDraft ? 'Draft' : 'Pending';

    /*if(!this.form.controls.termsForLatePayment.value) {
      this.notify.showMessage("Please select terms for payment", 2000);
      return;
    }*/

    // remove extra from male/female required
    /*employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.maxRate > 0);
    if (employmentRequest.noOfFemaleRequired == 0) {
      employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.gender != 'Female');
    }
    if (employmentRequest.noOfMaleRequired == 0) {
      employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.gender != 'Male');
    }*/
    employmentRequest.jobFulfilerCommission = this.data.jobFulfilerCommission;
    if (employmentRequest.proofOfWorkFields && employmentRequest.proofOfWorkFields.length) {
      employmentRequest.proofOfWorkFields.forEach(ele => {
        ele.requiredInfoFieldName = ele.requiredInfoFieldLabel.replaceAll(' ', '_').toLowerCase();
      })
    }
    employmentRequest.proofOfWork = {
      proofOfWorkPicturesRequired: this.form.value.proofOfWorkPicturesRequired,
      proofOfWorkFields: employmentRequest.proofOfWorkFields
    }
    delete employmentRequest.proofOfWorkPicturesRequired;
    delete employmentRequest.proofOfWorkFields;
    console.log("employmentRequest------", employmentRequest)

    if (this.data && this.data.id) {
      this._update(employmentRequest);
    } else {
      this._create(employmentRequest);
    }

  }

  /**
   * Create Employment request to server
   * @param employment 
   */
  async _create(employment: Employment) {

    await this.employmentService.create(employment).toPromise()
      .then(employment => {
        this.sentRequestForPayment = false;
        this.data.id = employment.id;
        this.data.employeerId = employment.employerId
        this.notify.showMessage("Employment successfully created!", 2000);
        this.router.navigate(['/employment/all/all']);
        // this.dialogRef.close(true);
      }).catch(err => {
        this.sentRequestForPayment = false;
      });

  }

  /**
   * Update Employment request to server
   * @param employment 
   */
  _update(employment: Employment) {
    this.employmentService.update(employment.id, employment).subscribe(employment => {
    }, (error) => {
      this.sentRequestForPayment = false;
    }, () => {
      this.sentRequestForPayment = false;
      this.notify.showMessage("Employment successfully updated!", 2000);
      this.router.navigate(['/employment/all/all']);
      // this.dialogRef.close(true);
    })

  }


  // jobDateChange(event) {
  //   // 0 - Sunday
  //   // 1 - Monday
  //   // 2 - Tuesday
  //   // 3 - Wednesday
  //   // 4 - Thrusday
  //   // 5 - Friday
  //   // 6 - Saturday
  //   let fromDate = this.form.controls.dates.value[0].dateFrom;
  //   let toDate = this.form.controls.dates.value[0].dateTo;
  //   if (!fromDate || !toDate) return;

  //   // recurringDays
  //   this.recurringDays.forEach(days => {
  //     let check = this.checkDate(fromDate, toDate, days.val);
  //     this.recurringDays.map(x => {
  //       return x.val == days.val ? x.disabled = !check : x;
  //     });
  //   });
  // }

  // jobDateChange(event) {
  //   const selectedDate = event.value;

  //   if (!selectedDate) return;

  //   const isEditMode = this.mode === 'edit';

  //   this.datesArrayForm.controls.forEach((control) => {
  //     const dateFromControl = control.get('dateFrom');
  //     const timeFromControl = control.get('timeFrom');
  //     const timeToControl = control.get('timeTo');

  //     if (dateFromControl && timeFromControl && timeToControl) {
  //       const timeFromValue = timeFromControl.value;
  //       const timeToValue = timeToControl.value;

  //       const hasManualTimeSelection = timeFromValue && timeToValue;

  //       if (!(isEditMode || hasManualTimeSelection)) {
  //         const currentDate = new Date();
  //         const currentHours = currentDate.getHours();
  //         const currentMinutes = currentDate.getMinutes();

  //         let startTime = new Date(selectedDate);
  //         startTime.setHours(currentHours, currentMinutes, 0, 0);

  //         // Adjust start time to the next 30-minute interval
  //         const minutes = startTime.getMinutes();
  //         const remainder = minutes % 30;
  //         startTime.setMinutes(remainder >= 30 ? minutes + (30 - remainder) : minutes + (30 - remainder));

  //         let endTime = new Date(startTime);
  //         endTime.setHours(endTime.getHours() + 1);

  //         timeFromControl.setValue(`${('0' + startTime.getHours()).slice(-2)}:${('0' + startTime.getMinutes()).slice(-2)}`);
  //         timeToControl.setValue(`${('0' + endTime.getHours()).slice(-2)}:${('0' + endTime.getMinutes()).slice(-2)}`);
  //       }
  //     }
  //   });
  // }

  jobDateChange(event) {
    const selectedDate = event.value;

    if (!selectedDate) return;

    const isEditMode = this.mode === 'edit';

    this.datesArrayForm.controls.forEach((control) => {
      const dateFromControl = control.get('dateFrom');
      const timeFromControl = control.get('timeFrom');
      const timeToControl = control.get('timeTo');

      if (dateFromControl && timeFromControl && timeToControl) {
        const timeFromValue = timeFromControl.value;
        const timeToValue = timeToControl.value;

        const hasManualTimeSelection = timeFromValue || timeToValue;

        if (!(isEditMode || hasManualTimeSelection)) {
          const currentDate = new Date();
          const currentHours = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();

          let startTime = new Date(selectedDate);
          startTime.setHours(currentHours, currentMinutes, 0, 0);

          // Adjust start time to the next 30-minute interval
          const minutes = startTime.getMinutes();
          const remainder = minutes % 30;
          startTime.setMinutes(remainder >= 30 ? minutes + (30 - remainder) : minutes + (30 - remainder));

          let endTime = new Date(startTime);
          endTime.setHours(endTime.getHours() + 1);

          timeFromControl.setValue(`${('0' + startTime.getHours()).slice(-2)}:${('0' + startTime.getMinutes()).slice(-2)}`);
          timeToControl.setValue(`${('0' + endTime.getHours()).slice(-2)}:${('0' + endTime.getMinutes()).slice(-2)}`);



          const newStartTime = timeFromControl.value;
          const newEndTime = timeToControl.value;
          this.newTimeDifference = this.calculateTimeDifference(newStartTime, newEndTime);
          this.times.forEach((time) => {
            const newStartTime = timeFromControl.value;
            const newEndTime = time.value;
            time.timeDifference = this.calculateTimeDifference(newStartTime, newEndTime);
          });
          console.log(this.newTimeDifference, "this.newTimeDifference")
        }
      }
    });
  }





  checkDate(date1, date2, compareDay) {
    var d1 = new Date(date1),
      d2 = new Date(date2),
      dayFound = false;

    while (d1 <= d2) {
      var day = d1.getDay();
      dayFound = (day === compareDay);
      if (dayFound) { return true; } // return immediately if weekend found
      d1.setDate(d1.getDate() + 1);
    }
    return false;
  }

  savedEmployment = null;

  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id;

      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;

      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      this.employeerPaymentOrderService.update(response.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        // if (this.isFlatRate) {
        //   this.savedEmployment.status = 'Pending';
        // }
        // else {
        //   this.savedEmployment.status = 'Active';
        // }
        this.employmentService.updateV2(this.savedEmployment.id, this.savedEmployment).toPromise().then(emp => {
          this.notify.showMessage("Payment success and employment saved", 2000);
          this.router.navigate(['/employment/all/all']);
        })
      })

      /**
       * Need to save employement in approved state
       */},
    "modal": {
      "ondismiss": () => {
        this.router.navigate(['/employment/all/all']);
      }
    }
  };

  async saveEmployeementV2(status: string) {
    let employmentRequest: Employment = this.form.value;
    // shifting date array into request
    if (employmentRequest.employmentType == 'RECURRING_DAYS')
      employmentRequest.dates = this.dateArr;
    // get briefing data
    employmentRequest.briefing = [];
    this.briefingData.forEach(x => {
      if (x.checked)
        employmentRequest.briefing.push(x.value)
    })

    let epiFemale = new EmployerPaymentInfo();
    epiFemale.gender = 'Female';
    epiFemale.level = 'Beginner';
    epiFemale.maxRate = 0;
    epiFemale.flatRate = 0;

    let epiMale = new EmployerPaymentInfo();
    epiMale.gender = 'Male';
    epiMale.level = 'Beginner';
    epiMale.maxRate = 0;
    epiMale.flatRate = 0;

    if (this.isFlatRate) {
      employmentRequest.flatRate = true;
      epiFemale.flatRate = this.employmentFormData.controls.femaleFlatRate.value ? this.employmentFormData.controls.femaleFlatRate.value : 0;
      epiMale.flatRate = this.employmentFormData.controls.maleFlatRate.value ? this.employmentFormData.controls.maleFlatRate.value : 0;
    }
    else {
      employmentRequest.flatRate = false;
      epiFemale.maxRate = this.currFemaleHourlyRate;
      epiFemale.level = this.findUserLevel(this.currFemaleHourlyRate, 'Female');
      epiMale.maxRate = this.currMaleHourlyRate;
      epiMale.level = this.findUserLevel(this.currMaleHourlyRate, 'Male');
    }

    employmentRequest.employerPaymentInfos = [epiFemale, epiMale];

    // console.log('pay info');
    employmentRequest.employerPaymentInfos.forEach(epi => {
      // console.log(epi);
    })

    // setting the address location
    employmentRequest.location = this.data.address;

    // setting job id
    // employmentRequest.jobId = (employmentRequest.jobId as (any)).id;
    employmentRequest.jobId = this.form.controls['jobId'].value;

    // set recurringDays if selected 
    employmentRequest.recurringDays = [];
    if (employmentRequest.employmentType == 'RECURRING_DAYS') {
      this.recurringDays.forEach(x => {
        if (x.checked)
          employmentRequest.recurringDays.push(x.value)
      });
    }

    // set jobSeekerPaymentInfo
    if (!this.data.jobSeekerPaymentInfo && !this.data.jobSeekerPaymentInfo_female) {
      employmentRequest.jobSeekerPaymentInfo = null;
    } else {
      employmentRequest.jobSeekerPaymentInfo = this.data.jobSeekerPaymentInfo;
      employmentRequest.jobSeekerPaymentInfo_female = this.data.jobSeekerPaymentInfo_female;
    }

    // fix dates
    employmentRequest.dates.forEach(x => {
      x.date = new Date(x.date.getTime() - (x.date.getTimezoneOffset() * 60000));
    });


    // set status
    employmentRequest.status = status;

    // if(!this.form.controls.termsForLatePayment.value) {
    //   this.notify.showMessage("Please select terms for payment", 2000);
    //   return;
    // }

    // remove extra from male/female required
    /*employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.maxRate > 0);
    if (employmentRequest.noOfFemaleRequired == 0) {
      employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.gender != 'Female');
    }
    if (employmentRequest.noOfMaleRequired == 0) {
      employmentRequest.employerPaymentInfos = employmentRequest.employerPaymentInfos.filter(i => i.gender != 'Male');
    }*/

    if (this.data && this.data.id) {
      this.employmentService.update(this.data.id, employmentRequest);
      return this.employmentService.getById(this.data.id);
    }
    else {
      return this.employmentService.create(employmentRequest);
    }

  }

  getAdvancePayAmount() {
    let amount = 0;
    amount = (this.femaleTotal + this.maleTotal) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
    return amount;
  }

  async buyRazorPay() {
    this.sentRequestForPayment = true;
    await (await this.saveEmployeementV2('Pending')).toPromise().then(employment => {
      this.savedEmployment = employment;
      this.data.id = employment.id;
      this.data.employeerId = employment.employerId
      let paymentOrderRequest = new EmployementPaymentOrder();
      //20% of total amount 
      paymentOrderRequest.amount = (this.femaleTotal + this.maleTotal) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
      paymentOrderRequest.employer_id = this.data.employeerId;
      paymentOrderRequest.employment_id = this.data.id;
      this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
        order => {
          if (order != null && order.status == 'SUCCESS') {
            this.razorPayOptions.amount = order.result.amount * 100;
            this.razorPayOptions.currency = 'INR';
            this.razorPayOptions.key = environment.rezorpayKey;
            this.razorPayOptions.order_id = order.result.razorPayOrderId;
            var rzp = new Razorpay(this.razorPayOptions);
            rzp.open()
            rzp.on('payment.failed', function (response) {
              this.sentRequestForPayment = false;
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
            });
          }
        }
      );
    }).catch(err => {
      this.sentRequestForPayment = false;
    });
  }

  async loadEmployers() {
    this.employerService.getEmpList().subscribe(data => {
      this.empList = data.content;
      this.employerList = data.content;
    });
  }

  // Get all job types
  async loadJobType() {
    this.jobTypeService.searchAndSort(0, 100, null, null).subscribe(data => {
      this.jobTypes = data.content;
      this.allJobTypes = data.content;
    });
  }

  // Get all Templates
  async loadTemplates() {
    const params: Search[] = [];
    if (this.isEmployerLogin || this.profile.user_details.employerId != null) {
      params.push(new Search('employerId', this.profile.user_details.employerId));
    }
    await this.jobService.searchAndSort(0, 100, null, null, params).toPromise().then(data => {
      this.templateLists = data.content;
    });
  }


  getEmployerId(): string | null {
    if (this.profile && this.profile.user_details && this.profile.user_details.employerId) {
      return this.profile.user_details.employerId;
    } else if (this.form.get('emplrId')) {
      return this.form.get('emplrId').value;
    }
    return null;
  }


  // Create new template
  createNewTemplate() {
    this.isLoadingDescription = true;

    if (!this.selectedValue) {
      console.error('Job type or Job type ID is missing.');
      return;
    }

    const employerId = this.getEmployerId();
    if (!employerId) {
      console.error('Employer ID is missing.');
      return;
    }

    const data = {
      id: '',
      employerName: this.profile.user_details.name,
      jobTypeId: this.selectedValue,
      employerId: employerId,
      description: this.form.get('templateDescription').value,
      title: this.form.get('templateTitle').value,
      tasks: [''],
      requirements: '',
      prerequisites: '',
      dressCodeFemale: this.form.get('dressCodeFemale').value,
      dressCodeMale: this.form.get('dressCodeMale').value,
    };

    this.http.post<any>(API_JOB, data)
      .subscribe(
        result => {
          if (result) {
            this.form.controls['jobId'].setValue(result.id);
            this.form.controls['jobId'].updateValueAndValidity();
            console.log("1 jobId", this.form.controls['jobId'].value)

            // Fetch jobType details for the newly created template
            this.getJobType({ jobTypeId: result.jobTypeId } as Job)
              .then(async () => {
                // Move to the next step after everything is set up
                //await this.setTemplate(result);
                this.onSelectTemplate(result);

                this.currMaleHourlyRate = this.jobType.employerPrices[0].jobTypeHourlyPriceRange[0].hourlyMin;
                console.log(this.currMaleHourlyRate)
                // console.log('Base Pay:', basePay);
                // console.log('Hourly Rate:', hourlyRate);
                // console.log('Date Array:', this.dateArr);

                this.data.employerPaymentInfos.forEach(p => {
                  if (this.jobType) {
                    if (p.gender == 'Female') {
                      if (this.currFemaleHourlyRate == null || this.currFemaleHourlyRate == 0) {
                        this.femaleSliderChange(p.maxRate, this.jobType.employerPrices[1].basePrice);
                      }
                    } else {
                      if (this.currMaleHourlyRate == null || this.currMaleHourlyRate == 0) {
                        this.maleSliderChange(p.maxRate, this.jobType.employerPrices[0].basePrice);
                      }
                    }
                  }
                });

                if (this.data.flatRate || this.isFlatRate) {
                  this.handleRateType('flatRate');
                } else {
                  this.handleRateType('hourlyRate');
                }
                this.isLoadingDescription = false;

                this.stepper++;
                this.scrollToTop();
              });


            if (this.stepper === 3) {
              this.nextBtnTxt = 'Next : Set budget';
            }

            if (this.stepper === 4) {
              this.nextBtnTxt = 'Review shift';
            }
          }
        },
        error => {
          console.error('Error creating job template:', error);
        }
      );

    console.log("2 jobId", this.form.controls['jobId'].value)
  }



  // selectedJob type through template get
  selectedJobType(jobTypeId, resetTemplate?) {
    this.jobs = [];
    let empId = null;

    if (this.profile.user_details.employerId) {
      empId = this.profile.user_details.employerId;
      this.form.controls['emplrId'].setValue(this.profile.user_details.employerId);
      this.form.controls['emplrId'].updateValueAndValidity();
      this.form.controls['employerId'].setValue(this.profile.user_details.employerId);
      this.form.controls['employerId'].updateValueAndValidity();
    } else {
      empId = this.form.controls['emplrId'].value;
    }

    this.selectedValue = jobTypeId;


    if (!jobTypeId) {
      this.templateLists.forEach(ele => {
        if (ele.employerId == empId) {
          this.jobs.push(ele)
        } else {
          if (ele.globalTemplate) {
            this.jobs.push(ele)
          }
        }
      })
    }
    else {
      // this.fetchJobDescription(jobTypeId);
      this.templateLists.forEach(ele => {
        if (ele.jobTypeId == jobTypeId) {
          if (ele.employerId == empId) {
            this.jobs.push(ele)
          } else {
            if (ele.globalTemplate) {
              this.jobs.push(ele)
            }
          }
        }
      })
    }
  }

  selectedEmployer(emplId, resetTemplate?) {
    this.form.controls['employerId'].setValue(emplId);
    this.form.controls['employerId'].updateValueAndValidity();
    this.jobs = [];
    if (emplId == '') {
      this.templateLists.forEach(ele => {
        if (ele.jobTypeId == this.form.controls['jobTypeId'].value) {
          if (ele.globalTemplate) {
            this.jobs.push(ele)
          } else {
            this.jobs.push(ele)
          }
        }
      })
    }
    else {
      if (this.form.controls['jobTypeId']) {
        this.templateLists.forEach(ele => {
          if (ele.jobTypeId == this.form.controls['jobTypeId'].value) {
            if (ele.employerId == emplId) {
              this.jobs.push(ele)
            } else {
              if (ele.globalTemplate) {
                this.jobs.push(ele)
              }
            }
          }
        })
      }
      else {
        this.templateLists.forEach(ele => {
          if (ele.employerId == emplId || ele.globalTemplate) {
            this.jobs.push(ele)
          }
        })
      }
    }
  }

  // search of job types on key up
  searchJobTypesByInput(event) {
    this.jobTypes = this.allJobTypes.filter(data =>
      data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1);
  }

  searchEmployerByInput(event) {
    this.empList = this.employerList.filter(data =>
      data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1);
  }




  async setTemplate(job: Job) {
    this.selectedTemplateDetails = job;
    if (job) {
      if (this.profile.user_details.employerId) {
        this.form.controls['emplrId'].setValue(job.employerId);
        this.form.controls['emplrId'].updateValueAndValidity();
        this.form.controls['employerId'].setValue(job.employerId);
        this.form.controls['employerId'].updateValueAndValidity();
      }
      await this.getJobType(job);
      console.log("cccc");
    }
  }

  // on select template
  async onSelectTemplate(job: Job) {
    console.log("yy", job.employerId)

    if (job.employerId == null) {

      this.appService.currentUser.subscribe(curruser => {
        if (curruser) {
          job.employerId = curruser.user_details.employerId;
        }
      });

    }
    console.log("zz", job);
    this.setTemplate(job);

  }


  // share checkout
  async shareCheckout() {
    if (this.data.id) {
      this.copyURL(this.data.id);
    } else {
      await (await this.saveEmployeementV2('Pending')).toPromise().then(employment => {
        this.data.id = employment.id;
        this.copyURL(this.data.id);
      })
    }
  }

  // copy share link
  copyURL(employementId) {
    let copyUrl = null;
    this.copied = true;
    this.copyTitle = 'Link copied!';

    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://dev.workorbits.com/checkout/' + employementId;
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://workorbits.com/checkout/' + employementId;
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copied = false;
      this.copyTitle = 'Share Checkout';
    }, 7000);
  }

  // change male total
  changeMaleTotal() {
    let obj = null;
    this.jobType.employerPrices.forEach(ele => {
      if (ele.gender == 'Male') {
        obj = {
          gender: "Male",
          level: "Beginner",
          maxRate: ele.jobTypeHourlyPriceRange[0].hourlyMin,
          flatRate: 0
        }
      }
    });

    if (this.data.employerPaymentInfos) {
      let checkMaleExists = this.data.employerPaymentInfos.filter(x => x.gender === 'Male');
      if (!checkMaleExists.length) {
        this.data.employerPaymentInfos.push(obj)
      }
    } else {
      this.data.employerPaymentInfos = [];
      this.data.employerPaymentInfos.push(obj)
    }
  }

  // change female total
  changeFemaleTotal() {
    let obj = null;
    this.jobType.employerPrices.forEach(ele => {
      if (ele.gender == 'Female') {
        obj = {
          gender: "Female",
          level: "Beginner",
          maxRate: ele.jobTypeHourlyPriceRange[0].hourlyMin,
          flatRate: 0
        }
      }
    });

    if (this.data.employerPaymentInfos) {
      let checkFemaleExists = this.data.employerPaymentInfos.filter(x => x.gender === 'Female');
      if (!checkFemaleExists.length) {
        this.data.employerPaymentInfos.push(obj)
      }
    } else {
      this.data.employerPaymentInfos = [];
      this.data.employerPaymentInfos.push(obj)
    }
  }

  /**
   * Get Job descriptioin using GAI function
   * @param event 
   */
  fetchJobDescription(jobTypeId) {
    this.isLoadingDescription = true;

    var inputText = "";
    this.jobTypes.forEach(function (value, key) {
      if (value.id == jobTypeId) {
        inputText = inputText + value.name + "\n" + value.notes;
        return;
      }
    })
    this.gaiJobDescription = "";
    // console.log("input text " + inputText);
    if (inputText != "") {
      this.gaiService.getGaiResponse(inputText, 100).then(res => {
        let respone = res.aiResponse;
        this.gaiJobDescription = respone;
        this.isLoadingDescription = false;
      }).catch((err: any) => {
        return;
      })
    }
  }

  checkSpecialInputs(): boolean {
    // console.log("checkSpecialInputs being called");
    const input1 = this.form.get('templateTitle');
    const input2 = this.form.get('templateDescription');
    const input3 = this.form.get('dressCodeMale');
    const input4 = this.form.get('dressCodeFemale');

    const areFieldsFilled = input1 && input1.value
      && input2 && input2.value
      && input3 && input3.value
      && input4 && input4.value;

    // if (areFieldsFilled) {
    //   this.form.controls['jobId'].setValue(null); // Clear the radio button selection
    //   console.log("jobId null selected")
    // }

    return areFieldsFilled;
  }

  checkIfTemplateIsSelected(): void {
    const selectedJobId = this.form.get('jobId').value;
    this.templateSelected = !!selectedJobId;

    if (this.templateSelected) {
      console.log("Selected Template ID:", selectedJobId);
    } else {
      console.log("No template selected.");
    }
  }

  onExpand(event: AnimationEvent) {
    if (event.toState === 'expanded') {
      this.scrollToBottom();
    }
  }

  isButtonDisabled(): boolean {
    if (this.stepper === 1) {
      // For step 1: Check if 'jobTypeId' is selected
      return !this.form.controls['jobTypeId'].value;
    }

    if (this.stepper === 2) {
      // For step 2: Check if a template is selected or if the four special input fields are filled
      const isTemplateSelected = !!this.form.get('jobId').value;
      const areSpecialInputsFilled = this.checkSpecialInputs();

      return !(isTemplateSelected || areSpecialInputsFilled);
    }

    if (this.stepper === 3) {
      this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
      if (!this.proofOfWorkFields.valid) {
        return true;
      } else {
        return false;
      }
    }

    if (this.stepper === 4 || this.stepper === 5) {
      return false;
    }

    // Default to disabled for any other unexpected case
    return true;
  }

  scrollToTop() {
    const targetElement = this.el.nativeElement;

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  scrollToBottom() {
    const targetElementBottom = document.getElementById('nxt-btn');
    if (targetElementBottom) {
      targetElementBottom.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // add more work pictures fields
  addMoreWorkPicturesFields() {
    setTimeout(() => {
      this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
      if (this.form.controls['proofOfWorkPicturesRequired'].value == true) {
        this.proofOfWorkFields.push(this.workPicturesFormWithValidators);
      } else {
        this.proofOfWorkFields.push(this.workPicturesForm);
      }
    }, 0);
  }

  // Delete picture fields
  deleteField(index) {
    this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
    this.proofOfWorkFields.removeAt(index);
    console.log(this.proofOfWorkFields.length)
  }

  // upload Work Pictures Fields set required
  uploadWorkPicturesFields() {
    setTimeout(() => {
      this.proofOfWorkFields = this.form.get('proofOfWorkFields') as FormArray;
      console.log(this.proofOfWorkFields, this.form.value)
      this.proofOfWorkFields.clear();
      console.log(this.form.controls['proofOfWorkPicturesRequired'].value)
      if (this.form.controls['proofOfWorkPicturesRequired'].value == true) {
        this.proofOfWorkFields.push(this.workPicturesFormWithValidators);
      } else {
        this.proofOfWorkFields.push(this.workPicturesForm);
      }
      this.cdRef.detectChanges();
      console.log(this.proofOfWorkFields, this.form.value)
    }, 0);
  }
}
