import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, NgModule } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Link } from "../../job-seeker/job-seeker-list/job-seeker-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInRight400ms } from "../../../../@core/animations/fade-in-right.animation";
import { scaleFadeIn400ms } from "../../../../@core/animations/scale-fade-in.animation";
import { EmploymentService } from "../../../../@core/services/employment/employment.service";
import { Employment } from "../../../../@core/interfaces/employment/employment.model";
import { EmployerService } from "../../../../@core/services/employer/employer.service";
import { NotificationService } from "../../../../@core/services/notification.service";
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { MatDialog, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material';
import { ColumnVisibilityDialogComponent } from 'src/app/shared/components/column-visibility-dialog/column-visibility-dialog.component';
import { Job } from 'src/@core/interfaces/job/job.model';
import { JobSeeker } from 'src/@core/interfaces/job-seeker/job-seeker.model';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { Search } from 'src/@core/interfaces/search';
import { STATUS } from 'src/app/shared/helpers/status';
import { JobApplicationService } from 'src/@core/services/job/job-application.service';
import { ProgressBarService } from 'src/@core/services/progress-bar.service';
import { AppService, CURRENT_EMPLOYER } from 'src/@core/services/app.service';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { roles } from 'src/app/shared/helpers/roles';
import { JobService } from '../../../../@core/services/job/job.service';
import { fadeInUp400ms } from '../../../../@core/animations/fade-in-up.animation';
import { JobTypeService } from '../../../../@core/services/job/job-type.service';
import { JobSeekerService } from '../../../../@core/services/job-seeker/job-seeker.service';
import theme from 'src/@core/utils/tailwindcss';
import { JobSeekerPreferenceService } from 'src/@core/services/job-seeker/job-seeker-preference.service';
import { DateTime } from 'luxon';
import { StatsService } from 'src/@core/services/job/stats.service';
import { EmployeerPaymentOrderService } from 'src/@core/services/employment/employment-payment-order.service';
import { EmployementPaymentOrder } from 'src/@core/interfaces/employment/employement-payment-order.model';
import { environment } from 'src/environments/environment';
import { ProfileApprovalDialogComponent } from '../../job-seeker/profile-approval-dialog/profile-approval-dialog.component';
import { ProfileRejectDialogComponent } from '../../job-seeker/profile-reject-dialog/profile-reject-dialog.component';
import { ProfileApprovalPreventDialogComponent } from '../../job-seeker/profile-approval-prevent-dialog/profile-approval-prevent-dialog.component';
import { JobSeekerPreference } from 'src/@core/interfaces/job-seeker/job-seeker-preference.model';
import { AddChampComponent } from '../../enterprise-admin/work-order/add-champ/add-champ.component';

declare var Razorpay: any;
@Component({
  selector: 'hour4u-admin-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  animations: [fadeInRight400ms, scaleFadeIn400ms, stagger40ms, fadeInUp400ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]

})
export class ApplicationsComponent implements OnInit {

  term = '';
  isSearch = false;
  dataSource: MatTableDataSource<JobApplication>;
  dataChange: BehaviorSubject<any>;
  selection = new SelectionModel<JobApplication>(true, []);

  color = theme.colors.green;
  redColor = theme.colors.red;
  purpleColor = theme.colors.purple;
  shiftLength: number;
  basePriceMale: number;
  basePriceFemale: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [20, 50, 100, 500];
  resultsLength = 0;

  sortOrder = 'desc';
  title = 'Job';


  roles = roles;
  columns = [
    // { columnDef: 'position', visible: true, label: 'Position' },
    { columnDef: 'name', visible: true, label: 'Job Seeker Name' },
    { columnDef: 'jobPrefStatus', visible: true, label: 'Status for Job Type' },
    { columnDef: 'hourlyRate', visible: true, label: 'Hourly Rate' },
    { columnDef: 'shiftPayments', visible: true, label: 'Shift Payments' },
    { columnDef: 'averageRating', visible: true, label: 'Rating' },
    { columnDef: 'hoursWorked', visible: true, label: 'Hours Worked' },
    { columnDef: 'totalJobs', visible: true, label: 'Total Shifts' },
    { columnDef: 'mobile', visible: true, label: 'Mobile' },
    { columnDef: 'status', visible: true, label: 'Application Status' },
  ];

  displayedColumns = this.columns.map(x => x.columnDef);

  employment: any = null;

  employer: Employer = this.appService.getEmployer();
  profile = this.appService.getCurrentAccount();

  loading = false;
  diameter = 30;
  loaderColor: string;
  mode = 'indeterminate';

  links: Link[] = [
    {
      label: 'ALL',
      route: '/all'
    },
    {
      label: 'APPROVED',
      route: '/approved'
    },
    /*{
      label: 'IN REVIEW',
      route: '/inreview'
    },*/
    {
      label: 'PENDING',
      route: '/pending'
    },
    {
      label: 'REJECTED',
      route: '/rejected'
    }
    // {
    //   label: 'STAND BY',
    //   route: '/standby'
    // }
  ];

  searchParams: Array<Search> = [];
  isSendButtonDisable = false;
  approvedTotal = 0;
  isLoading = true;
  copiedEmployer: boolean = false;
  copiedJobSeeker: boolean = false;
  copyTitleJobSeeker: string = 'Copy link to share Job Seeker';
  copyTitleEmployer: string = 'Copy link to share Employer';
  loginEmployerDetails: any = null;
  showNoAccessState: boolean = false;
  status = STATUS;
  approvePrevent = false;
  jobSeeker: JobSeeker = new JobSeeker();
  preference: JobSeekerPreference = new JobSeekerPreference();

  hideWhenData1ElementDisplayStyle = 'block';
  // hideWhenData2ElementDisplayStyle = 'none';
  // whenNoDataElementDisplayStyle = 'none';
  // detailHeaderElementDisplayStyle = 'none';
  applicationPendingSectionElementDisplayStyle = 'block';
  applicationTableElementDisplayStyle = 'block';
  baseImageUrl = environment.image_url;
  applicationStatus: string;
  job: any;
  showExpand: boolean;


  constructor(private route: ActivatedRoute,
    private employmentService: EmploymentService,
    private employerService: EmployerService,
    private jobService: JobService,
    private jobTypeService: JobTypeService,
    private notify: NotificationService,
    private jobApplicationService: JobApplicationService,
    private jobSeekerService: JobSeekerService,
    private jobSeekerPreferenceService: JobSeekerPreferenceService,
    private statsService: StatsService,
    private cdRef: ChangeDetectorRef,
    private progress: ProgressBarService,
    private appService: AppService,
    private router: Router,
    private employeerPaymentOrderService: EmployeerPaymentOrderService,
    private dialog: MatDialog,
  ) {

  }
  async ngOnInit() {
    this.dataChange = new BehaviorSubject(0);
    this.dataSource = new MatTableDataSource();
    this.applicationPendingSectionElementDisplayStyle = 'none';
    this.loading = true;
    await this.route.params.subscribe(data => {
      this.searchParams = [];
      //this.loadEmploymentData(data.id);
      this.searchParams.push(new Search('workOrderId', data.id));
      this.searchParams.push(new Search('employmentId', data.id));


      let urlPath = this.router.url.split("/");
      if (urlPath[urlPath.length - 2] == 'approved') {
        this.searchParams.push(new Search('status', 'APPROVED'));
      } /*else if (urlPath[urlPath.length - 2] == 'inreview') {
        this.searchParams.push(new Search('status', 'IN_REVIEW'));
      }*/ else if (urlPath[urlPath.length - 2] == 'pending') {
        this.searchParams.push(new Search('status', 'PENDING'));
      } else if (urlPath[urlPath.length - 2] == 'rejected') {
        this.searchParams.push(new Search('status', 'REJECTED'));
      }

      this.links = [
        {
          label: 'ALL APPLICATIONS',
          route: '/employment/job-applications/all/' + data.id
        },
        {
          label: 'APPROVED',
          route: '/employment/job-applications/approved/' + data.id
        },
        /*{
          label: 'IN REVIEW',
          route: '/employment/job-applications/inreview/' + data.id
        },*/
        {
          label: 'PENDING',
          route: '/employment/job-applications/pending/' + data.id
        },
        {
          label: 'REJECTED',
          route: '/employment/job-applications/rejected/' + data.id
        }
      ];

    });
    await this.loadApplicationData();
    this.loadCount();
    this.loginEmployerDetails = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
    //    console.log(this.loginEmployerDetails)
    if (!this.loginEmployerDetails) {
      this.loginEmployerDetails =
      {
        advanceAmountPercentage: 20
      }
    }
  }

  loadCount() {
    // get approved count
    const countParams: Search[] = [...this.searchParams];
    countParams.push(new Search('status', 'APPROVED'));
    this.jobApplicationService.count(countParams).subscribe(total => {
      this.approvedTotal = total;
    })
  }

  getColor(status) {
    switch (status) {
      case 'PENDING': this.purpleColor['500']; break;
      case 'REJECTED': this.redColor['500']; break;
      default: this.color['500']; break;
    }
  }


  async loadApplicationData() {

    console.log("load application is called")
    const statusFilters: Search[] = [...this.searchParams];

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dataSource.sort = this.sort;
    merge(this.sort.sortChange, this.paginator.page, this.dataChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.progress.show();

          /*return this.jobApplicationService!.searchAndSort(
                this.paginator.pageIndex, this.paginator.pageSize,
                this.sort.active, this.sort.direction, statusFilters, 'Job-application');*/

          return this.jobApplicationService!.getAllDetails(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, statusFilters, 'Job-application');
        }),
        map(data => {
          this.resultsLength = data.jobApplications.totalElements;

          if (data.jobApplications.content.length === 0) {
            // this.hideWhenData1ElementDisplayStyle = 'none';
            //this.hideWhenData2ElementDisplayStyle = 'none';
            // this.whenNoDataElementDisplayStyle = 'block';

          }
          console.log("22", data)
          this.employment = data.workOrderResponse.workOrder;
          this.employment.id = data.workOrderResponse.workOrder.id;
          this.loading = false;
          //this.employment.employer = data.employmentResponse.employer;
          this.employment.job = data.jobResponse.job;
          this.employment.job.jobType = data.jobResponse.jobType;
          console.log("33333333333333333333333333", data)

          // this.loadMergeJobSeekers().then((value) => {
          //   // this.hideWhenData2ElementDisplayStyle = 'block';
          //   this.processEmploymentData();
          //   this.loading = false;
          // });
          return data.jobApplications.content;
        }),
        catchError(() => {
          this.progress.hide();
          return observableOf([]);
        })
      ).subscribe(async (data: JobApplication[]) => {
        console.log("asdasd", data)
        this.dataSource.data = data;
        this.loadMergeJobSeekers().then((value) => {
          // this.hideWhenData2ElementDisplayStyle = 'block';
          this.processEmploymentData();
          this.loading = false;
        });

      });

    // not showing employer name if empployer is logged in
    if (this.profile.roles.indexOf(roles.EMPLOYER_STAFF) > -1 || this.profile.roles.indexOf(roles.EMPLOYER_ADMIN) > -1) {
      this.columns = this.columns.map(x => {
        if (x.columnDef === 'employerId' || x.columnDef === 'hourlyRate' || x.columnDef === 'shiftPayments' || x.columnDef === 'mobile')
          x.visible = false;
        return x;
      });
      this.displayedColumns = this.columns.filter(x => x.visible).map(x => x.columnDef);
    }

    this.cdRef.detectChanges();

  }

  /*async loadMergeJobSeekers() {
    const jobSeekerParams: Search[] = [];
    this.dataSource.data.map(jobApp => jobSeekerParams.push(new Search('id', jobApp.jobSeekerId)));
    await this.jobSeekerService.searchAndSort(0, 10000, null, null, jobSeekerParams, this.employment.id).toPromise()
      .then(async data => {
        if (data) {
          data.content.forEach(async js => {
            this.dataSource.data.map(async jobApp => {
              if (jobApp.jobSeekerId === js.id) {
                jobApp.tempJobSeeker = js;
                await this.jobSeekerPreferenceService.getChampJobTypeStatus(jobApp.jobSeekerId, this.employment.job.jobTypeId).toPromise().then(data1 => {
                  if (data1) {
                    jobApp.tempJobPrefStatus = data1;
                  }
                });
                await this.statsService.getTotalShiftsByJobSeeker(jobApp.jobSeekerId).toPromise().then(shifts => {
                  jobApp.tempTotalJobs = shifts;
                });
                await this.statsService.getTotalHoursByJobSeeker(jobApp.jobSeekerId).toPromise().then(hours => {
                  jobApp.tempHoursWorked = hours;
                });
              }
            });
          });
        }
      });
    this.isLoading = false;
    this.cdRef.detectChanges();
    this.progress.hide();
  }*/

  async loadMergeJobSeekers() {
    const jobSeekerParams: Search[] = [];
    let jobSeekerIdsArray = [];
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@")
    console.log(this.dataSource.data)
    this.dataSource.data.map(jobApp => {
      console.log('$""***', jobApp)
      jobSeekerParams.push(new Search('id', jobApp.jobSeekerId));
      jobSeekerIdsArray.push(jobApp.jobSeekerId)
    });
    let jobSeekerIdsString = jobSeekerIdsArray.toString();
    let jobSeekerStasObj = await this.loadJobSeekerStats(jobSeekerIdsString);
    console.log(this.employment)
    await this.jobSeekerService.getJobSeekersDetails(null, null, jobSeekerParams, this.employment.id).toPromise()
      .then(async data => {
        console.log("ssssd", data)

        if (data) {
          data.forEach(async js => {
            this.dataSource.data.map(async jobApp => {
              if (jobApp.jobSeekerId === js.id) {
                jobApp.tempJobSeeker = js;
                js.jobTypePreferences.forEach(jobTypePreference => {
                  if (jobTypePreference.typeId == this.employment.job.jobTypeId) {
                    jobApp.tempJobPrefStatus = jobTypePreference.level;
                  }
                });
                jobSeekerStasObj.forEach(jobSeekerStat => {
                  if (jobSeekerStat.jobSeekerId == js.id) {
                    jobApp.tempTotalJobs = jobSeekerStat.totalShifts;
                    jobApp.tempHoursWorked = jobSeekerStat.totalRecordedTime;
                  }
                });
              }
            });
            console.log("ssssd", data)
          });
          Promise.resolve("success");
        }
      });
    this.isLoading = false;
    this.cdRef.detectChanges();
    this.progress.hide();
  }
  async loadJobSeekerStats(jobSeekerIdsString) {
    let response = [];
    await this.statsService.getJobSeekersStats(jobSeekerIdsString).toPromise().then(async result => {
      response = result;
    });
    return response;
  }


  reloadTable() {
    this.dataChange.next(1);
  }

  search(data) {
    this.searchParams = [];
    this.searchParams.push(new Search('title', data));
    // this.searchParams.push(new Search('code', data));
    this.dataChange.next(1);
  }
  sortData(direction) {
    this.sort.active = 'createdOn';
    this.sort.direction = direction.value;
    this.sort.sortChange.next();
  }

  // click the row and go to jobseeker details paga
  viewJobSeeker(jobSeekerId?: string) {
    this.router.navigateByUrl(`/job-seeker/details/${jobSeekerId}/general`);
  }

  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** send for employer review */
  sendForEmployerReview() {
    this.isSendButtonDisable = true;
    this.notify.showMessage('Working on your request', 3000);
    const ids = this.selection.selected.map(jobApp => jobApp.id);
    this.jobApplicationService.sendForemployerReview(ids, this.employment.employerId).toPromise()
      .then(res => {
        this.notify.showMessage(ids.length + ' applications sent for employer review', 3000);
        this.isSendButtonDisable = false;
      }).catch(error => {
        this.notify.showMessage('Could not send applications for employer review', 3000);
        this.isSendButtonDisable = false;
      })
  }

  changeApplicationStatus(status, application) {
    application.status = status;
    application.isApplyLater = false;
    application.workOrderId = this.employment.id;
    this.jobApplicationService.update(application.id, application).toPromise()
      .then(res => {
        this.notify.showMessage('Job application status updated...', 2000);
        this.dataChange.next(1);
        this.loadCount();
      }).catch(error => {
        this.notify.showMessage('Unable to update Job application ...', 3000);
      });
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  processEmploymentData() {
    if (this.employment && this.employment.status && this.employment.status == 'Pending') {
      this.applicationTableElementDisplayStyle = 'none';
      this.applicationPendingSectionElementDisplayStyle = 'block';
    }

    this.showNoAccessState = false;
    console.log(this.employment.job, JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)))
    if (this.employment.job && JSON.parse(localStorage.getItem(CURRENT_EMPLOYER))) {
      if (this.employment.job.employerId && this.employment.job.employerId != JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)).id) {
        this.hideWhenData1ElementDisplayStyle = 'none';
        // this.hideWhenData2ElementDisplayStyle = 'none';
        // this.whenNoDataElementDisplayStyle = 'none';
        // this.detailHeaderElementDisplayStyle = 'none';
        this.showNoAccessState = true;
      } else {
        this.showNoAccessState = false;
        //this.detailHeaderElementDisplayStyle = 'block';
      }
    } else {
      // this.detailHeaderElementDisplayStyle = 'block';
    }
    let startTime = DateTime.local(this.employment.dates[0].date[0], this.employment.dates[0].date[1], this.employment.dates[0].date[2], this.employment.dates[0].timeFrom[0], this.employment.dates[0].timeFrom[1]);
    let endTime = DateTime.local(this.employment.dates[0].date[0], this.employment.dates[0].date[1], this.employment.dates[0].date[2], this.employment.dates[0].timeTo[0], this.employment.dates[0].timeTo[1]);
    console.log("start time : " + startTime);
    console.log("endTime time : " + endTime);
    this.shiftLength = endTime.diff(startTime, 'hours').hours < 0 ? endTime.diff(startTime, 'hours').hours + 24 : endTime.diff(startTime, 'hours').hours;
    if (this.employment.jobSeekerPaymentInfo)
      this.basePriceMale = this.employment.jobSeekerPaymentInfo.basePrice;
    if (this.employment.jobSeekerPaymentInfo_female)
      this.basePriceFemale = this.employment.jobSeekerPaymentInfo_female.basePrice;
  }

  /* async loadEmploymentData(id: string) {
     await this.employmentService.getById(id).toPromise()
       .then(async employment => {
         this.employment = employment;
         console.log(this.employment)
         if (this.employment && this.employment.status && this.employment.status == 'Pending') {
           document.getElementById('application-table').style.display = 'none';
           document.getElementById('application-pending-section').style.display = 'block';
         }
         // else {
         //   document.getElementById('application-table').style.display = 'block';
         //   document.getElementById('application-pending-section').style.display = 'none';
         // }
         // load employer
         await this.employerService.getById(this.employment.employerId).toPromise()
           .then(async data => {
             this.employment.employer = data;
           });
         // load job
         await this.jobService.getById(this.employment.jobId).toPromise()
           .then(async job => {
             this.employment.job = job;
             this.showNoAccessState = false;
             console.log(this.employment.job, JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)))
             if (this.employment.job && JSON.parse(localStorage.getItem(CURRENT_EMPLOYER))) {
               if (this.employment.job.employerId != JSON.parse(localStorage.getItem(CURRENT_EMPLOYER)).id) {
                 document.getElementById('hide-when-data1').style.display = 'none';
                 document.getElementById('hide-when-data2').style.display = 'none';
                 document.getElementById('when-no-data').style.display = 'none';
                 document.getElementById('detail-header').style.display = 'none';
                 this.showNoAccessState = true;
               }
             }
             console.log(this.showNoAccessState)
             await this.jobTypeService.getById(this.employment.job.jobTypeId).toPromise()
               .then(jobType => {
                 this.employment.job.jobType = jobType;
                 let startTime = DateTime.local(this.employment.dates[0].date[0], this.employment.dates[0].date[1], this.employment.dates[0].date[2], this.employment.dates[0].timeFrom[0], this.employment.dates[0].timeFrom[1]);
                 let endTime = DateTime.local(this.employment.dates[0].date[0], this.employment.dates[0].date[1], this.employment.dates[0].date[2], this.employment.dates[0].timeTo[0], this.employment.dates[0].timeTo[1]);
                 this.shiftLength = endTime.diff(startTime, 'hours').hours < 0 ? endTime.diff(startTime, 'hours').hours + 24 : endTime.diff(startTime, 'hours').hours;
                 console.log(this.employment.job.jobType.jobSeekerPrices)
                 this.basePriceMale = this.employment.jobSeekerPaymentInfo.basePrice;
                 this.basePriceFemale = this.employment.jobSeekerPaymentInfo_female.basePrice;
               });
           });
         await this.loadApplicationData();
 
       });
   } */

  sortChange(event) {
    this.sort.active = 'createdOn';
    this.sort.direction = event.value;
    this.dataChange.next(1);
  }

  // Pay % to activate live shift
  razorPayOptions = {
    "key": '',
    "amount": 0,
    "currency": '',
    "name": '',
    "discription": "hours4u payment",
    "order_id": '',
    "handler": (response) => {
      let paymentOrderRequest = new EmployementPaymentOrder();
      paymentOrderRequest.razorpay_order_id = response.razorpay_order_id;
      paymentOrderRequest.razorpay_payment_id = response.razorpay_payment_id;
      paymentOrderRequest.razorpay_signature = response.razorpay_signature;
      this.employeerPaymentOrderService.update(response.razorpay_order_id, paymentOrderRequest).toPromise().then(res => {
        this.employment.status = 'Active'
        this.employmentService.update(this.employment.id, this.employment).toPromise().then(emp => {
          this.notify.showMessage("Payment success and employment saved ", 2000);
          //this.loadEmploymentData(this.employment.id)
          this.loadApplicationData();
        })
      })
    }
  };

  payAmount() {
    this.isLoading = true;
    let femaleTotal = this.getTotalBasedOnGender('Female');
    let maleTotal = this.getTotalBasedOnGender('Male');

    let paymentOrderRequest = new EmployementPaymentOrder();
    paymentOrderRequest.amount = (femaleTotal + maleTotal) * (this.loginEmployerDetails.advanceAmountPercentage / 100);
    paymentOrderRequest.employer_id = this.employment.employerId;
    paymentOrderRequest.employment_id = this.employment.id;

    if (paymentOrderRequest.amount >= 0) {
      this.employeerPaymentOrderService.create(paymentOrderRequest).toPromise().then(
        (order: any) => {
          this.isLoading = false;
          if (order != null && order.status == 'SUCCESS') {
            this.razorPayOptions.amount = order.result.amount * 100;
            this.razorPayOptions.currency = 'INR';
            this.razorPayOptions.key = environment.rezorpayKey;
            this.razorPayOptions.order_id = order.result.razorPayOrderId;
            var rzp = new Razorpay(this.razorPayOptions);
            rzp.open()
            rzp.on('payment.failed', function (response) {
              alert(response.error.code);
              alert(response.error.description);
              alert(response.error.source);
              alert(response.error.step);
              alert(response.error.reason);
              alert(response.error.metadata.order_id);
              alert(response.error.metadata.payment_id);
            });
          } else {
            this.notify.showMessage("Something went wrong!", 3000);
          }
        }).catch(err => {
          this.isLoading = false;
          console.log(err)
        })
    } else {
      this.notify.showMessage("Please select valid rate", 3000);
      this.isLoading = false;
    }
  }

  getTimeBetween(inTime: any, outTime: any): any {
    if (isNaN(inTime) || isNaN(outTime) || (inTime > outTime)) {

      var hours_start_temp = parseInt(inTime[0], 10);
      var minutes_start_temp = inTime[1] ? parseInt(inTime[1], 10) : 0;
      var start_hrs: number = hours_start_temp + minutes_start_temp / 60;

      var hours_end_temp = parseInt(outTime[0], 10);
      var minutes_end_temp = inTime[1] ? parseInt(outTime[1], 10) : 0;
      var end_hrs: number = hours_end_temp + minutes_end_temp / 60;

      var timeDiff: any;

      if ((end_hrs - start_hrs) < 0)
        timeDiff = ((end_hrs - start_hrs) + 24).toFixed(2);
      else
        timeDiff = (end_hrs - start_hrs).toFixed(2);
      return timeDiff;
    }
  }

  getTotalBasedOnGender(gender) {
    let hourlyRate = 0;
    let totalAmount = 0;
    let basePay = 0;

    let tempHours = this.getTimeBetween(this.employment.dates[0].timeFrom, this.employment.dates[0].timeTo);
    this.employment.job.jobType.employerPrices.forEach(element => {
      if (element.gender == gender) {
        basePay = element.basePrice;
      }
    });
    this.employment.employerPaymentInfos.forEach(ele => {
      if (ele.gender == gender) {
        hourlyRate = ele.maxRate;
      }
    })
    if (gender == 'Female') {
      totalAmount += (basePay + (hourlyRate * tempHours)) * this.employment.noOfFemaleRequired;
    } else {
      totalAmount += (basePay + (hourlyRate * tempHours)) * this.employment.noOfMaleRequired;
    }
    return totalAmount;
  }

  // Copy link to clipboard
  copyLinkToClipboardForJobSeeker(employment) {
    console.log(employment)

    let copyUrl = null;
    this.copiedJobSeeker = true;
    this.copyTitleJobSeeker = 'Copied to your clipboard!';

    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://devapp.workorbits.com/#/available-job-details-global/' + employment.id
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://app.workorbits.com/#/available-job-details-global/' + employment.id
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedJobSeeker = false;
      this.copyTitleJobSeeker = 'Copy link to share Job Seeker';
    }, 7000);
  }

  copyLinkToClipboardForEmployer() {
    let copyUrl = null;
    this.copiedEmployer = true;
    this.copyTitleEmployer = 'Copied to your clipboard!';

    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://dev.workorbits.com' + this.router.url
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://workorbits.com' + this.router.url
    }
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedEmployer = false;
      this.copyTitleEmployer = 'Copy link to share Employer';
    }, 7000);
  }

  dataRefresh() {
    this.router.navigateByUrl('/employment/all/all');
  }

  openApproveDialog() {
    if (!this.approvePrevent) {
      //this.preference.name = this.jobSeeker.name;
      const dialogRef = this.dialog.open(ProfileApprovalDialogComponent, {
        width: '600px',
        disableClose: true,
        data: { preference: this.preference, jobSeeker: this.jobSeeker }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.jobSeeker.status = 'Active';
          this.reloadComponent();
        }
      });
    }
    else {
      this.dialog.open(ProfileApprovalPreventDialogComponent, {
        width: '600px',
        disableClose: true,
        data: this.jobSeeker.name
      });
    }
  }

  openRejectDialog() {
    const dialogRef = this.dialog.open(ProfileRejectDialogComponent, {
      width: '500px',
      disableClose: true,
      data: this.jobSeeker
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.jobSeeker.status = 'Rejected';
        this.reloadComponent();
      }
    });
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  imageErrorHandler(event) {
    if (this.jobSeeker.gender === 'Male') {
      event.target.src = './assets/img/avatars/male_avatar.svg';
    } else {
      event.target.src = './assets/img/avatars/female_avatar.svg';
    }
  }


  // Add Champ
  addChamps(object?: any): void {
    console.log(object)
    let data = null;
    data = Object.assign({}, object);
    const dialogRef = this.dialog.open(AddChampComponent, {
      width: '700px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadComponent();
      }
    });
  }
}
