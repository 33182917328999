import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mobileFormat'
})
export class MobileFormatPipe implements PipeTransform {

  transform(value: string): string {
    // Check if the value is valid
    if (!value) return '';

    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Format the mobile number as per Indian standard
    const formattedValue = numericValue.replace(/(\d{5})(\d{5})/, '+91 ' + '$1 $2');

    return formattedValue;
  }
}
