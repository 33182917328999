import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../@core/services/auth.service';
import { NotificationService } from '../../../@core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';


@Component({
  selector: 'hour4u-admin-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  emailAddress;
  token;

  form = this.fb.group({
    token: [null, Validators.required],
    emailAddress: [null],
    password: [null, Validators.required],
    mobile: [null]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.emailAddress = this.route.snapshot.queryParamMap.get('email');
    this.token = this.route.snapshot.queryParamMap.get('otp');
    if (this.emailAddress.includes('@')) {
      this.form.patchValue({ emailAddress: this.emailAddress });
    } else {
      this.form.patchValue({ mobile: this.emailAddress });
    }
    if (this.token) {
      this.form.patchValue({ token: this.token });
    }
  }

  onSubmit() {
    this.authService.verifyAndReset(this.form.value).subscribe(data => {
      this.notify.showMessage('Password successfully updated, Login Now ... ', 2000);
      this.router.navigateByUrl('/');
    }, error => {
      this.notify.showMessage(error.error.message, 3000);
    }, () => {
    });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}
