import { Component, OnInit, Input } from '@angular/core';
import { GiveRatingDialogComponent } from '../give-rating-dialog/give-rating-dialog.component';
import { MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { JobApplication } from 'src/@core/interfaces/job/job-application.model';
import { Dates, Employment } from 'src/@core/interfaces/employment/employment.model';
import { AttendanceService } from 'src/@core/services/job/attendance.service';
import { Search } from 'src/@core/interfaces/search';
import { Attendance } from 'src/@core/interfaces/job/attendance.model';
import { EmploymentHistoryService } from 'src/@core/services/employment/employment-history.service';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { DatePipe } from '@angular/common';
import { roles } from 'src/app/shared/helpers/roles';
import { MarkAttendanceDialogComponent } from '../mark-attendance-dialog/mark-attendance-dialog.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Component({
  selector: 'hour4u-admin-active-job-seeker-card',
  templateUrl: './active-job-seeker-card.component.html',
  styleUrls: ['./active-job-seeker-card.component.scss']
})
export class ActiveJobSeekerCardComponent implements OnInit {

  dataChange: BehaviorSubject<any>;
  @Input('jobApplication') jobApplication: JobApplication;
  @Input('employer') employer: Employer;
  @Input('employment') employmentData: Employment;
  @Input('date') date: Dates;
  showProgress = true;
  params: Search[] = [];
  attendance: Attendance = new Attendance();
  employment: Employment = new Employment();
  jobStatus = 'completed';
  roles = roles;
  baseImageUrl = environment.image_url;
  workImages: any[] = [];

  constructor(private dialog: MatDialog,
    private attendanceService: AttendanceService,
    private employmentHistoryServce: EmploymentHistoryService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getAttendance();
    this.loadHistory();
  }

  async loadHistory() {
    this.employment = new Employment();
    this.params = [];
    this.params.push(new Search('employmentId', this.jobApplication.employmentId));
    this.params.push(new Search('jobSeekerId', this.jobApplication.jobSeekerId));
    this.employmentHistoryServce.searchAndSort(0, 10000, null, null, this.params).toPromise()
      .then(data => {
        this.employment.history = data.content;
      }).finally(() => {
        this.employment.sliderImages = [];
        this.employment.history.forEach(history => {


          let historyDate = new Date(history.historyDate[0], history.historyDate[1] - 1, history.historyDate[2]);
          let newDate = new Date(this.date.date[0], this.date.date[1] - 1, this.date.date[2]);


          const date = moment(historyDate).format('MM-DD-YYYY');
          const date2 = moment(newDate).format('MM-DD-YYYY');
          console.log("date", date == date2)
          if ((date == date2) && (history.imageProofs != null)) {

            history.imageProofs.forEach((image: any) => {
              // image.totalTasks=0;
              // if(image.proofEnum == 'MIDDLE'){
              //   image.totalTasks= image.totalTasks+1;
              // }
              this.employment.sliderImages.push({
                image: this.baseImageUrl + image.imagePath,
                thumbImage: this.baseImageUrl + image.imagePath,
                title: image.proofEnum + ' | ' + (this.jobApplication.tempJobSeeker ? this.jobApplication.tempJobSeeker.name : '')
              });
            });
          }
          this.workImages = history.imageProofs.filter(ip => ip.proofEnum == 'MIDDLE');
        });
      });
  }

  getAttendance() {
    this.attendance = new Attendance();
    this.params = [];
    this.params.push(new Search('employmentId', this.jobApplication.employmentId));
    this.params.push(new Search('jobSeekerId', this.jobApplication.jobSeekerId));
    this.attendanceService.searchAndSort(0, 10000, null, null, this.params).subscribe(data => {
      data.content.forEach(a => {
        if (a.checkIn) {
          let checkInDate = a.checkIn[0] + "," + a.checkIn[1] + "," + a.checkIn[2];
          if (checkInDate == this.date.date) {
            this.attendance = a;
            console.log(this.attendance)
          }
        }
      });

      //this.attendance = data.content[0];

      if (!this.attendance) {
        this.showProgress = false;
        // check is job not started
        this.jobStatus = 'notstarted';
      } else {
        // check is job completed
        if (this.attendance.checkOut != null) {
          // show completed green box
          this.jobStatus = 'completed';
          this.showProgress = false;
        } else {
          // in progress
          this.jobStatus = 'inProgress';
        }
      }
    });
  }

  getProgress(timeFrom, timeTo) {
    if (!timeFrom || !timeTo) return;
    // parse from time
    let from = new Date();

    from.setHours(timeFrom[0]);
    from.setMinutes(timeFrom[1]);

    // parse to time
    let to = new Date();
    to.setHours(timeTo[0]);
    to.setMinutes(timeTo[1]);

    // calculate total job time in minutes
    let totalJobTime = Math.round(((to.getTime() - from.getTime()) / 1000) / 60);

    // calculate time till now in minutes
    let timeSpent = Math.round((((new Date()).getTime() - from.getTime()) / 1000) / 60);

    // calculate percentage
    return Math.round((timeSpent / totalJobTime) * 100);
  }

  openRatingDialog() {
    const dialogRef = this.dialog.open(GiveRatingDialogComponent, {

      width: '700px',
      disableClose: true,
      data: { jobApplication: this.jobApplication, employer: this.employer }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataChange.next(1);
      }
    });
  }

  openAttendance() {

    const dialogRef = this.dialog.open(MarkAttendanceDialogComponent, {
      width: '550px',
      disableClose: true,
      data: { attendance: this.attendance, jobApplication: this.jobApplication, employer: this.employer, employment: this.employmentData, date: this.date }
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result != 'Canceled') {
          this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
          this.router.navigated = false;
          this.router.navigate(['/employment/active/' + this.jobApplication.employmentId, {
            date: this.date.date
          }]);
        }
      }
    );
  }


  viewTasksOfEmployment() {
    this.router.navigate(['/employment/active/workproof/' + this.jobApplication.employmentId + '/' + this.jobApplication.jobSeekerId, {
      date: this.date.date
    }]);
  }

}
