import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_PROPERTY } from 'src/@core/services/util/api.endpoints';
import { roles } from 'src/app/shared/helpers/roles';
import { Lightbox, LightboxConfig, IAlbum } from 'ngx-lightbox'; // Import Lightbox and LightboxConfig
import { AppService } from 'src/@core/services/app.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BookSocietyDialogComponent } from '../book-society-dialog/book-society-dialog.component';
import { environment } from 'src/environments/environment';





@Component({
  selector: 'hour4u-admin-view-society',
  templateUrl: './view-society.component.html',
  styleUrls: ['./view-society.component.scss']
})
export class ViewSocietyComponent implements OnInit {
  societies: any[] = [];
  society: any;
  timeDifference: string = '';
  tiles: any[] = [];
  societyId: string;
  baseImageUrl = environment.image_url
  imageUrls: string[] = []; // property to store image URLs
  albums: IAlbum[] = [];

  roles = roles;
  currentUserRole: any = null;
  public userEmail: string = '';
  form: FormGroup;
  numberOfHours: any;
  bookingForm: FormGroup;
  copiedSocieties: { [key: string]: boolean } = {};
  copyTitleSociety: string = 'Share';
  bookedSocieties: any[] = [];





  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private lightbox: Lightbox, // Inject the Lightbox service
    private lightboxConfig: LightboxConfig, // Inject the LightboxConfig service
    private appService: AppService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.bookingForm = this.fb.group({
      numberOfHours: 4,
      bookingDate: ['']
    });
  }


  ngOnInit(): void {
    // Retrieve the society ID from the route parameter
    this.route.params.subscribe((params) => {
      this.societyId = params['id'];

      // Fetch the society details using the societyId
      this.http.get<any>(`${API_PROPERTY}/${this.societyId}`).subscribe(
        (society) => {
          this.society = society;
          console.log('society', society);
          this.calculateTimeDifference();

          // Now fetch the image URLs based on society data
          if (this.society.images && this.society.images.length > 0) {
            this.imageUrls = this.society.images.map(
              (imagePath) =>
                `${this.baseImageUrl}${imagePath}`
            );

            // Populate the albums array with valid data
            this.imageUrls.forEach((imageUrl) => {
              if (imageUrl) { // Check if imageUrl is defined
                const album: IAlbum = {
                  src: imageUrl, // Valid image URL
                  caption: '',   // Caption (you can set this if needed)
                  thumb: '',     // Thumbnail URL (you can set this if needed)
                };
                this.albums.push(album);
              }
            });
          }
        },
        (error) => {
          console.error('Failed to fetch society:', error);
        }
      );
    });

    // Configure the Lightbox
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.disableScrolling = false;
    this.lightboxConfig.fadeDuration = 0.5;
    this.userEmail = this.appService.getCurrentAccount().email;
    this.getCurrentRole();
  }
  // get current user role
  getCurrentRole() {
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })
  }

  // Function to open the lightbox
  openLightbox(index: number): void {
    this.lightbox.open(this.albums, index);
  }



  calculateTimeDifference() {
    if (this.society && this.society.availableSlots && this.society.availableSlots.length > 0) {
      const { timeFrom, timeTo } = this.society.availableSlots[0];
      if ((timeFrom && timeFrom.length) && (timeTo && timeTo.length)) {
        const hourDifference = timeTo[0] - timeFrom[0];
        const minuteDifference = timeTo[1] - timeFrom[1];

        this.timeDifference = `${hourDifference} h ${minuteDifference} m`;
      }
    } else {
      this.timeDifference = "N/A"; // Set a default value when data is not available
    }
  }


  editSociety(societyId: string) {
    // Navigate to the "add-edit-societies" route with the society ID in the route parameter
    this.router.navigate(['/societies/add-edit-societies', societyId]);
  }

  openBookSocietyDialog() {
    if (!this.bookingForm.get('bookingDate').value) {
      this.bookingForm.get('bookingDate').setErrors({ 'required': true });
      return;
    }


    const dialogRef = this.dialog.open(BookSocietyDialogComponent, {
      width: '488px',
      data: {
        society: this.society,
        numberOfHours: this.bookingForm.get('numberOfHours').value,
        bookingDate: this.bookingForm.get('bookingDate').value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  copySocietyLinkToClipboard(societyId: string) {
    let copyUrl = null;

    if (environment.api_url === 'https://devapi.hour4u.com') {
      copyUrl = `https://dev.workorbits.com/venues/view-venue/${societyId}`;
    }
    if (environment.api_url === 'https://api.hour4u.com') {
      copyUrl = `https://workorbits.com/venues/view-venue/${societyId}`;
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl || '';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // Update the local state to mark the society's link as copied
    this.copiedSocieties[societyId] = true;

    // Reset the button text after a specific duration (e.g., 3 seconds)
    setTimeout(() => {
      this.copiedSocieties[societyId] = false;
    }, 5000);
  }

}