// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  log: true,
  environmentName: 'local',
  version: 5.1,
  api_url: 'https://devapi.hour4u.com', // DEV url
  // api_url: 'https://uatapi.hour4u.com', // UAT url
  // api_url: 'https://api.hour4u.com', // PROD url
  image_url: 'https://image-data-hour4u-2.s3.ap-south-1.amazonaws.com/',
  /*firebase: {
    apiKey: 'AIzaSyAmFFqjM54rN8LccINRm7ME8Xa726MO81M',
    authDomain: 'hour4u-uat.firebaseapp.com',
    databaseURL: 'https://hour4u-uat.firebaseio.com',
    projectId: 'hour4u-uat',
    storageBucket: 'hour4u-uat.appspot.com',
    messagingSenderId: '1057720385634',
    appId: '1:1057720385634:web:76a6341500a6403d26113b',
    measurementId: 'G-ZQJGFM8NR3'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDKQka_oP1dDoGk7SlJr0XKals1ggO4Koo",
    authDomain: "hour4u-angular.firebaseapp.com",
    projectId: "hour4u-angular",
    storageBucket: "hour4u-angular.appspot.com",
    messagingSenderId: "334397445627",
    appId: "1:334397445627:web:3bc56bba06618391fc675e",
    measurementId: "G-5KR06HCKNN"
  },*/
  rezorpayKey: 'rzp_test_frWcC85VwYGHuv', // UAT KEY
  // rezorpayKey: 'rzp_live_0lOqy628mUvmnm', // PROD KEY
  contactSuppportNumber: "917397998705",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
