import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInUp400ms } from "src/@core/animations/fade-in-up.animation";
import { AuthService } from "src/@core/services/auth.service";
import { NotificationService } from "src/@core/services/notification.service";
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Employer } from "src/@core/interfaces/employer/employer.model";
import { EmployerService } from "src/@core/services/employer/employer.service";
import { roles } from 'src/app/shared/helpers/roles';
import { AccountService } from "src/@core/services/account.service";
import SignaturePad from "signature_pad";
import { MatDialog } from "@angular/material";
import { ConfirmSignatureComponent } from "../confirm-signature/confirm-signature.component";
// import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { FormGroup, FormArray } from '@angular/forms';
// import { PDFAnnotationData } from "pdfjs-dist";
import { FileHandle } from "src/app/shared/directives/dragDrop.directive";
import { svgToPng, Text2Sign } from "../auto-generate-signatureFromName";
import fonts from '../auto-generate-signatureFromName/fonts.json';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AppService, CURRENT_EMPLOYER } from "src/@core/services/app.service";
// import { DOCUMENT } from "@angular/common";
import { PDFDocument } from 'pdf-lib';

export class Input {
    name: string;
    type: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value; any;
    page?: number;
}

@Component({
    selector: 'hour4u-admin-employer-signup',
    templateUrl: './employer-signup.component.html',
    styleUrls: ['./employer-signup.component.scss'],
    animations: [fadeInUp400ms]
})


export class EmployerSignupComponent implements OnInit {
    isReloadButtonEnabled = false;
    form = this.fb.group({
        mobile: [null, Validators.required]
    });

    otpForm = this.fb.group({
        otp: [null, Validators.required]
    });

    empForm = this.fb.group({
        compName: [null, [Validators.required, Validators.minLength(2)]],
        compContactNumber: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
        compEmail: [null, [Validators.required, Validators.email]],
        compGST: [''],
        compAddr: [''],
        // compPwd: [null, Validators.required],
        accountId: [''],
        id: [''],
        profilePic: ['']
    });

    emailForm = this.fb.group({
        name: [null, Validators.required],
        email: [null, Validators.required],
        otp: [null, Validators.required],
    });

    pageStatus: string;
    empMobile: string;
    inputType = 'password';
    visible = false;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    gstInNumber: string = null;
    showGSTCompanyDetails: boolean = false;
    panCard: any = null;
    gstCertificate: any = null;
    viewPanCard: any = null;
    viewGstCertificate: any = null;

    @ViewChild("canvas", { static: true }) canvas: ElementRef;
    @ViewChild("canvas1", { static: true }) canvas1: ElementRef;
    @ViewChild('video', { static: true }) video;
    @ViewChild('firstInput', { static: false }) firstInput: ElementRef;

    sig: SignaturePad;
    selectedSignatureOption: any = null;
    uploadedSignature: any = null;
    showSavedSignature: boolean = false;
    signaturePersonName: string = null;
    pdfSrc: string = 'assets/sample.pdf';
    // screen DPI / PDF DPI
    readonly dpiRatio = 96 / 72;
    public myForm: FormGroup;
    showCaptureImg: boolean = false;
    signatureCapturedByCamera: boolean = false;

    files: FileHandle[] = [];
    videoElement: HTMLVideoElement;
    viewUploadedSignature: any = null;
    signatureList: any[] = [];
    signatureNameUpdate = new Subject<string>();
    selectedSignature: any = null;
    otpSent: boolean = false;
    isEmailFieldHide: boolean = false;
    isContactNumberFieldHide: boolean = false;
    emailOrPhone: string = null;
    otpVerificationForm: FormGroup;
    otpDigits: number[];
    isFirstInputVisible = false;
    isInitialButtonClick = true;

    constructor(private fb: FormBuilder,
        private notify: NotificationService,
        private authService: AuthService,
        private cd: ChangeDetectorRef,
        private employerService: EmployerService,
        private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private appService: AppService,
        private formBuilder: FormBuilder
    ) {
        this.myForm = this.fb.group({});

        // Update signature img on name change
        this.signatureNameUpdate.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(value => {
                if (value) {
                    const text2sign = new Text2Sign();
                    const text2sign1 = new Text2Sign();
                    this.signatureList = [];

                    text2sign.generateSignature(this.signaturePersonName, 50, { url: fonts.Satisfaction, name: 'Satisfaction' }, 'black');
                    const svgData = text2sign.getSVGdata();
                    svgToPng(svgData, (img) => {
                        this.signatureList.push(img)
                    })
                    text2sign1.generateSignature(this.signaturePersonName, 50, { url: fonts.Autography, name: 'Autography' }, 'black');
                    const svgData1 = text2sign1.getSVGdata();
                    svgToPng(svgData1, (img) => {
                        this.signatureList.push(img)
                    })
                } else {
                    this.signatureList = [];
                }
            });
        this.otpDigits = [0, 1, 2, 3, 4, 5]; // Initialize the array with six elements

        this.otpVerificationForm = this.formBuilder.group({
            digit0: ['', Validators.required],
            digit1: ['', Validators.required],
            digit2: ['', Validators.required],
            digit3: ['', Validators.required],
            digit4: ['', Validators.required],
            digit5: ['', Validators.required]
        });
    }
    // ngAfterViewInit() {
    //     const timer = setTimeout(() => {
    //         this.isReloadButtonEnabled = true;
    //         document.getElementById('reload-button').style.color = 'red';
    //     }, 30000);
    //     this.isReloadButtonEnabled = false;
    // }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.pageStatus = params['pageStatus'];
        });
        console.log('pageStatus ', this.pageStatus);
        // this.pageStatus = 'signAgreement';
        // this.loadPDF();

    }

    sendEmployerOtp(event) {

        event.preventDefault();
        event.stopPropagation();
        this.otpSent = true;
        const mobileNum = this.form.controls.mobile.value;
        this.empMobile = mobileNum;
        let type = null;
        this.form.controls['mobile'].setValidators([]);
        if (this.form.valid) {
            if (this.form.controls.mobile.value.includes('@')) {
                type = 'email';
                this.form.controls['mobile'].setValidators(Validators.email);
                this.form.controls['mobile'].updateValueAndValidity();
            } else {
                type = 'mobile';
                this.form.controls['mobile'].setValidators([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]);
                this.form.controls['mobile'].updateValueAndValidity();
            }

            if (this.form.valid) {
                this.authService.sendOtpLink(this.form.controls.mobile.value, type).subscribe((data: any) => {
                    this.notify.showMessage('OTP sent successfully!', 2000);
                    if (type == 'email') {
                        this.empForm.controls['compEmail'].setValue(this.form.controls['mobile'].value);
                        this.empForm.controls['compEmail'].updateValueAndValidity();
                        this.isEmailFieldHide = true;
                    } else {
                        this.empForm.controls['compContactNumber'].setValue(this.form.controls['mobile'].value);
                        this.empForm.controls['compContactNumber'].updateValueAndValidity();
                        this.isContactNumberFieldHide = true;
                    }
                    this.otpForm.reset();
                    this.pageStatus = 'confirm';
                    this.otpSent = false;
                    return;
                }, (error: any) => {
                    console.log(error)
                    this.otpForm.reset();
                    this.otpSent = false;
                    this.notify.showMessage('Error. Something went wrong!', 2000);
                });
            } else {
                this.otpSent = false;
                if (this.form.controls.mobile.value.includes('@')) {
                    this.notify.showMessage('Enter valid email!', 2000);
                } else {
                    this.notify.showMessage('Enter valid mobile number!', 2000);
                }
            }
        } else {
            this.otpSent = false;
            this.notify.showMessage('Enter valid details!', 2000);
        }

        // Set the focus on the first input field once it becomes visible
        this.checkFirstInputVisibility();
    }

    // confirmOtp() {
    //     const otp = this.otpForm.controls.otp.value;
    //     if (!otp) {
    //         return;
    //     } else {
    //         this.authService.generateToken(this.empMobile, otp).toPromise().then((data: any) => {
    //             this.notify.showMessage('OTP confirmed', 2000);
    //             this.setUserData(data.accountId);
    //             this.empForm.controls['accountId'].setValue(data.accountId);
    //             this.empForm.controls['accountId'].updateValueAndValidity();
    //         }).catch(err => {
    //             console.log(err)
    //             this.form.reset();
    //             this.otpForm.reset();
    //             this.notify.showMessage('OTP unconfirmed', 2000);
    //             return
    //         })
    //     }
    // }



    confirmOtp() {
        const otp = this.otpVerificationForm.value.digit0.toString() + this.otpVerificationForm.value.digit1.toString() + this.otpVerificationForm.value.digit2.toString() + this.otpVerificationForm.value.digit3.toString() + this.otpVerificationForm.value.digit4.toString() + this.otpVerificationForm.value.digit5.toString();
        console.log(typeof this.otpVerificationForm.value.digit0.toString())
        if (!otp) {
            return;
        } else {
            this.authService.generateToken(this.empMobile, otp)
                .toPromise()
                .then((data: any) => {
                    this.notify.showMessage('OTP confirmed', 2000);
                    this.setUserData(data.accountId);
                    this.empForm.controls['accountId'].setValue(data.accountId);
                    this.empForm.controls['accountId'].updateValueAndValidity();
                })
                .catch(err => {
                    console.log(err);
                    this.form.reset();
                    this.otpVerificationForm.reset();
                    this.notify.showMessage('OTP unconfirmed', 2000);
                    return;
                });
        }
    }


    // get user account by id data and set in storage
    async setUserData(accountId) {
        await this.accountService.getById(accountId).toPromise()
            .then(async data => {
                const user = this.appService.getCurrentAccount();
                user.user_details = data;
                // this.appService.setCurrentAccount(user);
                if (data.employerId != null) {
                    this.appService.setIsEmployerLogin(true);
                    await this.loadEmployerInfo(data.employerId);
                } else {
                    this.form.reset();
                    this.otpForm.reset();
                    this.notify.showMessage('This account is already registered with us with a different role. Please continue with another email/phone number.', 3000);
                    this.pageStatus = 'setup';
                }
            }).catch(err => {
                console.log(err)
                this.empForm.reset();
                this.form.reset();
            })
    }

    // get employer info by employer id and set in form
    async loadEmployerInfo(id: string) {
        let emp = await this.appService.getEmployer();
        if (emp && emp.id == id) {
            this.appService.setEmployer(emp);
            // this.pageStatus = 'gstDetails';
            this.router.navigateByUrl(this.appService.getRedirectURL());
        } else {
            await this.employerService.getById(id).toPromise()
                .then((employer: Employer) => {
                    if (employer) {
                        if (!employer.email || !employer.contactNo || !employer.name) {
                            this.pageStatus = 'identity';
                            this.empForm.controls['id'].setValue(employer.id);
                            this.empForm.controls['id'].updateValueAndValidity();
                            this.empForm.controls['compName'].setValue(employer.name);
                            this.empForm.controls['compName'].updateValueAndValidity();
                            this.empForm.controls['compEmail'].setValue(employer.email);
                            this.empForm.controls['compEmail'].updateValueAndValidity();
                            this.empForm.controls['compContactNumber'].setValue(employer.contactNo);
                            this.empForm.controls['compContactNumber'].updateValueAndValidity();
                            this.empForm.controls['compGST'].setValue(employer.gstNumber);
                            this.empForm.controls['compGST'].updateValueAndValidity();
                            this.empForm.controls['compAddr'].setValue(employer.address);
                            this.empForm.controls['compAddr'].updateValueAndValidity();
                            this.empForm.controls['profilePic'].setValue(employer.profilePic);
                            this.empForm.controls['profilePic'].updateValueAndValidity();

                            this.emailForm.controls['name'].setValue(employer.name);
                            this.emailForm.controls['name'].updateValueAndValidity();
                            this.emailForm.controls['email'].setValue(employer.email);
                            this.emailForm.controls['email'].updateValueAndValidity();
                        } else {
                            this.appService.setEmployer(employer);
                            this.router.navigateByUrl(this.appService.getRedirectURL());
                            //this.pageStatus = 'gstDetails';
                        }
                    }
                }).catch(error => {
                    this.notify.showMessage('Something went wrong with your details, try again after sometime...', 3000);
                    this.appService.logout();
                });
        }
    }

    createEmployer() {
        if (this.empForm.invalid) {
            this.notify.showMessage('Please enter valid details!', 2000);
            return;
        }
        else {
            const emp = new Employer();
            emp.contactNo = this.empForm.controls.compContactNumber.value;
            emp.name = this.empForm.controls.compName.value;
            emp.email = this.empForm.controls.compEmail.value;
            emp.gstNumber = this.empForm.controls.compGST.value;
            emp.address = this.empForm.controls.compAddr.value;
            emp.profilePic = this.empForm.controls.profilePic.value;
            emp.id = this.empForm.controls.id.value;
            emp.accountId = this.empForm.controls.accountId.value;
            // emp.password = this.empForm.controls.compPwd.value;

            if (emp.id) {
                this.employerService.updateEmployer(emp.id, emp).toPromise().then(
                    async (data) => {
                        await this.notify.showMessage('Employer created successfully!', 2000);
                        await this.appService.setEmployer(data);
                        await this.router.navigateByUrl(this.appService.getRedirectURL());
                        // this.pageStatus = 'gstDetails';
                        // this.gstInNumber = emp.gstNumber;
                    }
                ).catch((err) => {
                    this.notify.showMessage('Employer not created successfully!', 2000);
                    return;
                });
            } else {
                // this.employerService.createEmployer(emp).toPromise().then(
                //     async (data) => {
                //         this.notify.showMessage('Employer created successfully!', 2000);
                //         this.appService.setEmployer(data);
                //         this.router.navigate(['/dashboard']);
                //         // this.pageStatus = 'gstDetails';
                //         // this.gstInNumber = emp.gstNumber;
                //     }
                // ).catch((err) => {
                //     this.notify.showMessage('Employer not created successfully!', 2000);
                //     return;
                // });
            }
        }
    }

    // toggle pwd visibility
    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    // Fetch cmpany details from GSTIN number
    fetchCompanyData() {
        this.showGSTCompanyDetails = true;
    }

    // skip all pages and rediret to main screen
    skip() {

    }

    // go to doc uploads section
    goToDocumentUploadSection() {
        this.pageStatus = 'documentUpload';
    }

    // Submit pancard and gst certificate img and redirect to agreement sign instruction page
    submitDocuments() {
        this.pageStatus = 'agreementSignIn';
    }

    // go to sign agreement page
    signAgreement() {
        this.pageStatus = 'signAgreement';
        this.loadPDF();
    }

    // enter email and get otp screen
    emailEnterScreen() {
        this.pageStatus = 'emailScreen';
    }

    // send email & draw signatuire screen
    sendEmail() {
        this.pageStatus = 'signaturePage';
        this.selectSignatureOption('drawSignature');
        this.signaturePersonName = this.emailForm.value.name;
        this.signatureNameUpdate.next(this.signaturePersonName);
    }

    // selected signature capture option
    selectSignatureOption(option) {
        this.selectedSignatureOption = option;
        this.showSavedSignature = false;
        if (option == 'drawSignature') {
            this.sig = new SignaturePad(this.canvas.nativeElement);
        }
        if (option == 'signatureSelect') {

        }
        if (option == 'signatureUpload') {

        }
    }

    // Clear all capture and uploaded doc field
    clearSignature() {
        this.sig ? this.sig.clear() : '';
        this.files = [];
        this.signatureCapturedByCamera = false;
        this.showCaptureImg = false;
        this.viewUploadedSignature = null;
        this.showSavedSignature = false;
    }

    // save canvas draw signature
    saveSignature() {
        this.showSavedSignature = true;
    }

    // after adding signature click on next
    confirmSignature() {
        const dialogRef = this.dialog.open(ConfirmSignatureComponent, {
            disableClose: true,
            panelClass: 'confirm-signarure-dialog',
            position: { bottom: '14%' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result == 'success') {
                    this.pageStatus = 'successPage';
                } else {
                    this.pageStatus = 'errorPage';
                }
            }
        });
    }

    // accept terms and conditions for signature accept
    okayClick() {
        this.router.navigate(['/']);
    }

    // reject terms and conditions for signature accept
    cancelClick() {
        this.pageStatus = 'signaturePage';
    }

    // selected files for pancard , gst and signature
    selectedFile(event, field) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (field == 'panCard') {
                this.viewPanCard = reader.result;
            }
            if (field == 'gstCertificate') {
                this.viewGstCertificate = reader.result;
            }
            if (field == 'signature') {
                this.viewUploadedSignature = reader.result;
            }
        };
    }

    // file drag&drop file obj get
    filesDropped(files: FileHandle[]): void {
        this.files = files;
    }

    // capture signature with camera
    takePicture() {
        const canvasElement = this.canvas1.nativeElement;
        const context = canvasElement.getContext('2d');
        context.drawImage(this.videoElement, 0, 0, canvasElement.width, canvasElement.height);
        this.signatureCapturedByCamera = true;
    }

    // clicked img by camera
    captureImage() {
        this.showCaptureImg = true;
        this.videoElement = this.video.nativeElement;
        navigator.mediaDevices
            .getUserMedia({
                video: { facingMode: 'environment' },
            })
            .then(stream => {
                this.videoElement.srcObject = stream;
            });
    }

    // Selected signature by name
    selectedSignatureChange(event) {
        console.log(event)
    }

    pdfDoc = null;
    currentPage = 1;
    totalPages = 0;
    scale = .9
    forms = new Map()

    // load pdf
    async loadPDF() {
        // const SEARCH_FOR = "";

        // // @ts-ignore
        // pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';

        // // @ts-ignore
        // const eventBus = new pdfjsViewer.EventBus()

        // // @ts-ignore
        // const pdfLinkService = new pdfjsViewer.PDFLinkService({
        //     eventBus,
        // });

        // // @ts-ignore
        // const pdfFindController = new pdfjsViewer.PDFFindController({
        //     eventBus,
        //     linkService: pdfLinkService,
        // });

        // // @ts-ignore
        // const pdfScriptingManager = new pdfjsViewer.PDFScriptingManager({
        //     eventBus,
        //     sandboxBundleSrc: 'assets/pdf/pdf.sandbox.js',
        // });

        // (async function () {
        //     // ussing pdf-lib a diffrent library to fill the form fields
        //     const formPdfBytes = await fetch('assets/pdf/sample2.pdf').then(res => res.arrayBuffer())
        //     const emblemImageBytes = await fetch('assets/pdf/Signature.png').then(res => res.arrayBuffer())
        //     // const emblemImageBytes1 = await fetch('assets/H4U-Symbol.png').then(res => res.arrayBuffer())
        //     const pdfDocLIB = await PDFDocument.load(formPdfBytes)
        //     console.log(pdfDocLIB);
        //     const data = await pdfDocLIB.getForm().getField('the Effective Date by and between')
        //     // @ts-ignore

        //     // await pdfDocLIB.getForm().getButton('Signature-Image-4_af_image')
        //     //     .setImage(await pdfDocLIB.embedJpg(emblemImageBytes1))
        //     await pdfDocLIB.getForm().getButton('Signature-Image-1_af_image')
        //         .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        //     await pdfDocLIB.getForm().getButton('Signature-Image-2_af_image')
        //         .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        //     await pdfDocLIB.getForm().getButton('Signature-Image-3_af_image')
        //         .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        //     const pdfDocBytes = await pdfDocLIB.saveAsBase64({ dataUri: true })
        //     // @ts-ignore

        //     const loadingTask = pdfjsLib.getDocument({
        //         url: pdfDocBytes,
        //         cMapUrl: '../assets/cmaps/',
        //         cMapPacked: true,
        //         enableXfa: true,
        //     });

        //     loadingTask.promise.then(async function (pdfDocument) {
        //         const page = await pdfDocument.getPage(1);
        //         const annotations = await page.getAnnotations();
        //         const container = document.getElementById("viewerContainer");

        //         // @ts-ignore
        //         const pdfViewer = new pdfjsViewer.PDFViewer({
        //             container,
        //             eventBus,
        //             linkService: pdfLinkService,
        //             findController: pdfFindController,
        //             scriptingManager: pdfScriptingManager,
        //             toolbar: {
        //                 numPages: {
        //                     pageNumber: {
        //                         type: "pageNumber",
        //                         maxDigits: 4,
        //                     },
        //                 }
        //             }
        //         });
        //         pdfLinkService.setViewer(pdfViewer);
        //         pdfScriptingManager.setViewer(pdfViewer);

        //         eventBus.on("pagesinit", function () {
        //             // We can use pdfViewer now, e.g. let's change default scale.
        //             pdfViewer.currentScaleValue = "page-width";
        //             // We can try searching for things.
        //             if (SEARCH_FOR) {
        //                 eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
        //             }
        //         });
        //         // Document loaded, specifying document for the viewer and
        //         // the (optional) linkService.
        //         pdfViewer.setDocument(pdfDocument);

        //         pdfLinkService.setDocument(pdfDocument, null);
        //     })
        // })();




















        const employer = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
        console.log(employer)

        // this.emailForm.controls['name'].setValue(employer.name);
        // this.emailForm.controls['name'].updateValueAndValidity();
        // this.emailForm.controls['email'].setValue(employer.email);
        // this.emailForm.controls['email'].updateValueAndValidity();

        // @ts-ignore
        // pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.js';
        // // @ts-ignore
        // const loadingTask = pdfjsLib.getDocument({
        //     url: 'assets/sample.pdf',
        //     cMapUrl: 'assets/cmaps/',
        //     cMapPacked: true,
        // });

        // ussing pdf-lib a diffrent library to fill the form fields
        const formPdfBytes = await fetch('assets/pdf/sample2.pdf').then(res => res.arrayBuffer());
        // const emblemImageBytes = await fetch('https://image-data-hour4u-2.s3.ap-south-1.amazonaws.com/uat/job-seeker/6443b572da6fb43a86b21f40/profile.jpg').then(res => res.arrayBuffer());
        const emblemImageBytes = await fetch('assets/logo-workorbits.svg').then(res => res.arrayBuffer());
        // // const emblemImageBytes1 = await fetch('../assets/hypersign.jpg').then(res => res.arrayBuffer());

        const pdfDocLIB = await PDFDocument.load(formPdfBytes);

        console.log(pdfDocLIB);


        const data = await pdfDocLIB.getForm().getField('the Effective Date by and between')
        // @ts-ignore


        // await pdfDocLIB.getForm().getButton('Signature-Image-4_af_image')
        //     .setImage(await pdfDocLIB.embedJpg(emblemImageBytes1))
        await pdfDocLIB.getForm().getButton('Signature-Image-1_af_image')
            .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        await pdfDocLIB.getForm().getButton('Signature-Image-2_af_image')
            .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        await pdfDocLIB.getForm().getButton('Signature-Image-3_af_image')
            .setImage(await pdfDocLIB.embedPng(emblemImageBytes))
        const pdfDocBytes = await pdfDocLIB.saveAsBase64({ dataUri: true })
        // @ts-ignore

        pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf/pdf.worker.js';

        // @ts-ignore
        const loadingTask = pdfjsLib.getDocument({
            // url: 'assets/pdf/sample.pdf',
            url: pdfDocBytes,
            cMapUrl: 'assets/cmaps/',
            cMapPacked: true,
            enableXfa: true,
        });

        const SEARCH_FOR = "";
        console.log(document.getElementById("viewerContainer"))

        // loadingTask.promise.then(async (pdf) => {
        //     console.log('PDF loaded');
        //     this.pdfDoc = pdf;
        //     const page = await this.pdfDoc.getPage(1)
        //     const annotationData = await page.getAnnotations()
        //     annotationData[0].fieldValue = "2nd"
        //     annotationData[1].fieldValue = "RANDOM DATA"
        //     annotationData[2].fieldValue = "23"
        //     annotationData[3].fieldValue = "Random ONE"
        //     annotationData[4].fieldValue = "Random TWO"


        //     // const container = document.getElementById('pdfContainer')

        //     // container.setAttribute('class', 'pdfViewer singlePageView')

        //     // @ts-ignore
        //     const eventBus = new pdfjsViewer.EventBus()
        //     // @ts-ignore
        //     // const pdfPageView = new pdfjsViewer.PDFPageView({
        //     //     container,
        //     //     id: 1,
        //     //     scale: 1,
        //     //     // @ts-ignore
        //     //     defaultViewport: page.getViewport({ scale: 1 }),
        //     //     eventBus,
        //     //     // @ts-ignore

        //     //     textLayerFactory: !this.pdfDoc.isPureXfa
        //     //         // @ts-ignore

        //     //         ? new pdfjsViewer.DefaultTextLayerFactory()
        //     //         : null,
        //     //     // @ts-ignore
        //     //     annotationLayerFactory: new pdfjsViewer.DefaultAnnotationLayerFactory(),
        //     //     // @ts-ignore
        //     //     xfaLayerFactory: this.pdfDoc.isPureXfa
        //     //         // @ts-ignore
        //     //         ? new pdfjsViewer.DefaultXfaLayerFactory()
        //     //         : null,
        //     //     // @ts-ignore

        //     //     renderInteractiveForms: true
        //     // })
        //     // pdfPageView.setPdfPage(page);

        //     // pdfPageView.draw();
        // });

        // @ts-ignore
        const eventBus = new pdfjsViewer.EventBus()

        // @ts-ignore
        const pdfLinkService = new pdfjsViewer.PDFLinkService({
            eventBus,
        });

        // @ts-ignore
        const pdfFindController = new pdfjsViewer.PDFFindController({
            eventBus,
            linkService: pdfLinkService,
        });

        // @ts-ignore
        const pdfScriptingManager = new pdfjsViewer.PDFScriptingManager({
            eventBus,
            sandboxBundleSrc: 'assets/pdf/pdf.sandbox.js',
        });

        (function () {
            loadingTask.promise.then(async (pdfDocument) => {
                const page = await pdfDocument.getPage(1)
                const annotationData = await page.getAnnotations()
                annotationData[0].fieldValue = "2nd"
                annotationData[1].fieldValue = "RANDOM DATA"
                // annotationData[2].fieldValue = "23"
                // annotationData[3].fieldValue = "Random ONE"
                // annotationData[4].fieldValue = employer.email

                const container = document.getElementById("viewerContainer");


                // @ts-ignore
                const pdfViewer = new pdfjsViewer.PDFViewer({
                    container,
                    eventBus,
                    linkService: pdfLinkService,
                    findController: pdfFindController,
                    scriptingManager: pdfScriptingManager,
                    toolbar: {
                        numPages: {
                            pageNumber: {
                                type: "pageNumber",
                                maxDigits: 4,
                            },
                        }

                    }
                });
                pdfLinkService.setViewer(pdfViewer);
                pdfScriptingManager.setViewer(pdfViewer);

                eventBus.on("pagesinit", function () {
                    // We can use pdfViewer now, e.g. let's change default scale.
                    pdfViewer.currentScaleValue = "page-width";

                    // We can try searching for things.
                    if (SEARCH_FOR) {
                        eventBus.dispatch("find", { type: "", query: SEARCH_FOR });
                    }
                });
                // Document loaded, specifying document for the viewer and
                // the (optional) linkService.
                pdfViewer.setDocument(pdfDocument);

                pdfLinkService.setDocument(pdfDocument, null);
            })
        })();

    }

    // next btn click
    nextBtnClick() {
        this.currentPage += 1;
        this.currentPage =
            this.currentPage > this.totalPages ? 1 : this.currentPage;
        this.loadPDF();
    }

    prevBtnClick() {
        this.currentPage -= 1;
        this.currentPage =
            this.currentPage < 1 ? this.totalPages : this.currentPage;
        this.loadPDF();
    }
    convertEmailToLowercase(): void {
        const emailControl = this.form.controls['mobile'];
        if (emailControl.value && emailControl.value.includes('@')) {
            const lowercaseEmail = emailControl.value.toLowerCase();
            emailControl.setValue(lowercaseEmail);
        }
    }
    onEnterKeyPressed(event: any): void {
        this.convertEmailToLowercase();
        this.sendEmployerOtp(event);
    }



    //Ritambuj

    onOtpKeydown(event: KeyboardEvent, index: number) {
        // Move focus to the next input box on right arrow key press
        if (event.key === 'ArrowRight' && index < this.otpDigits.length - 1) {
            const nextInput = document.getElementById('digit' + (index + 1));
            if (nextInput) {
                nextInput.focus();
            }
        }

        // Move focus to the previous input box on left arrow key press
        if (event.key === 'ArrowLeft' && index > 0) {
            const prevInput = document.getElementById('digit' + (index - 1));
            if (prevInput) {
                prevInput.focus();
            }
        }

        // Confirm OTP on enter key press
        if (event.key === 'Enter' && index === this.otpDigits.length - 1) {
            this.confirmOtp();
        }

        // Move focus to the previous input box on backspace key press
        if (event.key === 'Backspace' && index > 0) {
            const currentInput = document.getElementById('digit' + index) as HTMLInputElement;
            if (currentInput && currentInput.value === '') {
                const prevInput = document.getElementById('digit' + (index - 1));
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    }


    onOtpInput(event: Event, index: number) {
        const input = event.target as HTMLInputElement;
        const inputValue = input.value;

        // Update the value in the corresponding form control
        this.otpVerificationForm.controls['digit' + index].setValue(inputValue);

        // Check if all the inputs are filled
        const allInputsFilled = this.otpDigits.every((digit) => {
            return this.otpVerificationForm.controls['digit' + digit].value !== '';
        });

        // Enable the Next button only when all 6 digits are filled
        this.isFirstInputVisible = allInputsFilled;

        // Move focus to the next input box if the input value length is equal to 1
        if (inputValue.length === 1 && index < this.otpDigits.length - 1) {
            const nextInput = document.getElementById('digit' + (index + 1));
            if (nextInput) {
                nextInput.focus();
            }
        }

        // Move focus to the previous input box on backspace key press
        if (event instanceof KeyboardEvent && event.key === 'Backspace' && inputValue.length === 0 && index > 0) {
            const prevInput = document.getElementById('digit' + (index - 1));
            if (prevInput) {
                prevInput.focus();
            }
        }
    }




    onOtpPaste(event: ClipboardEvent, index: number) {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');

        // Extract individual digits from the pasted text
        const digits = pastedText.split('');

        // Update the values in the corresponding form controls
        for (let i = 0; i < digits.length && i < this.otpDigits.length; i++) {
            this.otpVerificationForm.controls['digit' + (index + i)].setValue(digits[i]);
        }

        // Move focus to the next input box if all digits are pasted
        if (digits.length > 0 && index + digits.length < this.otpDigits.length) {
            const nextInput = document.getElementById('digit' + (index + digits.length));
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
    onBackPress() {
        this.pageStatus = 'setup';
        this.otpVerificationForm.reset();
    }
    checkFirstInputVisibility() {
        // Use a timer to check if the first input field is visible every 100 milliseconds
        const interval = setInterval(() => {
            const firstInputEl = this.firstInput.nativeElement;
            if (firstInputEl && firstInputEl.offsetParent !== null) {
                // The first input field is visible
                firstInputEl.focus();
                clearInterval(interval); // Stop checking for visibility
            }
            document.getElementById('reload-button').style.opacity = '0.5';
            const timer = setTimeout(() => {
                this.isReloadButtonEnabled = true;
                document.getElementById('reload-button').style.opacity = '1';
            }, 30000);
            this.isReloadButtonEnabled = false;
        }, 100);
    }



}  
