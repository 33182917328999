import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@core/animations/stagger.animation';
import { PaymentAttendance } from 'src/@core/interfaces/payment/payment-attendance.model';
import { Search } from 'src/@core/interfaces/search';
import { AppService } from 'src/@core/services/app.service';
import { BehaviorSubject, merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { Payment } from 'src/@core/interfaces/payment/payment.model';
import { Attendance } from 'src/@core/interfaces/job/attendance.model';
import { Rating } from 'src/@core/interfaces/rating/rating.model';
import { JobSeekerRatingService } from 'src/@core/services/rating/job-seeker-rating.service';
import { AttendanceService } from 'src/@core/services/job/attendance.service';
import { JobService } from 'src/@core/services/job/job.service';
import { FormatDatePipe } from 'src/@core/pipes/formatDate.pipe';
import { ParseTimePipe } from 'src/@core/pipes/parseTime.pipe';
import { DatePipe } from '@angular/common';
import { JobSeekerPipe } from 'src/@core/pipes/data/jobseeker.pipe';
import { JobSeekerService } from 'src/@core/services/job-seeker/job-seeker.service';
import { WorkLogService } from 'src/@core/services/employment/worklog.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ProgressBarService } from 'src/@core/services/progress-bar.service';
import { Job } from 'src/@core/interfaces/job/job.model';
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { environment } from 'src/environments/environment';
import { roles } from 'src/app/shared/helpers/roles';
import { STATUS } from 'src/app/shared/helpers/status';
import { JobApplicationService } from 'src/@core/services/job/job-application.service';
import { PaymentService } from 'src/@core/services/payment/payment.service';
import { PaymentStatusDialogComponent } from 'src/app/modules/payments/payment-status-dialog/payment-status-dialog.component';

@Component({
  selector: 'hour4u-admin-show-work-logs',
  templateUrl: './show-work-logs.component.html',
  styleUrls: ['./show-work-logs.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    fadeInRight400ms],
  providers: [FormatDatePipe, JobSeekerPipe, ParseTimePipe]
})
export class ShowWorkLogsComponent implements OnInit {

  columns1 = [
    { columnDef: 'userName', visible: true, label: 'Job Seeker' },
    { columnDef: 'checkIn', visible: true, label: 'Login Time' },
    { columnDef: 'checkOut', visible: true, label: 'Logout Time' },
    { columnDef: 'hoursWorked', visible: true, label: 'Hours Worked' },
    { columnDef: 'agreedAmount', visible: true, label: 'Agreed amount' },
    { columnDef: 'payableAmount', visible: true, label: 'Chargeable amount' },
    { columnDef: 'remarks', visible: true, label: 'Remarks' },
  ];
  displayedColumns1 = this.columns1.map(x => x.columnDef);

  currentUserRole: any;
  public mtformgroup: FormGroup;
  public mtformarray: FormArray;
  employmentId: string;
  employmentTitle: string;
  employerId: string;
  jobTitle: string;
  date: any;
  searchParams: Search[] = [];
  searchFilters: Search[] = [];
  dataSource: MatTableDataSource<PaymentAttendance> | null;
  dataSourcePayment: MatTableDataSource<Payment> | null;
  dataSourceAttendance: MatTableDataSource<Attendance> | null;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataChange: BehaviorSubject<any>;

  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  resultsLength = 0;
  // editableWorkLog: boolean = false;
  workLogList: any;
  isLoading: boolean = false;

  mode = 'indeterminate';
  diameter = 30;
  color: string;
  checkIndate = new Date();
  checkOutdate = new Date();
  job: any = null;
  copyTitleEmployer: string = 'Copy Link to Share';
  copiedEmployer: boolean = false;
  status = STATUS;
  roles = roles;
  approvedTotal = 0;
  showExpand: boolean = false;
  level = '';
  starList: boolean[] = [true, true, true, true, true];
  actionList: any[] = ['Mark as paid', 'Settled in cash', 'Edit approval'];
  worklogChange: boolean = false;
  backupForWorkLog: any[] = [];
  changedRowIndex: number[] = [];

  constructor(
    private appService: AppService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private workLogService: WorkLogService,
    private employmentService: EmploymentService,
    private router: Router,
    private jobService: JobService,
    private paymentService: PaymentService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.employmentId = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      this.jobTitle = params['jobTitle'];
      this.employmentTitle = params['employmentTitle'];
      this.employerId = params['employerId'];
      this.date = params['date'];
      this.loadData();
    });
    this.appService.currentUser.subscribe(currentUser => {
      currentUser.roles.forEach(ele => {
        this.currentUserRole = ele;
      })
    })

    this.mtformarray = this.fb.array([this.getFormGropArray()]);
    this.mtformgroup = this.fb.group({
      formarray: this.mtformarray
    });

    this.getAllWorkLogs();
  }

  async loadData() {
    await this.employmentService.getById(this.employmentId).toPromise()
      .then((job: any) => {
        this.job = job;
        this.loadJobs();
      }).finally(async () => {

      })
  }


  // Get job from server
  loadJobs() {
    this.jobService.getById(this.job.jobId).subscribe(job => {
      this.job.job = job;
    });
  }

  /**
   * Get employment rates
   */
  getRates(gender) {
    let val = 0;
    if (this.job && this.job.employerPaymentInfos && this.job.employerPaymentInfos.length) {
      this.job.employerPaymentInfos.forEach(x => {
        if (x.gender == gender) {
          if (this.job.flatRate) {
            val = x.flatRate;
          } else {
            val = x.maxRate;
          }
        }
      });
    }
    return val;
  }

  // set form array in table
  getFormGropArray(newRow = false, editRow?: boolean, starList?: any[]) {
    let status = null;
    if (newRow) {
      status = 'CREATED';
    }
    let f = new FormGroup({
      id: new FormControl(null),
      status: new FormControl(status),
      paymentRequestId: new FormControl(null),
      userName: new FormControl(null),
      checkIn: new FormControl(null),
      checkOut: new FormControl(null),
      agreedAmount: new FormControl(null),
      payableAmount: new FormControl(null),
      remarks: new FormControl(null),
      hoursWorked: new FormControl(null),
      newRow: new FormControl(newRow),
      editRow: new FormControl(editRow),
      rating: new FormControl(0),
      hourlyRate: new FormControl(0),
      basePrice: new FormControl(0),
      superAdminRemarksForChamp: new FormControl(null),
      superAdminCommentsForClient: new FormControl(null),
      superAdminCommentsForJobAdmin: new FormControl(null),
      champRemarks: new FormControl(null),
      champSettledAmount: new FormControl(null),
      champExpectedAmount: new FormControl(null),
      champAgreedAmount: new FormControl(null),
      jobAdminCommission: new FormControl(null),
      jobAdminRemarks: new FormControl(null),
      jobAdminSettledAmount: new FormControl(null),
      jobSuperVisorCommission: new FormControl(null),
      jobSuperVisorRemarks: new FormControl(null),
      jobSupervisorSettledAmount: new FormControl(null),
      jobSeekerId: new FormControl(null),
      starList: new FormControl(starList),
      paymentStatus: new FormControl(null)
    });
    return f;
  }

  // get all work logs
  getAllWorkLogs() {
    this.isLoading = true;
    // if (document.getElementById('application-pending-section')) {
    //   document.getElementById('application-pending-section').style.display = 'none';
    // }
    this.workLogList = null;
    this.workLogService.getWorkLogList(this.employmentId).then((res: any) => {
      this.isLoading = false;
      res.workLogItem.forEach(element => {
        if (!element.rating) {
          element.starList = [];
          element.rating = null;
          this.starList.forEach(ele => {
            console.log(ele)
            let obj = Object.assign({}, ele);
            element.starList.push(obj)
          })
        }
      });
      this.workLogList = res;
      console.log("this.workLogList---", this.workLogList)
      this.approvedTotal = this.workLogList.workLogItem.length;
      if (this.workLogList.workLogItem && this.workLogList.workLogItem.length == 0) {
        // if (document.getElementById('application-pending-section')) {
        //   document.getElementById('application-pending-section').style.display = 'block';
        // }
      }
      this.setFormArray();
    }).catch((err: any) => {
      this.isLoading = false;
      console.log(err);
    })

  }

  // add new row
  addNewRow() {
    this.mtformarray = this.mtformgroup.get('formarray') as FormArray;
    let starList = [];
    this.starList.forEach(ele => {
      console.log(ele)
      let obj = Object.assign({}, ele);
      starList.push(obj)
    })
    this.mtformarray.push(this.getFormGropArray(true, true, starList));
    // if (this.mtformarray.controls.length) {
    //   document.getElementById('application-pending-section').style.display = 'none';
    // }
  }

  // set value in formarray
  async setFormArray(isEdit?: boolean, index?: number) {
    this.mtformarray = this.mtformgroup.get('formarray') as FormArray;
    this.mtformarray.clear();
    this.workLogList.workLogItem.forEach((ele: any, i: number) => {
      console.log("ele.champSettledAmount", ele.champSettledAmount)
      this.mtformarray.push(this.fb.group({
        id: [ele.id],
        status: [ele.status],
        paymentRequestId: [ele.paymentRequestId],
        userName: [ele.userName],
        checkIn: [ele.checkIn],
        checkOut: [ele.checkOut],
        hoursWorked: [ele.hoursWorked],
        agreedAmount: [ele.agreedAmount],
        payableAmount: [ele.payableAmount],
        remarks: [ele.remarks],
        newRow: [false],
        editRow: [(ele.status != 'APPROVED' && isEdit && index == i) ? true : false],
        rating: [ele.rating],
        basePrice: [ele.basePrice],
        hourlyRate: [ele.hourlyRate],
        superAdminRemarksForChamp: [ele.superAdminRemarksForChamp],
        superAdminCommentsForClient: [ele.superAdminCommentsForClient],
        superAdminCommentsForJobAdmin: [ele.superAdminCommentsForJobAdmin],
        champRemarks: [ele.champRemarks],
        champSettledAmount: [(ele.champSettledAmount ? ele.champSettledAmount : (ele.champExpectedAmount ? null : (ele.champAgreedAmount ? ele.champAgreedAmount : 0)))],
        champExpectedAmount: [ele.champExpectedAmount],
        champAgreedAmount: [ele.champAgreedAmount],
        jobAdminCommission: [ele.jobAdminCommission],
        jobAdminRemarks: [ele.jobAdminRemarks],
        jobAdminSettledAmount: [(ele.jobAdminSettledAmount ? ele.jobAdminSettledAmount : (ele.champExpectedAmount ? null : ele.champAgreedAmount))],
        jobSuperVisorCommission: [ele.jobSuperVisorCommission],
        jobSuperVisorRemarks: [ele.jobSuperVisorRemarks],
        jobSupervisorSettledAmount: [ele.jobSupervisorSettledAmount],
        jobSeekerId: [ele.jobSeekerId],
        starList: [ele.starList],
        paymentStatus: [ele.paymentStatus]
      }))
    })
    this.backupForWorkLog = Object.assign([], this.mtformarray.value);
    let self = this;
    self.worklogChange = false;
    this.mtformgroup.get('formarray').valueChanges.subscribe(changes => {
      self.worklogChange = true;
      self.changedRowIndex = [];
      self.backupForWorkLog.forEach((ele, i) => {
        self.mtformarray.value.forEach((element, index) => {
          if (ele.id == element.id) {
            if (JSON.stringify(ele) == JSON.stringify(element)) {
            } else {
              console.log(i, index)
              self.changedRowIndex.push(i);
            }
          }
        });
      })
    })
  }

  // click on edit row
  editRow(row, index) {
    row.controls['editRow'].setValue(true);
    row.controls['editRow'].updateValueAndValidity();
  }

  // delete record
  deleteRecord(row, itemIndex) {
    this.mtformarray = this.mtformgroup.get('formarray') as FormArray;
    this.mtformarray.removeAt(itemIndex);
    this.checkIndate = new Date();

    this.checkOutdate = new Date();
    // if (!this.mtformarray.controls.length) {
    //   document.getElementById('application-pending-section').style.display = 'block';
    // }
  }

  // get total champs count
  getTotalChamps() {
    let totalChamps = 0;
    totalChamps = this.mtformarray.value.length;
    return totalChamps;
  }

  // get Total Hours Duration 
  getTotalDuration() {
    let duration = 0;
    if (this.mtformarray.value && this.mtformarray.value.length != 0) {
      this.mtformarray.value.forEach((ele: any) => {
        duration = Number(duration) + Number(ele.hoursWorked);
      })
    }
    return duration;
  }

  // get all chargeable amount
  getChargeableAmount() {
    let chargeableAmount = 0;
    this.mtformarray.controls.forEach((ele: any) => {
      if (ele.value.payableAmount) {
        chargeableAmount = Number(chargeableAmount) + Number(ele.value.payableAmount);
      }
    })
    if (this.workLogList) {
      chargeableAmount = (Number(chargeableAmount) * 1.18);
    }
    return chargeableAmount;
  }

  // get Payment To Champs
  getPaymentToChamps() {
    let paymentToChamps = 0;
    this.mtformarray.controls.forEach((ele: any) => {
      if (ele.value.champSettledAmount) {
        paymentToChamps = Number(paymentToChamps) + Number(ele.value.champSettledAmount);
      }
    })
    return paymentToChamps;
  }

  // get Commission To JobAdmin
  getCommissionToJobAdmin() {
    let jobAdminCommission = 0;
    let jobSuperVisorCommission = 0;

    this.mtformarray.controls.forEach((ele: any) => {
      if (ele.value.jobAdminCommission) {
        jobAdminCommission = Number(jobAdminCommission) + Number(ele.value.jobAdminCommission);
      }
      if (ele.value.jobSuperVisorCommission) {
        jobSuperVisorCommission = Number(jobSuperVisorCommission) + Number(ele.value.jobSuperVisorCommission);
      }
    })
    return jobAdminCommission ? jobAdminCommission : jobSuperVisorCommission;
  }

  // get Your Earnings
  getYourEarnings() {
    let paymentToChamps = 0;
    let jobAdminCommission = 0;
    let chargeableAmount = 0;
    let jobSuperVisorCommission = 0;

    this.mtformarray.controls.forEach((ele: any) => {
      if (ele.value.payableAmount) {
        chargeableAmount = Number(chargeableAmount) + Number(ele.value.payableAmount);
      }
      if (ele.value.champSettledAmount) {
        paymentToChamps = Number(paymentToChamps) + Number(ele.value.champSettledAmount);
      }
      if (ele.value.jobAdminCommission) {
        jobAdminCommission = Number(jobAdminCommission) + Number(ele.value.jobAdminCommission);
      }
      if (ele.value.jobSuperVisorCommission) {
        jobSuperVisorCommission = Number(jobSuperVisorCommission) + Number(ele.value.jobSuperVisorCommission);
      }
    })
    if (this.workLogList) {
      let chargeableAmounts = (Number(chargeableAmount) * 1.18) - this.workLogList.paidAmount;
      if (chargeableAmounts != 0) {
        let amount = (Number(chargeableAmounts) + Number(this.workLogList.paidAmount)) - Number(paymentToChamps) - Number(jobAdminCommission ? jobAdminCommission : jobSuperVisorCommission);
        return amount.toFixed(2);
      } else {
        return paymentToChamps.toFixed(2);
      }
    }
  }

  // download work log
  async downloadWorkLog() {
    const blob = await this.workLogService.getWorklog(this.employmentId)
      .catch(error => {
        this.notify.showMessage('Unable to download work log.', 2000);
      });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'work_log_' + this.employmentId + ' .xlsx';
    link.click();

    window.URL.revokeObjectURL(url);
  }

  // work log send to employer for approval
  sendToEmployer() {
    let workLogItem = [];
    let recordTimeZero = false;
    this.mtformarray.value.forEach(element => {
      if (element.hoursWorked <= 0) {
        recordTimeZero = true;
      }
    })

    if (!recordTimeZero) {
      // this.mtformarray.value.forEach(element => {
      //   if (element.hoursWorked)
      //     workLogItem.push({
      //       "id": element.id,
      //       "status": element.status || 'APPROVAL_PENDING',
      //       "paymentRequestId": element.paymentRequestId,
      //       "agreedAmount": element.agreedAmount,
      //       "payableAmount": element.payableAmount,
      //       "remarks": element.remarks,
      //       "userName": element.userName,
      //       "checkIn": element.checkIn,
      //       "checkOut": element.checkOut,
      //       "hoursWorked": element.hoursWorked,
      //       "rating": element.rating
      //     })
      // });
      // let obj = {
      //   "id": this.workLogList.id,
      //   "createdBy": this.workLogList.createdBy,
      //   "updatedBy": this.workLogList.updatedBy,
      //   "createdOn": this.workLogList.createdOn,
      //   "updatedOn": this.workLogList.updatedOn,
      //   "isDelete": this.workLogList.isDelete,
      //   "deleteBy": this.workLogList.deleteBy,
      //   "status": this.workLogList.status == 'CREATED' ? 'APPROVAL_PENDING' : this.workLogList.status,
      //   "employmentId": this.employmentId,
      //   "employerId": this.employerId,
      //   "workLogItem": workLogItem,
      //   "paidAmount": this.workLogList.paidAmount,
      //   "rating": this.workLogList.rating
      // }
      // console.log("obj==========", obj)

      this.workLogList.status = "APPROVAL_PENDING";
      // this.mtformarray.value.forEach(element => {
      //   element.status = element.status != '' ? element.status: "APPROVAL_PENDING"
      // });
      this.workLogList.workLogItem = this.mtformarray.value;
      console.log("this.workLogList", this.workLogList)
      this.workLogService.updateWorkLog(this.employmentId, this.workLogList).then(res => {
        this.notify.showMessage('Worklog sent to employer successfully!', 2000);
        this.getAllWorkLogs();
      }).catch((err: any) => {
        this.notify.showMessage(err.message ? err.message : 'something went wrong!', 2000);
      })
    } else {
      this.notify.showMessage('Please enter valid details!', 2000);
    }
  }

  // all work log record editable
  // editWorkLog() {
  //   this.editableWorkLog = true;
  // }

  checkInTimeSet(event, row, index) {
    console.log(event.target.value)
    this.checkIndate.setHours(event.target.value.split(':')[0]);
    this.checkIndate.setMinutes(event.target.value.split(':')[1]);
    this.checkIndate.setSeconds(0);
    console.log(this.checkIndate)
    if (row.value.checkOut) {
      this.checkOutdate.setHours(row.value.checkOut.split(':')[0]);
      this.checkOutdate.setMinutes(row.value.checkOut.split(':')[1]);
      this.checkOutdate.setSeconds(0);
    }
    this.totalWorkedHours(row, index);
  }

  checkOutTimeSet(event, row, index) {
    this.mtformarray = this.mtformgroup.get('formarray') as FormArray;
    this.checkOutdate.setHours(event.target.value.split(':')[0]);
    this.checkOutdate.setMinutes(event.target.value.split(':')[1]);
    this.checkOutdate.setSeconds(0);
    if (row.value.checkIn) {
      this.checkIndate.setHours(row.value.checkIn.split(':')[0]);
      this.checkIndate.setMinutes(row.value.checkIn.split(':')[1]);
      this.checkIndate.setSeconds(0);
    }
    let diff = this.getDataDiff(this.checkIndate, this.checkOutdate);
    if (diff.day < 0) {
      this.mtformarray.controls[index].markAllAsTouched();
      this.mtformarray.controls[index].markAsDirty();
    }
    this.totalWorkedHours(row, index);
  }


  totalWorkedHours(row, index) {
    let shiftStartTime = this.job.dates[0].timeFrom.toString().replace(',', ':');
    let shiftEndTime = this.job.dates[0].timeTo.toString().replace(',', ':');

    let actualStartTime = new Date();
    let actualEndTime = new Date();

    actualStartTime.setHours(shiftStartTime.split(':')[0]);
    actualStartTime.setMinutes(shiftStartTime.split(':')[1]);
    actualStartTime.setSeconds(0);
    actualEndTime.setHours(shiftEndTime.split(':')[0]);
    actualEndTime.setMinutes(shiftEndTime.split(':')[1]);
    actualEndTime.setSeconds(0);

    let inDate = this.checkIndate;
    let outDate = this.checkOutdate;

    if (this.checkIndate >= actualStartTime) {
      console.log("1", this.checkIndate, actualEndTime, actualStartTime)
      if (this.checkIndate <= actualEndTime) {
        console.log("111111")
        inDate = this.checkIndate;
      } else {
        console.log("222222")
        inDate = actualStartTime;
        this.mtformarray.controls[index].get('checkIn').setValue(String(inDate.getHours()).padStart(2, '0') + ':' + String(inDate.getMinutes()).padStart(2, '0'));
        this.mtformarray.controls[index].get('checkIn').updateValueAndValidity();
      }
    } else {
      console.log("else 1")
      inDate = actualStartTime;
      this.mtformarray.controls[index].get('checkIn').setValue(String(inDate.getHours()).padStart(2, '0') + ':' + String(inDate.getMinutes()).padStart(2, '0'));
      this.mtformarray.controls[index].get('checkIn').updateValueAndValidity();
    }

    if (this.checkOutdate <= actualEndTime) {
      console.log("2")
      if (this.checkOutdate >= actualStartTime) {
        outDate = this.checkOutdate;
      } else {
        outDate = actualEndTime;
        this.mtformarray.controls[index].get('checkOut').setValue(String(outDate.getHours()).padStart(2, '0') + ':' + String(outDate.getMinutes()).padStart(2, '0'));
      }
    } else {
      console.log("else 2")
      outDate = actualEndTime;
      this.mtformarray.controls[index].get('checkOut').setValue(String(outDate.getHours()).padStart(2, '0') + ':' + String(outDate.getMinutes()).padStart(2, '0'));
    }

    if (inDate && outDate) {
      console.log("3", inDate, outDate)
      let total_diff = null;
      this.mtformarray = this.mtformgroup.get('formarray') as FormArray;
      let diff = this.getDataDiff(inDate, outDate);
      console.log(inDate, outDate)
      console.log(diff)
      let minHour = 0;
      if (diff.day >= 0) {
        if (diff.minute > 0) {
          minHour = diff.minute / 60;
        }
        total_diff = (diff.hour + minHour).toFixed(2);
      } else {
        this.mtformarray.controls[index].markAllAsTouched();
      }
      // }
      if (!total_diff) {
        console.log("4")
        this.notify.showMessage('Please enter valid date', 2000);
        this.mtformarray.controls[index].get('checkOut').setValue("00:00");
      }
      this.mtformarray.controls[index].get('hoursWorked').setValue(total_diff ? total_diff : 0);
      this.mtformarray.controls[index].get('agreedAmount').setValue((row.value.basePrice + (this.mtformarray.controls[index].get('hoursWorked').value * 1 * row.value.hourlyRate)).toFixed(2))
    }
  }

  getDataDiff(startDate, endDate) {
    startDate.setMilliseconds(0);
    endDate.setMilliseconds(0);
    var diff = endDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return { day: days, hour: hours, minute: minutes, second: seconds };
  }

  copyLinkToClipboardForEmployer() {
    let copyUrl = null;
    this.copiedEmployer = true;
    this.copyTitleEmployer = 'Copied to your clipboard!';
    let shareLink = this.router.url.replace('/work-log/', '/payment/');
    if (environment.api_url == 'https://devapi.hour4u.com') {
      copyUrl = 'https://dev.workorbits.com' + shareLink
    }
    if (environment.api_url == 'https://api.hour4u.com') {
      copyUrl = 'https://workorbits.com' + shareLink
    }

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notify.showMessage('Link copied!', 3000)
    setTimeout(() => {
      this.copiedEmployer = false;
      this.copyTitleEmployer = 'Copy Link to Share';
    }, 7000);
  }

  // Approve work logs
  approveWorkLog() {
    this.approveWorklogAmount();
  }

  // Appvoe all and pay for all records
  async approveWorklogAmount() {
    let workLogItem = [];
    this.workLogList.workLogItem.forEach(element => {
      workLogItem.push({
        "id": element.id,
        "status": 'APPROVED',
        "paymentRequestId": element.paymentRequestId,
        "agreedAmount": element.agreedAmount,
        "payableAmount": element.payableAmount,
        "remarks": element.remarks,
        "userName": element.userName,
        "checkIn": element.checkIn,
        "checkOut": element.checkOut,
        "hoursWorked": element.hoursWorked,
        "rating": 5
      })
    });
    await this.submitForApproval(workLogItem);
  }

  // submit for approve record
  submitForApproval(workLogItem) {
    if (this.workLogList.status != 'APPROVED') {
      let obj = {
        "id": this.workLogList.id,
        "createdBy": this.workLogList.createdBy,
        "updatedBy": this.workLogList.updatedBy,
        "createdOn": this.workLogList.createdOn,
        "updatedOn": this.workLogList.updatedOn,
        "isDelete": this.workLogList.isDelete,
        "deleteBy": this.workLogList.deleteBy,
        "status": 'APPROVED',
        "employmentId": this.employmentId,
        "employerId": this.employerId,
        "workLogItem": workLogItem,
        "paidAmount": this.workLogList.paidAmount,
        "rating": 5
      }
      this.workLogService.updateWorkLog(this.employmentId, obj).then(res => {
        this.notify.showMessage('Work log approved successfully!', 2000);
        this.getAllWorkLogs();
      }).catch((err: any) => {
        this.notify.showMessage('Something went wrong!', 2000);
      })
    } else {
      this.notify.showMessage('This work log already approved!', 2000);
    }
  }

  dataRefresh() {
    this.router.navigateByUrl('/employment/all/all');
  }

  // set single record rating
  setStar(index: any, data?: any) {
    console.log(data)
    for (var i = 0; i <= 4; i++) {
      if (i <= index) {
        data.starList[i] = false;
      }
      else {
        data.starList[i] = true;
      }
    }
    if (index > -1 && index < 2) this.level = 'Fair'
    else if (index > 1 && index < 4) this.level = 'Good'
    else this.level = 'Excellent'
    data.rating = index + 1;
  }

  // selected action
  selectedAction(action, row) {
    if (action == 'Mark as paid') {
      this.markAsPaid(row, 'Paid');
    }
    if (action == 'Settled in cash') {
      this.markAsPaid(row, 'Paid', true);
    }
    if (action == 'Edit approval') {
      this.EditApproval(row);
    }
  }

  // Mark As Paid & settle as cash
  markAsPaid(row, status: string, forCash?: boolean) {
    console.log(row)
    this.isLoading = true;
    this.paymentService.updatePaymentStatus(row.value.paymentRequestId, status, row.value.champSettledAmount, forCash).toPromise().then(data => {
      this.notify.showMessage('Payment Status Updated Successsfully !', 2000);
      this.isLoading = false;
      this.getAllWorkLogs();
    }).catch(err => {
    });
  }

  // Edit Approval
  EditApproval(row) {
    this.openPaymentStatusDialog(row)
  }

  // Open change status dialog
  openPaymentStatusDialog(row) {
    console.log(row.value, this.job)
    let obj = {
      "jobSeekerId": row.value.jobSeekerId,
      "employmentId": this.job.id,
      "employerId": this.job.employerId,
      "totalRecordedTime": row.value.hoursWorked,
      "amount": row.value.champAgreedAmount,
      "settledAmount": row.value.champSettledAmount,
      "expectedAmount": row.value.champExpectedAmount,
      "rate": row.value.rating,
      "id": row.value.paymentRequestId
    }
    if (!row.value.rate)
      row.value.rate = row.value.rating;
    const dialogRef = this.dialog.open(PaymentStatusDialogComponent, {
      width: '400px',
      disableClose: true,
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.settledAmount) {
          row.value.champSettledAmount = result.settledAmount;
        }
        this.approvePayment(row);
        this.changeStatusofChampWorklog('Approved');
        this.getAllWorkLogs();
      }
    });
  }

  // approve Champ Worklog
  approveChampWorklog(row) {
    console.log(row)
    if (row.controls['champSettledAmount'].value) {
      if (row.value.rating) {
        // row.value.status = 'SUPER_ADMIN_APPROVAL_PENDING';
        row.value.status = 'APPROVAL_PENDING';
        this.EditApproval(row);
      } else {
        this.notify.showMessage("Please enter rating!", 2000);
      }
    } else {
      this.notify.showMessage("Please enter settled amount!", 2000);
    }
  }

  approvePayment(row) {
    this.paymentService.updatePaymentStatus(row.value.paymentRequestId, 'Approved', row.value.champSettledAmount).subscribe(data => {
      this.notify.showMessage("Status updated successfully !", 2000);
    })
  }

  // reject Champ Worklog
  rejectChampWorklog(row) {
    console.log(row)
    row.value.status = 'SUPER_ADMIN_REJECTED';
    this.changeStatusofChampWorklog('Rejected');
  }

  // change Status of Champ Worklog
  changeStatusofChampWorklog(status) {
    this.isLoading = true;
    this.workLogList.workLogItem = this.mtformarray.value;
    console.log("obj==========", this.workLogList)
    this.workLogService.updateWorkLog(this.employmentId, this.workLogList).then(res => {
      this.isLoading = false;
      this.notify.showMessage('Worklog ' + status + ' successfully!', 2000);
      this.getAllWorkLogs();
    }).catch((err: any) => {
      this.isLoading = false;
      this.notify.showMessage(err.message ? err.message : 'something went wrong!', 2000);
    })
  }

  // disabled send work log btn
  disableSendWorkLogBtn() {
    let disabled = false;
    if (this.workLogList) {
      if (this.workLogList.status == 'PAID' || this.workLogList.status == 'APPROVED' || !this.mtformarray.controls.length) {
        disabled = true;
      }
      this.workLogList.workLogItem.forEach((ele: any, i: number) => {
        if (ele.paymentStatus && ele.paymentStatus == 'Unapproved') {
          disabled = true;
        }
      })
    }
    return disabled;
  }
}