import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AssignJobAdminComponent } from 'src/app/modules/employment/assign-job-admin/assign-job-admin.component';

@Component({
  selector: 'hour4u-admin-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Output() dataRefresh = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  // create Agency
  createAgency() {
    this.dialogRef.close(true);
    this.router.navigate(['/employer/all']);
  }

  // assign Agency
  assignAgency() {
    this.dialogRef.close(true);
    const dialogRef = this.dialog.open(AssignJobAdminComponent, {
      width: '600px',
      panelClass: 'assign-dialog',
      disableClose: true,
      data: this.data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataRefresh.emit();
      }
    });
  }
}
