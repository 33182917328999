import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import icMail from '@iconify/icons-ic/twotone-mail';
import { Router } from '@angular/router';
import { AuthService } from 'src/@core/services/auth.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { fadeInUp400ms } from 'src/@core/animations/fade-in-up.animation';

@Component({
  selector: 'hour4u-admin-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInUp400ms]
})
export class ForgotPasswordComponent implements OnInit {

  form = this.fb.group({
    email: [null, Validators.required]
  });

  icMail = icMail;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notify: NotificationService,
    private router: Router
  ) { }

  ngOnInit() { }

  send() {
    const email = this.form.controls.email.value;
    if (!email) {
      return;
    }
    let obj = {
      "userName": this.form.controls.email.value,
      "userNameType": this.form.controls.email.value.includes('@') ? 'EMAIL' : 'MOBILE'
    }
    this.authService.sendForgotOTPLink(obj).subscribe(data => {
      this.notify.showMessage('Link sent successfully!', 3000);
    }, (error) => {
      this.notify.showMessage('User does not exists !', 3000);
    }, () => {
      this.form.controls.email.setValue('');
      this.router.navigate(['change-password'], {
        queryParams: {
          email
        }
      });
    });

    // this.authService.sendForgotLink(this.form.controls.email.value).subscribe(data => {
    //   this.notify.showMessage('Link sent successfully!', 3000);
    // }, (error) => {
    //   this.notify.showMessage('User does not exists !', 3000);
    // }, () => {
    //   this.form.controls.email.setValue('');
    //   this.router.navigate(['change-password'], {
    //     queryParams: {
    //       email
    //     }
    //   });
    // });
  }
}
