import { Component, OnInit } from '@angular/core';
import { Job } from 'src/@core/interfaces/job/job.model';
import { JobService } from 'src/@core/services/job/job.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { fadeInRight400ms } from 'src/@core/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@core/animations/scale-in.animation';
import { Employer } from 'src/@core/interfaces/employer/employer.model';
import { EmployerService } from 'src/@core/services/employer/employer.service';
import { JobType } from 'src/@core/interfaces/job/job-type.model';
import { JobTypeService } from 'src/@core/services/job/job-type.service';
import { roles } from 'src/app/shared/helpers/roles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hour4u-admin-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss'],
  animations: [fadeInRight400ms, scaleIn400ms]
})
export class ViewJobComponent implements OnInit {

  data: Job = new Job();
  employer: Employer;
  jobType: JobType;
  roles = roles;
  imageUrl = environment.image_url;

  constructor(
    private jobService: JobService,
    private employerService: EmployerService,
    private jobTypeService: JobTypeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.data.id = data.id;
      this.loadData();
    });
  }

  /**
   * Get job from server
   */
  loadData() {
    this.jobService.getById(this.data.id).subscribe(job => {
      this.data = job;
      this.loadEmployers();
      this.loadJobType();
    })
  }

  /**
   * Get emplyer from server to show
   */
  loadEmployers() {
    if (this.data.employerId) {
      this.employerService.getById(this.data.employerId).subscribe(employer => {
        this.employer = employer;
      })
    }
  }

  /**
   * Get emplyer from server to show
   */
  loadJobType() {
    this.jobTypeService.getById(this.data.jobTypeId).subscribe(type => {
      this.jobType = type;
    })
  }

  getTotalQuantity() {
    let quantity = 0;
    if (this.data && this.data.rateCards && this.data.rateCards.length) {
      this.data.rateCards.forEach(element => {
        quantity = quantity + element.quantity;
      });
    }
    return quantity;
  }

  getTotalAmount() {
    let amount = 0;
    if (this.data && this.data.rateCards && this.data.rateCards.length) {
      this.data.rateCards.forEach(element => {
        amount = amount + (element.rate * element.quantity);
      });
    }
    return amount;
  }

  downloadAttachment(attachmentName: string): void {
    // Create an anchor element and trigger a click to download the file
    const link = document.createElement('a');
    link.href = `${this.imageUrl}${attachmentName}`;
    link.target = '_blank';
    link.download = attachmentName;
    link.click();
  }

  // create Work Order
  createWorkOrder() {
    console.log(this.data, this.employer, this.jobType);
    const navigationExtras: NavigationExtras = {
      queryParams: { 'jobId': this.data.id, 'jobTypeId': this.data.jobTypeId, 'employerId': this.data.employerId }
    }
    this.router.navigate(['/enterprise-admin/create-work-order'], navigationExtras);
  }
}
