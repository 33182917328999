import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { ColorFadeModule } from 'src/@core/pipes/color/color-fade.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialShared } from 'src/app/shared/material-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurdBaseModule } from 'src/app/shared/base/curd-base.module';
import { EmploymentComponent } from './employment/employment.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ViewEmploymentComponent } from './employment/view-employment/view-employment.component';
import { ApproveEmploymentComponent } from './employment/approve-employment/approve-employment.component';
import { ActivateEmploymentComponent } from './employment/activate-employment/activate-employment.component';
import { EmploymentFilterDialogComponent } from './employment/employment-filter-dialog/employment-filter-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatProgressSpinnerModule } from '@angular/material';
import { AssignAdminDialogComponent } from './assign-admin-dialog/assign-admin-dialog.component';
import { ApplicationsComponent } from './applications/applications.component';
import { DeActivateEmploymentComponent } from './employment/de-activate-employment/de-activate-employment.component';
import { ActiveEmploymentsComponent } from './active-employments/active-employments.component';
import { ActiveJobSeekerCardComponent } from './active-employments/active-job-seeker-card/active-job-seeker-card.component';
import { GiveRatingDialogComponent } from './active-employments/give-rating-dialog/give-rating-dialog.component';
import { AddEditEmploymentV2Component } from './employment/add-edit-employmentV2/add-edit-employmentV2.component';
import { EmploymentMapViewComponent } from './active-employments/employment-map-view/employment-map-view.component';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { GiveBulkRatingDialogComponent } from './active-employments/give-bulk-rating-dialog/give-bulk-rating-dialog.component';
import { MarkAttendanceDialogComponent } from './active-employments/mark-attendance-dialog/mark-attendance-dialog.component';
import { ViewEmploymentPaymentsComponent } from './employment/view-employment-payments/view-employment-payments.component';
import { PaymentStatusDialogComponent } from '../payments/payment-status-dialog/payment-status-dialog.component';
import { PaymentsModule } from '../payments/payments.module';
import { ShowWorkLogsComponent } from './employment/show-work-logs/show-work-logs.component';
import { ViewJobSeekerPaymentsComponent } from './employment/view-job-seeker-payments/view-job-seeker-payments.component';
import { CancelEmployementComponent } from './employment/cancel-employement/cancel-employement.component';
import { ShiftBurgerMenuComponent } from './shift-burger-menu/shift-burger-menu.component';
import { PageLayoutModule } from 'src/@core/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@core/components/secondary-toolbar/secondary-toolbar.module';
import { AssignJobAdminComponent } from './assign-job-admin/assign-job-admin.component';
import { CreateShiftComponent } from './employment/create-shift/create-shift.component';
import { ViewProofOfWorkComponent } from './view-proof-of-work/view-proof-of-work.component';
import { ViewDetailTasksComponent } from './view-proof-of-work/view-detail-tasks/view-detail-tasks.component';


const routes: Routes = [

  { path: '', component: EmploymentComponent },
  //{ path: 'all', component: EmploymentComponent },
  { path: 'all/:status', component: EmploymentComponent },
  { path: 'all/:status/:id', component: EmploymentComponent },
  { path: 'active/:id', component: ActiveEmploymentsComponent },
  { path: 'active/workproof/:id/:seekerId', component: ViewProofOfWorkComponent },
  { path: 'active/workproof/:id', component: ViewProofOfWorkComponent },
  { path: 'add', component: AddEditEmploymentV2Component },
  { path: 'view/:id', component: ViewEmploymentComponent },
  { path: 'payment/:id', component: ViewEmploymentPaymentsComponent },
  { path: 'job-seeker-payment/:id', component: ViewJobSeekerPaymentsComponent },
  { path: 'work-log/:id', component: ShowWorkLogsComponent },
  { path: 'job-applications/all/:id', component: ApplicationsComponent },
  { path: 'job-applications/approved/:id', component: ApplicationsComponent },
  //{ path: 'job-applications/inreview/:id', component: ApplicationsComponent },
  { path: 'job-applications/pending/:id', component: ApplicationsComponent },
  { path: 'job-applications/rejected/:id', component: ApplicationsComponent }
  //{ path: 'job-applications/standby/:id', component: ApplicationsComponent }
];


@NgModule({
  declarations: [
    EmploymentComponent,
    ViewEmploymentComponent,
    EmploymentFilterDialogComponent,
    ApproveEmploymentComponent,
    ActivateEmploymentComponent,
    AssignAdminDialogComponent,
    ApplicationsComponent,
    DeActivateEmploymentComponent,
    ActiveEmploymentsComponent,
    ActiveJobSeekerCardComponent,
    GiveRatingDialogComponent,
    GiveBulkRatingDialogComponent,
    AddEditEmploymentV2Component,
    EmploymentMapViewComponent,
    MarkAttendanceDialogComponent,
    ViewEmploymentPaymentsComponent,
    ShowWorkLogsComponent,
    ViewJobSeekerPaymentsComponent,
    CancelEmployementComponent,
    ShiftBurgerMenuComponent,
    AssignJobAdminComponent,
    CreateShiftComponent,
    ViewProofOfWorkComponent,
    ViewDetailTasksComponent,
  ],
  imports: [
    FlexLayoutModule,
    IconModule,
    ColorFadeModule,
    MaterialShared,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CurdBaseModule,
    NgxMaterialTimepickerModule,
    PaymentsModule,
    RouterModule.forChild(routes),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCapq8M5KTmGYayG3x7mLHSD2qXfzmyTC4',
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    MatProgressSpinnerModule,
    PageLayoutModule,
    SecondaryToolbarModule
  ],
  entryComponents: [
    EmploymentFilterDialogComponent,
    EmploymentFilterDialogComponent,
    ApproveEmploymentComponent,
    ActivateEmploymentComponent,
    AssignAdminDialogComponent,
    DeActivateEmploymentComponent,
    GiveRatingDialogComponent,
    GiveBulkRatingDialogComponent,
    MarkAttendanceDialogComponent,
    ViewEmploymentPaymentsComponent,
    CancelEmployementComponent,
    ShiftBurgerMenuComponent,
    AssignJobAdminComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ]
})
export class EmploymentModule { }
