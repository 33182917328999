import { Injectable } from '@angular/core';
import {
    CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route,
    CanActivateChild, Router, NavigationExtras
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { LoginResponse } from '../interfaces/login-response.model';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    user: LoginResponse;
    constructor(private app: AppService, private router: Router) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin(state.url, next);
    }
    checkLogin(url: string, route: ActivatedRouteSnapshot): boolean {
        this.user = this.app.getCurrentAccount();
        if (this.app.isLoggedIn()) {
            // check if route is restricted by role
            if (route.data.roles && this.user.roles.filter(x => route.data.roles.indexOf(x) === -1).length > 0) {
                //if (route.data.roles && route.data.roles.indexOf(this.user.roles[0]) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/dashboard']);
                return false;
            }
            return true;
        }
        this.router.navigate(['/dashboard']);
        return;
        // store the attempted URL for redirecting
        this.app.setRedirectURL(url);
        // create session_id
        const sessionId = 12345567;
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: { 'session_id': sessionId },
            fragment: 'anchor'
        }
        console.log("##############3", url)
        // this.router.navigate(['/'], navigationExtras);
        return false;
    }
}
