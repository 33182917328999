import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/@core/services/account.service';
import { EmploymentAdministration } from 'src/@core/interfaces/employment/employment-administration.model';
import { EmploymentAdministrationService } from 'src/@core/services/employment/employment-administration.service';
import { NotificationService } from 'src/@core/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Search } from 'src/@core/interfaces/search';
import { roles } from "../../../shared/helpers/roles";
import { EmploymentService } from 'src/@core/services/employment/employment.service';
import { MatCheckboxChange } from '@angular/material';
import { AppService } from 'src/@core/services/app.service';

@Component({
  selector: 'hour4u-admin-assign-admin-dialog',
  templateUrl: './assign-admin-dialog.component.html',
  styleUrls: ['./assign-admin-dialog.component.scss']
})
export class AssignAdminDialogComponent implements OnInit {

  selectedJobDetails: any = null;
  selection = [];
  searchField: string = null;
  searchParams: Search[] = [];
  supervisorAdminList: any[] = [];
  roles = roles;
  resultsLength: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  role: string = 'JOB_SUPERVISOR';
  pageSizeOptions: number[] = [10, 20, 50, 100, 500];
  assignedJobSupervisorAdmins: any[] = [];
  fromWhere: string = null;
  step: number = 1;
  commissionArray: any[] = [];
  profile = this.appService.getCurrentAccount();
  selectedAll: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssignAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private notify: NotificationService,
    public employmentService: EmploymentService,
    private appService: AppService
  ) {
    this.selectedJobDetails = this.data;
    console.log(this.selectedJobDetails)
    if (this.selectedJobDetails.fromWhere && this.selectedJobDetails.fromWhere == "JOB_ADMIN") {
      this.step = 1;
      this.fromWhere = this.selectedJobDetails.fromWhere;
    }
    if (this.selectedJobDetails && this.selectedJobDetails.jobFulfilerCommission) {
      this.commissionArray = [];
      this.setCommission();
    }
  }

  setCommission() {
    this.selectedJobDetails.jobFulfilerCommission.femaleCommission.supervisorCommissionAmount = null;
    this.selectedJobDetails.jobFulfilerCommission.femaleCommission.supervisorCommissionPercentage = null;
    this.selectedJobDetails.jobFulfilerCommission.maleCommission.supervisorCommissionAmount = null;
    this.selectedJobDetails.jobFulfilerCommission.maleCommission.supervisorCommissionPercentage = null;
    let objFemale = {
      "gender": this.selectedJobDetails.jobFulfilerCommission.femaleCommission.gender,
      "commissionPercentage": this.selectedJobDetails.jobFulfilerCommission.femaleCommission.commissionPercentage,
      "commissionAmount": this.selectedJobDetails.jobFulfilerCommission.femaleCommission.commissionAmount,
      "supervisorCommissionAmount": this.selectedJobDetails.jobFulfilerCommission.femaleCommission.supervisorCommissionAmount,
      "supervisorCommissionPercentage": this.selectedJobDetails.jobFulfilerCommission.femaleCommission.supervisorCommissionPercentage,
      "totalMargin": this.getTotalAmount(this.selectedJobDetails.jobFulfilerCommission.femaleCommission)
    }
    this.commissionArray.push(objFemale);

    let objMale = {
      "gender": this.selectedJobDetails.jobFulfilerCommission.maleCommission.gender,
      "commissionPercentage": this.selectedJobDetails.jobFulfilerCommission.maleCommission.commissionPercentage,
      "commissionAmount": this.selectedJobDetails.jobFulfilerCommission.maleCommission.commissionAmount,
      "supervisorCommissionAmount": null,
      "supervisorCommissionPercentage": null,
      "totalMargin": this.getTotalAmount(this.selectedJobDetails.jobFulfilerCommission.maleCommission)
    }
    this.commissionArray.push(objMale);
  }

  // get tOtal amount
  getTotalAmount(commission) {
    let amount = 0;
    amount = commission.commissionAmount - (commission.supervisorCommissionAmount ? commission.supervisorCommissionAmount : 0);
    return amount;
  }

  ngOnInit() {
    this.getEmployementAssignedJobSupervisorAdmin();
  }

  getEmployementAssignedJobSupervisorAdmin() {
    this.employmentService.getAssignedAdmins(this.selectedJobDetails.id).subscribe((res: any) => {
      console.log(res)
      this.assignedJobSupervisorAdmins = res.jobSuperVisorAssignmentList;
      this.getAllsupervisorAdmin();
    })
  }

  getAllsupervisorAdmin() {
    this.searchParams = [];
    if (this.searchField)
      this.searchParams.push(new Search('name', this.searchField));
    this.searchParams.push(new Search('roles', this.role));
    this.accountService.searchAndSort(this.pageIndex, this.pageSize, 'createdOn', null, this.searchParams
    ).subscribe(data => {
      this.supervisorAdminList = data.content;
      if (this.selectedAll) {
        let selection = Object.assign([], this.selection);
        this.selection = [];
        selection.forEach(ele => {
          this.supervisorAdminList.forEach(el => {
            if (el.id == ele.id) {
              this.selection.push(el);
            }
          })
        })
      } else {
        if (this.selection && this.selection.length) {
          this.selection.forEach(ele => {
            this.supervisorAdminList.forEach(el => {
              if (el.id == ele.id) {
                el.checked = true;
              }
            })
          })
        }
      }

      if (this.assignedJobSupervisorAdmins && this.assignedJobSupervisorAdmins.length) {
        this.assignedJobSupervisorAdmins.forEach(ele => {
          this.supervisorAdminList.forEach(el => {
            if (ele.jobSupervisor && el.id == ele.jobSupervisor.id) {
              el.assigned = true;
            }
          })
        })
      }

      // this.supervisorAdminList = this.supervisorAdminList.sort((a, b) => {
      //   return a.name.localeCompare(b.name);
      // });
      this.resultsLength = data.totalElements;
    }, error => {
      this.notify.showMessage('Something went wrong!', 2000);
    });
  }

  toggle(item, event: MatCheckboxChange) {
    if (event.checked) {
      this.selection.push(item);
    } else {
      const index = this.selection.findIndex(x => x.id === item.id);
      if (index >= 0) {
        this.selection.splice(index, 1);
      }
    }
  }

  exists(item) {
    return this.selection.indexOf(item) > -1;
  };

  isIndeterminate() {
    return (this.selection.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selection.length === this.supervisorAdminList.length;
  };

  toggleAll(event: MatCheckboxChange) {
    this.selectedAll = event.checked;
    this.selection = [];
    if (!this.selectedAll) {
      this.supervisorAdminList.forEach(el => {
        el.checked = false;
      })
    }
    if (event.checked) {
      this.supervisorAdminList.forEach(row => {
        if (!row.assigned)
          this.selection.push(row)
      });
    } else {
      this.selection.length = 0;
    }
  }

  // Disable send request btn
  disable() {
    if (this.selection && this.selection.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  // Get Initial letter from JobSeeker name
  getUserNameInitial(full_name) {
    let initialOfName = null;
    if (full_name) {
      if (full_name.split(' ').length == 1) {
        initialOfName = full_name.split(' ')[0].split('')[0];
      }
      if (full_name.split(' ').length > 1) {
        initialOfName = full_name.split(' ')[0].split('')[0] + (full_name.split(' ')[1].split('')[0] ? full_name.split(' ')[1].split('')[0] : '')
      }
      return initialOfName ? initialOfName : full_name.split('')[0];
    }
  }

  // Click on submit btn
  goNext() {
    if (this.fromWhere == "JOB_ADMIN") {
      if (this.step == 1) {
        this.step = 2;
        return;
      }
      if (this.step == 2) {
        this.sendRequest();
        return;
      }
    } else {
      this.sendRequest();
      return;
    }
  }

  // Send request from super admin
  sendRequest() {
    console.log(this.selection, this.commissionArray);
    let obj = null;
    let femaleCommission = null;
    let maleCommission = null;
    if (this.fromWhere == "JOB_ADMIN") {
      this.commissionArray.forEach(ele => {
        if (ele.gender == 'Male') {
          maleCommission = {
            commissionAmount: ele.supervisorCommissionAmount,
            commissionPercentage: ele.supervisorCommissionPercentage,
            gender: ele.gender
          }
        }
        if (ele.gender == "Female") {
          femaleCommission = {
            commissionAmount: ele.supervisorCommissionAmount,
            commissionPercentage: ele.supervisorCommissionPercentage,
            gender: ele.gender
          }
        }
      })
    } else {
      delete this.data.jobFulfilerCommission.femaleCommission.supervisorCommissionAmount;
      delete this.data.jobFulfilerCommission.femaleCommission.supervisorCommissionPercentage;
      delete this.data.jobFulfilerCommission.maleCommission.supervisorCommissionAmount;
      delete this.data.jobFulfilerCommission.maleCommission.supervisorCommissionPercentage;
      femaleCommission = this.data.jobFulfilerCommission.femaleCommission;
      maleCommission = this.data.jobFulfilerCommission.maleCommission;
    }
    console.log(this.selectedJobDetails)
    let assigner = [];
    this.selection.forEach(ele => {
      let obj = {
        "jobFulfilerId": ele.id,
        "jobFulfiler": ele,
        "jobFulfilerCommission": {
          "joFulfilerType": "JOB_SUPERVISOR",
          "femaleCommission": femaleCommission,
          "maleCommission": maleCommission
        }
      }
      assigner.push(obj);
    })
    obj = {
      "employmentId": this.selectedJobDetails.id,
      "fulfillerType": "JOB_SUPERVISOR",
      "assigner": assigner,
      "jobAdminId": this.profile.user_details.id
    }
    console.log(obj)
    this.employmentService.assignJobSupervisorAdmin(obj).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
        this.notify.showMessage("Selected Job supervisor's request sent successfully", 2000);
      }
    }, (err: any) => {
      this.notify.showMessage('Something went wrong!', 2000);
    })
  }

  // Change pagination event
  getPaginatorData(event) {
    console.log(event)
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllsupervisorAdmin();
  }

  // Get commission in RS
  getCommissionInRs(commission) {
    console.log(commission)
    commission.totalMargin = this.getTotalAmount(commission);
    commission.supervisorCommissionPercentage = (commission.supervisorCommissionAmount * 100 / commission.commissionAmount).toFixed(2);
  }

  // Get commission in Percentage
  getCommissionInPercentage(commission) {
    console.log(commission)
    commission.totalMargin = this.getTotalAmount(commission);
    commission.supervisorCommissionAmount = (commission.supervisorCommissionPercentage * commission.commissionAmount / 100).toFixed(2);
  }

  // btn disable set
  btnDisabled() {
    let disabled = false;
    this.commissionArray.forEach((ele) => {
      if (!ele.supervisorCommissionAmount || ele.supervisorCommissionAmount < 0) {
        disabled = true;
      }
      if (!ele.supervisorCommissionPercentage || ele.supervisorCommissionPercentage < 0) {
        disabled = true;
      }
      if (ele.totalMargin < 0) {
        disabled = true;
      }
    })
    return disabled;
  }
}
