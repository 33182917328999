import { Injectable } from '@angular/core';
// import * as jwt from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';

import { distinctUntilChanged } from 'rxjs/operators';
import { LoginResponse } from '../interfaces/login-response.model';
import { Employer } from '../interfaces/employer/employer.model';


export const CURRENT_USER = 'h4u-current-user';
export const CURRENT_EMPLOYER = 'current-employer';
export const redirectUrl = 'redirectUrl';
export const IS_EMPLOYER_LOGIN = 'is-employerr-login';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    redirectURL: string;

    private employer = new BehaviorSubject<Employer>(null);
    private isEmployerLogin = new BehaviorSubject<boolean>(false);

    private currentUserSubject = new BehaviorSubject<LoginResponse>(new LoginResponse());
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    constructor() {
        try {
            const currentAccount = JSON.parse(localStorage.getItem(CURRENT_USER));
            if (currentAccount != null) {
                this.currentUserSubject.next(currentAccount);
            }
            // load employer
            const employer = JSON.parse(localStorage.getItem(CURRENT_EMPLOYER));
            if (employer != null) {
                this.employer.next(employer);
            }
            // load employer
            const isEmployerLogin = JSON.parse(localStorage.getItem(IS_EMPLOYER_LOGIN));
            if (isEmployerLogin != null) {
                this.employer.next(isEmployerLogin);
            }
        } catch (e) {
        }
        this.redirectURL = localStorage.getItem(redirectUrl);
    }


    destroy(): void {
        // localStorage.setItem(CURRENT_USER, JSON.stringify(this.getCurrentAccount()));
        localStorage.setItem(redirectUrl, this.redirectURL);
    }

    getCurrentAccount(): any {
        return this.currentUserSubject.getValue();
    }

    getCurrentAccountSubject(): Observable<any> {
        return this.currentUserSubject;
    }

    setRedirectURL(url: string) {
        this.redirectURL = url;
    }
    getRedirectURL() {
        return (this.redirectURL ? this.redirectURL : '/dashboard');
    }

    getToken(): string {
        // return "";
        // return "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsib2F1dGgyX2lkIl0sInVzZXJfbmFtZSI6InNhQGhvdXI0dS5jb20iLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiZXhwIjoxNzA1NjEyMDA5LCJhdXRob3JpdGllcyI6WyJTVVBFUl9BRE1JTiJdLCJqdGkiOiIyNWYwOWJiMy01MmFmLTRmMzUtODNhYS00YzMwZmQxMTY1ZGQiLCJjbGllbnRfaWQiOiJ3ZWItY2xpZW50In0.ybcdgI9BCrZh6KaMix0KWNE8hm-Vr59U5qcxYXcsNE8";
        return localStorage.getItem('jwtToken');
    }

    setCurrentAccount(credential: any) {
        //console.log(credential)
        localStorage.setItem(CURRENT_USER, JSON.stringify(credential));
        //console.log(credential.user.jwtToken)
        localStorage.setItem('jwtToken', 'bearer ' + credential.user.jwtToken);
        this.currentUserSubject.next(credential);
    }

    setEmployer(employer: Employer) {
        localStorage.setItem(CURRENT_EMPLOYER, JSON.stringify(employer));
        this.employer.next(employer);
    }

    getEmployer(): Employer {
        return this.employer.getValue();
    }

    setIsEmployerLogin(employerLogin) {
        localStorage.setItem(IS_EMPLOYER_LOGIN, employerLogin);
        this.isEmployerLogin.next(employerLogin);
    }

    getIsEmployerLogin(): boolean {
        return this.isEmployerLogin.getValue();
    }


    logout() {
        this.isEmployerLogin.next(null);
        this.currentUserSubject.next(null);
        this.redirectURL = null;
        localStorage.removeItem(CURRENT_USER);
        localStorage.removeItem('jwtToken')
        localStorage.clear();
    }

    isLoggedIn(): boolean {
        console.log(this.getCurrentAccount())
        if (this.getCurrentAccount() != null && this.getCurrentAccount().access_token) {
            console.log("################")
            return true;
        } else {
            console.log("!!!!!!!!!!!")
            return true;
        }
    }

    setStoreDetail(store: any) {
        let user = this.getCurrentAccount();
        user.storeId = store.id;
        user.storeCode = store.code;
        user.storeName = store.name;
        // this.setCurrentAccount(user);
    }

    getEntityId(): string {
        const currentAccount = this.getCurrentAccount();
        return currentAccount ? currentAccount.entity.id : null;
    }
}
